export const ENG_ECS_GROUPS_LIST_TRANSLATIONS = {
  'ecs.grouplist.title':
    `Host Groups are formed by configuring a common set of Kubernetes labels to a group of hosts. Labels are key/value pairs that are ` +
    `attached to objects, such as hosts, namespaces or pods. Labels can be used to organize and to select subsets of objects. Labels can be ` +
    `attached to objects at creation time and subsequently added and modified at any time.`,
  'ecs.grouplist.action.delete': 'Delete',
  'ecs.grouplist.action.edit': 'Edit',
  'ecs.grouplist.action.refresh': 'Refresh',
  'ecs.grouplist.column.grouplabel': 'Group Label',
  'ecs.grouplist.column.groupdescription': 'Group Description',
  'ecs.grouplist.column.groupdescription.placeholder': 'Filter Group Description',
  'ecs.grouplist.column.hostnumber': 'Number of Hosts',
  'ecs.grouplist.column.hostnumber.placeholder': 'Filter Group Host Count',
  'ecs.grouplist.footer': 'Groups per page',
  'ecs.grouplist.pagination': '{0} - {1} of {2} groups',
  'ecs.groups.noGroupsTitle': 'Currently you have no groups configured.',
  'ecs.groups.noGroupsSubtitle': 'Learn more on how to add groups and manage them in Edge Compute'
};
