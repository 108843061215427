import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
   ActivatedRoute, Event, NavigationEnd, Router
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { getLastRouteWithSidenavConfig, navigateToNewTab } from '../../utils/routing/routing.utils';
import {
   getSidenavLinks, NavLink, NavSection, NavSections
} from './sidenav.config';
import { Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
   // Using an attribute selector here because the specificity of Clarity css make it so
   // that the correct styles are not applied when the component wrapper tags are present
   // tslint:disable-next-line
   selector: '[ecs-sidenav]',
   templateUrl: './sidenav.component.html',
   styleUrls: ['./sidenav.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent extends Unsubscriber implements OnInit {
   private sectionsSource = new BehaviorSubject<NavSection[]>([]);

   sections$: Observable<NavSection[]> = this.sectionsSource.asObservable();
   navSections = NavSections;
   currentlySelectedLink: NavLink;
   collapsed: boolean;
   enterpriseId: number;
   currentVerticalTabs: NavSection[] = [];
   relativeRoute: ActivatedRoute; // for relative routing

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private sanitizer: DomSanitizer
   ) {
      super();
   }

   ngOnInit(): void {
      this.subs = this.router.events.subscribe((event: Event) => {
         if (event instanceof NavigationEnd) {
            this.assignSidenavLinks();
         }
      });
   }

   onLinkSelected(link: NavLink): void {
      this.setSelectedLink(link);
      this.navigateAppTo(link);
   }

   onCollapsedChange(value: boolean): void {
      this.collapsed = value;
   }

   trackByName(index: number, item: NavSection): string {
      return item.sectionName;
   }
   
   private assignSidenavLinks(): void {
      this.relativeRoute = getLastRouteWithSidenavConfig(this.route) || this.relativeRoute;
      this.currentVerticalTabs = getSidenavLinks(
         this.relativeRoute.snapshot.data['navigationState']
      );

      this.setSelectedLink();
      this.sectionsSource.next(this.currentVerticalTabs);
   }


   private clearLinkSelection(): void {
      // Function called when a link is selected in a nav-section.
      // Here we deselect all the links in other sections except for the section
      // in which a link has been selected.
      // The section takes care of unselecting the links or selecting them within itself.
      this.currentVerticalTabs = this.currentVerticalTabs?.map(link => ({
         ...link,
         selected: false
      }));
   }

   private navigateAppTo(link: NavLink): void {
      const { metaKey, ctrlKey } = window.event as any;
      const ctrlIsPressed = metaKey || ctrlKey;

      if (ctrlIsPressed) {
         const url = this.router.serializeUrl(this.router.createUrlTree(link.path, { relativeTo: this.relativeRoute }));

         navigateToNewTab(this.router.url, url, this.sanitizer);
         
         return;
      }

      this.router.navigate(link.path, {
         relativeTo: this.relativeRoute
      });

   }

   private setSelectedLink(link?: NavLink): void {
      const partialCurrentUrl = this.router.routerState.snapshot.url.split('/');

      partialCurrentUrl.shift();

      if (link) {
         if (this.currentlySelectedLink?.path.join('/') === link.path.join('/')) {
            return;
         }
      }

      this.clearLinkSelection();
      this.matchLinkAndSetSelection(partialCurrentUrl.join('/'));
   }

   private matchLinkAndSetSelection(partialUrl: string): boolean {
      let counter = 0;

      this.currentVerticalTabs = this.currentVerticalTabs?.map(section => ({
         ...section,
         links: section.links.map((link) => {
            const url: string = link.path.join('/');
            let selected = false;

            if (partialUrl.indexOf(url) !== -1) {
               selected = true;
               this.currentlySelectedLink = link;
               counter++;
            }

            return {
               ...link,
               selected
            };
         })

      }));

      if (!counter && this.currentVerticalTabs) {
         const allLinks = this.currentVerticalTabs.reduce((acc, { links }) => ([...acc, ...links]), []);
         const rootRouteLink = allLinks.find(({ path }) => path.join('') === './');

         if (rootRouteLink) {
            rootRouteLink.selected = true;
            this.currentlySelectedLink = rootRouteLink;

            return true;
         }
      }

      return !!counter;
   }
}
