import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { get as _get } from 'lodash';
import { CommonAppRoutes } from '../../constants/app-routing.constants';


export const enterpriseAppEndpoint = '/';

export const enum NavigationState {
   ENTERPRISE_CONFIGURE = 'ENTERPRISE_CONFIGURE',
   ENTERPRISE_MONITOR = 'ENTERPRISE_MONITOR',
}

export function getLastRouteWithSidenavConfig(activatedRoute: ActivatedRoute): ActivatedRoute {
   const sidenavPath = 'snapshot.data.navigationState';
   let route = activatedRoute;
   let currentRoute = activatedRoute;

   while (currentRoute.firstChild) {
      if (_get(currentRoute, sidenavPath) !== _get(currentRoute.firstChild, sidenavPath)) {
         route = currentRoute.firstChild;
      }

      currentRoute = currentRoute.firstChild;
   }

   return route;
}

export function navigateToNewTab(currentUrl: string, absoluteUrl?: string, sanitizer?: DomSanitizer): void {
   const urlForClickedLink = absoluteUrl;

   // Resolve the base url as the full absolute url subtract the relative url.
   const currentAbsoluteUrl = window.location.href;
   if(!isValidUrl(currentAbsoluteUrl)){
      return;
   }
   const index = currentAbsoluteUrl.indexOf(currentUrl);
   const baseUrl = currentAbsoluteUrl.substring(0, index);

   // Concatenate the urls to construct the desired absolute url.
   const clickedUrl = sanitizer.sanitize(SecurityContext.URL, new URL(baseUrl + urlForClickedLink).toString());
   const regexPattern = /https:\/\/.*\.vmware\.com:.*\/#\/.*/i;
    const match = regexPattern.test(clickedUrl);
   if (match) {
      window.open(clickedUrl, '_blank');
   } else {
      return;
   }
}

export function getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
   return activatedRoute.firstChild ? getChild(activatedRoute.firstChild) : activatedRoute;
}

export function getAdjustedRoute(router: Router, path: string): string {
   const parts = router.url.split(CommonAppRoutes.ecs);
   // Add the "ecs" and the rest of the URL back. While splitting, the first part already has a "slash"
   // so no need to add slashes. Remember, extra slashes can lead to weird behaviors especially in 
   // Operator login
   const adjustedUrl = `${parts[0]}${CommonAppRoutes.ecs}${path}`;
   return adjustedUrl;
 }

function isValidUrl(url:string) {
   const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
   return urlRegex.test(url);
 }
