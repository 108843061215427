import { Injectable } from '@angular/core';
import { VmwNgxButtonType, VmwNgxModalService } from '@vmw/ngx-modal-service';
import { ConfirmationModalComponent, ConfirmationModalConfig } from './confirmation-modal/confirmation-modal.component';



@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private modalService: VmwNgxModalService) { }

  confirm(config: ConfirmationModalConfig) {
    const modalRef = this.modalService.openOkCancel(ConfirmationModalComponent, {
      title: config.title,
      size: config.size,
      data: {
        ...config
      },
      buttons: {
        submitButton: {
          label: config.confirmButtonText,
          type: config.confirmButtonType ?? VmwNgxButtonType.Primary,
          handler: () => {
            config.confirm();
            modalRef.close();
          },
          disabled: config.confirmButtonDisabled
        },
        cancelButton: {
          label: config.cancelButtonText,
          type: config.cancelButtonType ?? VmwNgxButtonType.Outline,
          handler: () => {
            config.cancel();
            modalRef.close();
          }
        }
      },
    });
  }
}
