import { Pipe, PipeTransform } from "@angular/core";
import { Observable, map, startWith } from "rxjs";
import { TagForm, TagsForm } from "./tags-editor.models";

@Pipe({
  name: 'deactivateAddTag'
})
export class DeactivateAddTagPipe implements PipeTransform {
  transform(tagForm: TagForm, formArray: TagsForm): Observable<boolean> {
    return tagForm.valueChanges.pipe(startWith(tagForm.value), map(() => {
      return !tagForm.valid || (tagForm.pristine && formArray.controls.length > 1 && tagForm.controls.label_key.value === '' && tagForm.controls.label_value.value === '')
    }));
  }
}