import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appI18nLinkTarget]'
})
export class I18nLinkTargetDirective {
  @Input('appI18nLinkTarget') clickTarget: string;
  @Output() i18nLinkTargetClicked = new EventEmitter<HTMLElement>();

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.classList.contains(this.clickTarget)) {
      this.i18nLinkTargetClicked.emit();
    }
    event.stopPropagation();
  }
}
