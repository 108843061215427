import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';
import { ConfirmationService } from '../components/confirmation/confirmation.service';
import { VmwNgxModalSize } from '@vmw/ngx-modal-service';


export interface IComponentCanDeactivate {
  canDeactivate(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<IComponentCanDeactivate> {
  constructor(
    private confirmationService: ConfirmationService,
    private l10nService: L10nService
  ) {
  }

  canDeactivate(
    component: IComponentCanDeactivate
  ): Observable<boolean> | boolean {
    if (!component || component.canDeactivate()) return true;

    const canLeave = new Subject<boolean>();

    this.confirmationService.confirm({
      title: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.title'),
      message: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.body'),
      confirmButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.accept'),
      cancelButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.reject'),
      size: VmwNgxModalSize.Medium,
      confirm: () => {
        canLeave.next(false);
        canLeave.complete();
      },
      cancel: () => {
        canLeave.next(true);
        canLeave.complete();
      }
    });

    return canLeave;
  }
}