import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagrid } from '@clr/angular';
import { GitRepoResp } from '@ecs/ecs-api';
import { VmwNgxModalService } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { GitReposService } from '../../services/gitrepos.service';
import { AlertType, CONFIGURE_HOSTS_ROUTE, getAdjustedRoute, KeswickAlertService } from '@ecs/ecs-platform';
import { AddGitrepoModalService } from '../../services/add-gitrepo-modal.service';
import { GitReposListItem } from './gitrepos-list.model';
import { Lumos, REGISTER_GIT_REPO_DOC_LINK } from '@ecs/ecs-common';

@Component({
  selector: 'app-gitrepos-list',
  templateUrl: './gitrepos-list.component.html',
  styleUrls: ['./gitrepos-list.component.scss']
})
export class GitreposListComponent implements OnInit {
  @ViewChild('tmpl', { read: TemplateRef }) tmpl: TemplateRef<any>;
  @ViewChild('gitReposDatagrid', { static: true }) clarityDataGrid: ClrDatagrid;

  readonly REGISTER_GIT_REPO_DOC_LINK = REGISTER_GIT_REPO_DOC_LINK;

  public gitrepos: Array<any> = [];
  public addGitRepoModal = false;
  public selectedGitRepo: GitReposListItem;
  public selectedGitRepos: Array<GitReposListItem> = [];
  public loading = false;
  private editedGitRepo: any = {}; // Object to store the edited git repo data
  private successfulGitRepoDeletions = 0;
  private attemptedGitRepoDeletions = 0;
  private isRepoAssociatedWithHost = false;

  constructor(
    private gitrepoService: GitReposService,
    private addGitrepoService: AddGitrepoModalService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private router: Router,
    private l10service: L10nService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.gitrepoService.getGitRepoList();
    this.gitrepoService.gitRepoList.subscribe((gitRepos: GitRepoResp[]) => {
      this.gitrepos = gitRepos;
      this.loading = false;
    });
  }

  openAddGitRepoModal() {
    Lumos.trackClickEvent('GitRepo', 'Add GitRepo clicked');
    this.addGitrepoService.openAddGitrepoModal();
  }

  openEditGitRepoModal() {
    if (this.selectedGitRepos?.length === 1) {
      this.editedGitRepo = { ...this.selectedGitRepos?.[0] };
      this.addGitrepoService.openAddGitrepoModal(false, this.editedGitRepo);
    }
  }

  redirectToHostsPage(gitURL: string) {
    this.router.navigate([getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)], { queryParams: { git_repo_url: gitURL } });
  }

  isSelectedRepoAssociatedWithHost(): boolean {
    const hasRepoWithHostsAssociated = this.selectedGitRepos?.some((repo) => {
      return repo?.host_count > 0;
    });

    return hasRepoWithHostsAssociated;
  }

  openRemoveGitRepoModal() {
    this.isRepoAssociatedWithHost = this.isSelectedRepoAssociatedWithHost();
    const modalRef = this.modalService.openOkCancel(this.tmpl, {
      title: this.l10service.getMessage('ecs.gitrepolist.modal.title'),
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.yes'),
          handler: (ref) => {
            Lumos.trackClickEvent('GitRepo', 'Git Repo Removed');
            // the modal is closed with 'accept'
            ref.close('accept');
          },
          disabled: this.isRepoAssociatedWithHost
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.no'),
          handler: (ref) => {
            // the modal is closed with 'deny'
            ref.close('deny');
          }
        }
      }
    });

    modalRef.onClose().subscribe((result) => {
      if (result === 'accept') {
        this.successfulGitRepoDeletions = 0;
        this.attemptedGitRepoDeletions = 0;

        this.selectedGitRepos?.forEach((gitRepo) => {
          this.attemptedGitRepoDeletions += 1;

          this.gitrepoService.deleteGitRepo(gitRepo?.id).subscribe(() => {
            this.successfulGitRepoDeletions += 1;

            if (this.successfulGitRepoDeletions === this.selectedGitRepos?.length) {
              this.gitrepoService.getGitRepoList();

              this.alertService.showMessage({
                type: AlertType.success,
                message: this.l10service.getMessage('ecs.gitrepolist.modal.success')
              });
            }
          });
        });

        setTimeout(() => {
          if (
            this.attemptedGitRepoDeletions === this.selectedGitRepos?.length &&
            this.successfulGitRepoDeletions !== this.selectedGitRepos?.length
          ) {
            this.gitrepoService.getGitRepoList();

            this.alertService.showMessage({
              type: AlertType.failure,
              message: this.l10service.getMessage('ecs.gitrepolist.modal.failure')
            });
          }
        }, 500);
      }
    });
  }

  refreshGitReposDatagrid() {
    // Set loading state to true while fetching the new grid values
    this.loading = true;

    // Reset filter values for each column of datagrid
    const columns = this.clarityDataGrid?.columns;
    columns?.forEach((col) => {
      if (col) {
        col.filterValue = '';
      }
    });

    // Call backend API to fetch all Git Repos
    this.gitrepoService.getGitRepoList();
  }
}
