import { Injectable } from '@angular/core';
import { VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { GroupsUpdateComponent } from '../components/group/groups-update/groups-update.component';
import { UpdateGroupData } from '../components/group/groups/groups.model';
import { GroupsService } from './groups.service';
import { AlertType, KeswickAlertService } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { Label } from '@ecs/ecs-api';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateGroupModalService {
  constructor(
    private groupsService: GroupsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private l10service: L10nService
  ) {}

  openUpdateGroupModal(groupId: number, groupLabel: Label, groupDescription: string, groupMembers: string[]): Observable<void> { 
    const modalRef = this.modalService.openOkCancel(GroupsUpdateComponent, {
      size: VmwNgxModalSize.Large,
      title: 'Update Group',
      data: {
        groupID: groupId,
        groupLabel: groupLabel,
        groupDescription: groupDescription,
        groupMembers: groupMembers
      },
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.confirm')
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.cancel')
        }
      }
    });

    return modalRef.onClose().pipe(switchMap((updateGroupData: UpdateGroupData) => {  
      if (updateGroupData) {
        return this.groupsService.updateGroup(updateGroupData).pipe(map((responses) => {
           this.alertService.showMessage({
            type: AlertType.success,
            message: this.l10service.getMessage('ecs.updatehosttogroupmodal.success')
          });
        }), catchError(() => {
          this.alertService.showMessage({
            type: AlertType.failure,
            message: this.l10service.getMessage('ecs.updatehosttogroupmodal.failed')
          });
          return of();
        }));
      }else{
        return of();
      }
    }));
  }
}
