import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorService } from '../services/api-error.service';
import { OnboardingService } from '../services/onboarding.service';
import { HttpStatusCode } from '@angular/common/http';

@Injectable()
export class APIHttpInterceptor implements HttpInterceptor {
  constructor(private apiErrorService: ApiErrorService, private onboardingService: OnboardingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            if (err.status == 403 && err.error.message == 'service is not enabled for the org on CSP') {
              this.onboardingService.redirectToOnboarding();
            } else if (err.status == 500 && req.url.includes('/orgs/default')) {
              this.onboardingService.redirectToOnboarding();
            } else {
              this.apiErrorService.showApiError(err);
            }
          } catch (e) {
            console.log('Unexpected error from API:', e);
          }
        }
        return throwError(() => err);
      })
    );
  }
}
