<ng-container *ngIf="form">
    <form clrForm clrLayout="horizontal" [clrLabelSize]="4" [formGroup]="form">
        <clr-select-container ecsFormControlContainer>
            <label>{{ 'ecs.gitRepoConfigEditor.gitRepo.label' | vtranslate }}</label>
            <select clrSelect formControlName="git_repo_id" name="git_repo_id">
                <option *ngFor="let repo of gitRepos$ | async" [value]="repo.id">{{ repo.url }}</option>
            </select>
            <clr-control-helper>{{ 'ecs.gitRepoConfigEditor.gitRepo.helpText' | vtranslate
                }}</clr-control-helper>
            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
        </clr-select-container>

        <clr-input-container ecsFormControlContainer>
            <label for="gitRepoBranch">{{ 'ecs.gitRepoConfigEditor.gitRepoBranch.label' | vtranslate }}</label>
            <input clrInput type="text" formControlName="git_branch">
            <clr-control-helper>{{ 'ecs.gitRepoConfigEditor.gitRepoBranch.helpText' | vtranslate }}</clr-control-helper>
            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
        </clr-input-container>

        <clr-input-container ecsFormControlContainer>
            <label for="gitRepoPath">{{ 'ecs.gitRepoConfigEditor.gitRepoPath.label' | vtranslate }}</label>
            <input clrInput type="text" formControlName="git_path">
            <clr-control-helper>{{ 'ecs.gitRepoConfigEditor.gitRepoPath.helpText' | vtranslate }}</clr-control-helper>
            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
        </clr-input-container>

        <clr-input-container ecsFormControlContainer>
            <label for="gitRepoUsername">{{ 'ecs.gitRepoConfigEditor.gitRepoUsername.label' | vtranslate }}</label>
            <input clrInput type="text" formControlName="git_username">
            <clr-control-helper>{{ 'ecs.gitRepoConfigEditor.gitRepoUsername.helpText' | vtranslate }}</clr-control-helper>
            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
        </clr-input-container>
 
        <div class="password-container" *ngIf="mode==='create' || showToken">
            <clr-password-container ecsFormControlContainer >
                <label for="gitRepoAccessToken">{{ 'ecs.gitRepoConfigEditor.gitRepoAccessToken.label' | vtranslate }}</label>

                <input clrPassword class=" clr-password" name="git_access_token"
                    formControlName="git_access_token">

                    <clr-control-helper>{{ 'ecs.gitRepoConfigEditor.gitRepoAccessToken.helpText' | vtranslate }}</clr-control-helper>
                    <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
            </clr-password-container>
            <button *ngIf="mode==='edit'" (click)="cancelUpdateToken()" type="button" class="btn btn-sm btn-secondary btn-link btn-refresh">
                <cds-icon shape="undo" class="icon"></cds-icon>
            </button>
        </div>
        <div class="clr-form-control  clr-row" *ngIf="mode==='edit' && !showToken">
            <label class="clr-control-label" for="gitRepoAccessToken">{{ 'ecs.gitRepoConfigEditor.gitRepoAccessToken.label' | vtranslate }}</label>
            <div class="clr-control-container input-size-xl clr-col-md-8 clr-col-12">
                <div class="clr-input-wrapper">
                    <button class="btn btn-xl btn-success-outline" (click)="updateToken()">{{ 'ecs.sites.manageSite.form.updateAccessToken.label' | vtranslate
                    }}  </button>                        
                    <cds-icon *ngIf="tokenUpdateSuccess" status="success" shape="check-circle" size="25" class="icon"></cds-icon>
                </div>
            </div>
        </div>
    </form>
</ng-container>