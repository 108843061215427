export const ENG_ECS_EVENTS_TRANSLATIONS = {
  'ecs.operations.events.title': 'Events',
  'ecs.operations.events': 'Events generated by the user or host.',
  'ecs.operations.events.columns.event': 'Event',
  'ecs.operations.events.columns.event.placeholder': 'Filter Event',
  'ecs.operations.columns.source.placeholder': 'Filter Source',
  'ecs.operations.columns.time.placeholder': 'Filter Event',
  'ecs.operations.events.footer': 'Events per page',
  'ecs.events.datagrid.pagination.countDetails': '{0} - {1} of {2} events',
  'ecs.events.datagrid.pagination.noevents': 'No events found, all good!',
};
