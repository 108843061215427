import { Time } from '@angular/common';
import { CreateGitAssociation, CreateGroup } from '@ecs/ecs-api';
import { Timestamp } from 'rxjs';

export interface HostResp {
  results: IHostData[];
  total: number;
  page: number;
  perPage: number;
}

export interface IHostHealth {
  last_probe_time: Date;
  message: string;
  status: boolean;
  type: string;
}

export interface IHostData {
  name:string,
  git_repo_url: string;
  host_agent_details: {
    started_at: string;
    version: string;
  };
  host_cpu_capacity: number;
  host_health_conditions: {
    device_health_conditions: {
      battery: number;
      cpu_utilisation: number;
      memory_utilisation: number;
      storage_utilisation: number;
      temperature: number;
    };
    gitops_health_conditions: {
      gitops_desired_state_ready: string;
      gitops_desired_state_reconciling: string;
      gitops_repo_auth_success: string;
      gitops_repo_url_present: string;
      gitops_repo_url_reachable: string;
    };
    runtime_health_conditions: {
      certificate_expiration_date: string;
    };
  };
  host_registration_details: {
    host_vendor_identifier: string;
    host_model_identifier: string;
    host_serial_number: string;
  };
  host_health: string;
  host_memory_capacity: number;
  host_registration_status: string;
  host_storage_capacity: number;
  last_seen: string;
  unique_identifier: string;
  applied_group_labels: string[];
  desired_group_labels: string[];
  applied_groups_count: number;
  created_by: string;
}

export interface IHostInventoryItem {
  unique_identifier: string;
  health: string;
  status: string;
  workloads: number;
  gitURL: string;
  memory: string;
  cpuCount: number;
  storage: string;
  lastSeen: Date;
  location: string;
}

export interface IHost {
  host_vendor_identifier: string;
  host_model_identifier: string;
  host_serial_number: string;
  site_id: string;
}
export interface IAddHostData {
  data: IHost;
  hostDetailType?: string;
  file?: File;
}

export enum ESortOrder {
  ASCENDING = 'Asc',
  DESCENDING = 'Desc'
}

export interface ActivateHostInfo {
  id: number;
  url: string;
  description: string;
  access_token: string;
  username: string;
  branch: string;
}

export interface SupportedServersResp {
  result: string[];
}

export interface AddSupportedServerInfo {
  vendor: string;
  model: string;
}

export interface PaginatedHostsResponse {
  results: IHostData[];
  total: number;
  sortColumn: string;
}

export interface GroupInfo {
  label_key: string;
  label_value: string;
  description: string;
  members: string[];
  entity_type?: CreateGroup.EntityTypeEnum
}

export interface UpdateGroupInfo {
  id: number;
  description: string;
  members: {
    add_host_id_list: string[];
    remove_host_id_list: string[];
  };
}

export interface AddToGroupInfo {
  new_group_info: GroupInfo;
  update_group_info: UpdateGroupInfo;
}

export interface GroupRespData {
  id: number;
  description: string;
  label_key: string;
  label_value: string;
  member_details: {
    hosts: string[];
    total_host_count: number;
    valid_host_count: number;
  };
}

export interface GroupsResp {
  results: GroupRespData[];
  total: number;
  page: number;
  perPage: number;
}

export interface EditHostData {
  groupData: UpdateGroupInfo[];
  gitRepoData: CreateGitAssociation;
  hostIdentifier: string;
}

export const HOST_DEFAULT_FILTERS = { p: 1, s: 20, col: 'host_serial_number', order: ESortOrder.ASCENDING }