import { Component, Inject } from '@angular/core';
import { MODAL_DATA, OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { RemoveHostModalContentData } from './remove-host-modal-content.model';
import { HostRegistrationStatus } from '@ecs/ecs-api';

@Component({
  selector: 'app-remove-host-modal-content',
  templateUrl: './remove-host-modal-content.component.html',
  styleUrls: ['./remove-host-modal-content.component.scss']
})
export class RemoveHostModalContentComponent {
  readonly HostRegistrationStatus = HostRegistrationStatus;

  hosts: RemoveHostModalContentData['hosts'];
  isSingleHostDeleted = false;
  hostSerialNumber: string;
  hostsCountInput: number;
  hostListType: HostRegistrationStatus;
  modalRef: VmwNgxModalRef<OkCancel>;

  constructor(@Inject(MODAL_DATA) data: RemoveHostModalContentData, modalRef: VmwNgxModalRef<OkCancel>) {
    this.hosts = data.hosts;
    this.modalRef = modalRef;
    this.hostListType = data.hostListType;
    this.isSingleHostDeleted = data.isSingleHostDeleted;
    this.modalRef.buttons.submitButton.disabled = true;
  }

  onHostsCountInputChange(updatedValue: number) {
    this.hostsCountInput = updatedValue;
    this.modalRef.buttons.submitButton.disabled = true;
    if (this.hostsCountInput === this.hosts?.length) {
      this.modalRef.buttons.submitButton.disabled = false;
    }
  }
}
