import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, firstValueFrom, map, Observable } from 'rxjs';
import { OrgService , ActivateHostInfo } from '@ecs/ecs-platform';
import { CreateGitAssociation, GitreposService as GitReposApi } from '@ecs/ecs-api';
import { handleError } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class ActivateHostService {
  private readonly url = '/api/ecs/v0/orgs';
  private hostID: string;
  private associateGitUrl: string;
  private orgID: string;
  private response: any;

  constructor(private orgService: OrgService, private gitRepoApi: GitReposApi) {
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  updateHostIdentifier(hostID: string) {
    this.hostID = hostID;
  }

  getHostIdentifier() {
    return this.hostID;
  }

  associateHostToGit(gitDetails: CreateGitAssociation, hostID: string): Observable<CreateGitAssociation> {
    this.hostID = hostID;
    this.associateGitUrl = `${this.url}/${this.orgID}/hosts/${this.hostID}/gitrepo`;

    return this.gitRepoApi.createGitAssociation(hostID, this.orgID, gitDetails).pipe(
      catchError(handleError),
      map(() => gitDetails)
    );
  }
}
