import { Component, Input } from '@angular/core';
import { ICapacityAndUsageData } from '@ecs/ecs-platform';

@Component({
  selector: 'app-capacity-and-usage',
  templateUrl: './capacity-and-usage.component.html',
  styleUrls: ['./capacity-and-usage.component.scss']
})
export class CapacityAndUsageComponent {
  @Input() capacityAndUsageData: ICapacityAndUsageData;
}
