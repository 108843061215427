import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum AlertType {
  success = 'alert-success',
  failure = 'alert-danger'
}
export interface AlertMessage {
  type: AlertType;
  message: string;
  actionLabel?: string;
  navigationLink?: string;
}

@Injectable({
  providedIn: 'root'
})
export class KeswickAlertService {
  private message$ = new Subject<AlertMessage>();

  onMessage(): Observable<AlertMessage> {
    return this.message$.asObservable();
  }

  showMessage(message: AlertMessage): void {
    this.message$.next(message);
  }
}
