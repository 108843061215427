import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import {EventsService as EventsApi, SortOrder,  EventResp} from '@ecs/ecs-api';
import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { AlertType, KeswickAlertService, OrgService } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { Observable, finalize, map, of, switchMap, take, tap, catchError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends AbstractPaginatableListService<EventResp> {
  private defaultSortColumn = 'created_at';
  siteList = new BehaviorSubject<number>(0);


  constructor(
    private orgService: OrgService,
    private EventsApi: EventsApi,
    private keswickAlertService: KeswickAlertService,
    private l10nService: L10nService
  ) {
    super();
  }

  fetchData(pagination: ClrDatagridStateInterface<EventResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      const filters: Record<string, string> = pagination.filters?.reduce((acc, filter) => ({
        ...acc,
        [filter.property]: filter.value
      }), {});
      
      return this.EventsApi.getEvents(orgId, current, size, sortColumn as any, sortOrder, filters);
    }), map(events => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: events.page,
          size: events.per_page,
          from: events.per_page * (events.page - 1) + 1,
          to: events.per_page * events.page
        }
      });
      this.dataSource.next(events.results || []);
      this.totalItemsSource.next(events.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
      finalize(() => {
        this.loadingSource.next(false);
      }));
  } 

}
