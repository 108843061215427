<div>
  <p>{{'ecs.removehostmodal.title' | vtranslate}}:</p>
  <!-- <div *ngIf="isSingleHostDeleted; else hostDeletionList">
    <li>
      <strong>{{ hostSerialNumber }}</strong>
    </li>
    <br />
  </div>
  <ng-template #hostDeletionList> -->
  <ul>
    <li *ngFor="let host of hosts">
      <strong>{{ host?.hostName }}</strong>
    </li>
    <br />
  </ul>
  <!-- </ng-template> -->
  <p *ngIf="HostRegistrationStatus.Registered === hostListType">
    <cds-icon shape="exclamation-triangle" solid status="warning" size="24"></cds-icon>
    {{'ecs.removehostmodal.inactivehost' | vtranslate}}
  </p>
  <p *ngIf="HostRegistrationStatus.Registered !== hostListType">
    <cds-icon shape="exclamation-triangle" solid status="warning" size="24"></cds-icon>
    {{'ecs.removehostmodal.selectedhost' | vtranslate}}
    <span [innerHTML]="'ecs.removehostmodal.irrevesibleoperation' | vtranslate"></span>
  </p>

  <p>
    <span>
      <span [innerHTML]="'ecs.removehostmodal.selectedhostsfordeletion' | vtranslate : hosts?.length"></span>
       {{'ecs.removehostmodal.reconfirm' | vtranslate}}:</span>
    <input type="number" clrInput focusVisible required [ngModel]="hostsCountInput" (ngModelChange)="onHostsCountInputChange($event)" />
  </p>
</div>
<p>{{'ecs.confirmtoproceed' | vtranslate}}</p>
