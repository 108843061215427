<clr-main-container class="main-container" [ngClass]="'ecs-light'">
  <!-- TODO: VCO Removal -->
  <!-- <csp-header
    *ngIf="!cspServiceSdk.isEmbedded"
    [authToken]="authToken"
    [serviceRefLink]="serviceId"
    [options]="headerOptions"
    [openDrawer]="openDrawer"
    (switchOrg)="switchOrg($event)"
    (supportButtonClick)="openSupportForm()">
    <div class="csp-header-developer-panel">
      <div class="csp-header-docker-image-tag clr-row csp-dev-item">
        <label class="clr-col-5">{{ 'ecs.commit' | vtranslate }}</label>
        <div class="clr-col-7">{{ environment.commit || 'unknown' }}</div>
      </div>
    </div>
  </csp-header> -->
  <!-- <vmw-theme-switch-button classes="nav-icon nav-link" (click)="onThemeChange($event)" ></vmw-theme-switch-button> -->

  <app-sub-nav 
      [navItems]="horizontalTabs" 
      [selectedNavItemId]="selectedHorizontalTabId"
      (navItemSelected)="redirect($event)">
  </app-sub-nav>
  <div class="content-container">
    <div ecs-sidenav></div>
    <div class="content-area" #contentArea>
      <app-keswick-alert></app-keswick-alert>
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
<!-- 
<clr-modal [(clrModalOpen)]="showSessionExpiredModal" [clrModalClosable]="false">
  <h3 class="modal-title">{{ 'ecs.sessionexpired.title' | vtranslate }}</h3>
  <div class="modal-body">
    <p>{{ 'ecs.sessionexpired.body' | vtranslate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="signOut()">{{ 'ecs.logout' | vtranslate }}</button>
    <button type="button" class="btn btn-outline" (click)="refreshAuthToken()">{{ 'ecs.refresh' | vtranslate }}</button>
  </div>
</clr-modal> -->
