import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";

export interface JsonRPCResponse<T> {
    id: number;
    jsonrpc: string;
    result?: T;
    error?: {
       code: number;
       message: string;
    };
 }

 export interface EnterpriseDetails {
    logicalId: string;
    [key: string]: string; // dynamic keys object to avoid copying everything that the getEnterprise returns
 }

@Injectable({
    providedIn: 'root'
})
export class VcoApiService {
    private jsonRpcRequestId: number;
    private enterpriseDetailsMap: Map<number, EnterpriseDetails> = new Map();
    constructor(private http: HttpClient) {
        this.jsonRpcRequestId = 1;
    }

    getEnterprise(customerId: number): Observable<EnterpriseDetails> {
        if (this.enterpriseDetailsMap && this.enterpriseDetailsMap.has(customerId)) {
            return of(this.enterpriseDetailsMap.get(customerId));
        }
        return this.http.post<JsonRPCResponse<EnterpriseDetails>>(
            '/portal/',
            {
               id: this.jsonRpcRequestId++,
               jsonrpc: "2.0",
               method: "enterprise/getEnterprise",
               params: {id: customerId}
            },
            { withCredentials: true }
          ).pipe(
            map (({result}) => {
                this.enterpriseDetailsMap.set(customerId, result);
                return result;
            })
          );
    }

}