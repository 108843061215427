<ecs-page-header [breadcrumbs]="breadcrumbs" [pageTitle]="(mode==='create'?'ecs.addhost.title':'ecs.edit' )| vtranslate">
  <ng-container *ngIf="addHostForm">
    <form class="add-host-form" clrStepper [clrInitialStep]="currentStep" clrForm [formGroup]="addHostForm"
      clrLayout="horizontal" [clrLabelSize]="4">
      <!-- Host Registration Details -->
      <ng-container *ngIf="addHostForm?.get('hostDetailsStepperForm')?.get('hostDetails') !== null">
        <clr-stepper-panel formGroupName="hostDetailsStepperForm">
          <clr-step-title>{{'ecs.hostdetail' | vtranslate}}</clr-step-title>
          <clr-step-content *clrIfExpanded>
            <app-add-host-modal-content [mode]="mode" formControlName="hostDetails"></app-add-host-modal-content>
            <button class="clr-step-button btn btn-outline" (click)="navigateToSiteDetails()"
              [disabled]="!isHostDetailsFormValid">{{'ecs.addhost.next' | vtranslate }}</button>
          </clr-step-content>
        </clr-stepper-panel>
      </ng-container>

      <!-- Host Site Configuration -->
      <clr-stepper-panel formGroupName="siteDetailsStepperForm">
        <clr-step-title>{{'ecs.addhost.addToSite' | vtranslate}}</clr-step-title>
        <clr-step-content *clrIfExpanded>
          <ecs-add-host-site-details [mode]="mode" formControlName="siteDetails"
            [sites]="sites"></ecs-add-host-site-details>
          <button [disabled]="!isSiteDetailsFormValid" clrStepButton="next">{{'ecs.addhost.next' | vtranslate
            }}</button>
        </clr-step-content>
      </clr-stepper-panel>

      <!-- Git Association -->
      <clr-stepper-panel formGroupName="gitRepoAssociation">
        <clr-step-title>{{ 'ecs.addhost.gitAssociation' | vtranslate }}</clr-step-title>
        <clr-step-content *clrIfExpanded>
          <p>{{ 'ecs.addHost.form.gitRepo.step.description' | vtranslate }}</p>
          <clr-radio-container clrInline>
            <clr-radio-wrapper>
              <input type="radio" clrRadio formControlName="git_config_source" [value]="GitConfigSource.Site"
                name="git_config_source">
              <label>{{ 'ecs.addHost.form.inheritFromSite.label' | vtranslate }}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio formControlName="git_config_source" [value]="GitConfigSource.Host"
                name="git_config_source">
              <label>{{ 'ecs.addHost.form.configureAtHost.label' | vtranslate }}</label>
            </clr-radio-wrapper>
          </clr-radio-container>
          <ecs-git-repo-config-editor formControlName="gitDetails" [gitRepos]="gitRepos" [mode]="mode"></ecs-git-repo-config-editor>
          <button  (click)="updateTokenSuccess()" [disabled]="!isgitRepoAssociationValid" clrStepButton="next">{{'ecs.addhost.next' | vtranslate
            }}</button>
        </clr-step-content>
      </clr-stepper-panel>

      <clr-stepper-panel formGroupName="tagsDetails">
        <clr-step-title>{{ 'ecs.sites.manageSite.step.labels.title' | vtranslate }}</clr-step-title>
        <clr-step-description>{{ 'ecs.sites.manageSite.step.labels.description' | vtranslate
          }}</clr-step-description>
        <clr-step-content *clrIfExpanded>
          <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-6">
            <ecs-tags-editor formControlName="tags"></ecs-tags-editor>
          </div>
        </clr-step-content>
      </clr-stepper-panel>
    </form>
    <div class="actions-button-bar">
      <button type="submit" class="btn btn-primary" [disabled]="(loading$ | async) || !addHostForm.valid"
        (click)="submit()">{{ (mode==='create' ? 'ecs.addhost.addhost' :'ecs.action.edithost') | vtranslate }}</button>
      <button type="button" class="btn btn-link" (click)="cancel()">{{ 'common.labels.cancel' | vtranslate
        }}</button>
    </div>
  </ng-container>
  <ecs-spinner *ngIf="loading$ | async"></ecs-spinner>
</ecs-page-header>