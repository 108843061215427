import { AfterViewInit, Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { L10nService } from '@vmw/ngx-vip';
import { BehaviorSubject, debounceTime, map } from 'rxjs';
import { EcsFormControlContainerDirective } from '../ecs-form-control-container.directive';
import { Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
  templateUrl: './ecs-form-control-errors.component.html',
  selector: 'ecs-form-control-errors'
})
export class EcsFormControlErrorsComponent extends Unsubscriber implements AfterViewInit {
  private readonly lengthKeys: readonly string[] = ['maxlength', 'minlength'];
  private errorsSource = new BehaviorSubject<ValidationErrors | null>(null);
  
  public error$ = this.errorsSource.pipe(
    map((errors) => {
      const errorKey = errors && Object.keys(errors).length > 0 ? Object.keys(errors)[0] : null;

      if (!errors || !errorKey) {
        return null;
      }
      
      const errorParams = this.getErrorParams(errors, errorKey);
      return this.l10nService.getMessage('common.validationError.' + errorKey, errorParams);
    }));

  constructor(private formControlContainer: EcsFormControlContainerDirective, private l10nService: L10nService) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.formControlContainer.abtractContainer && this.formControlContainer.abtractContainer.control) {
      const control = this.formControlContainer.abtractContainer.control;
      this.subs = this.formControlContainer.abtractContainer.control.statusChanges.pipe(debounceTime(50)).subscribe((status) => {
        this.errorsSource.next(control.errors);
      });
    }
  }

  protected getErrorParams(errors: Record<string, any>, key: string): string[] {
    const params = errors[key];

    if (this.lengthKeys.includes(key)) {
       return [params.requiredLength];
    }

    if (key === 'max') {
       return [params.max];
    }

    return Array.isArray(params) ? params : [];
 }
}
