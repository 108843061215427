import { Directive, Optional, Self } from '@angular/core';

import { ClrAbstractContainer, ClrControlContainer, ClrInputContainer, ClrSelectContainer, ClrComboboxContainer, ClrPasswordContainer } from '@clr/angular';

@Directive({
  selector: '[ecsFormControlContainer]'
})
export class EcsFormControlContainerDirective {
  public abtractContainer: ClrAbstractContainer;

  constructor(
    @Optional() @Self() public clrInputContainer: ClrInputContainer,
    @Optional() @Self() public clrControlContainer: ClrControlContainer,
    @Optional() @Self() public clrSelectContainer: ClrSelectContainer,
    @Optional() @Self() public clrComboboxContainer: ClrComboboxContainer,
    @Optional() @Self() public clrPasswordContainer: ClrPasswordContainer
  ) {
    switch (true) {
      case !!clrInputContainer:
        this.abtractContainer = clrInputContainer;
        break;
      case !!clrSelectContainer:
        this.abtractContainer = clrSelectContainer;
        break;
      case !!clrControlContainer:
        this.abtractContainer = clrControlContainer;
        break;
      case !!clrComboboxContainer:
        this.abtractContainer = clrComboboxContainer;
        break;
      case !!clrPasswordContainer:
        this.abtractContainer = clrPasswordContainer;
        break;
      default:
        console.error('Control container not found');
        break;
    }
  }
}
