import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VmwNgxModalService } from '@vmw/ngx-modal-service';
import { AlertType, CONFIGURE_HOSTS_DETAILS_ROUTE, getAdjustedRoute, KeswickAlertService } from '@ecs/ecs-platform';
import { OperationsService } from '../operations/operations.service';
import { EAlertSeverity, IAlertData } from '../operations/operations.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ESortOrder } from '../events/events.model';
import { L10nService } from '@vmw/ngx-vip';
import { Lumos, getTimeStamp, AbstractPaginatableListComponent } from '@ecs/ecs-common';
import {AlertsService} from './alerts.service';
import { AlertResp } from '@ecs/ecs-api';
import { superscriptIcon } from '@cds/core/icon';
import { ClrDatagridStateInterface } from '@clr/angular';
import { take } from 'rxjs';

@Component({
  selector: 'ecs-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent extends AbstractPaginatableListComponent<AlertResp>{
  readonly SEVERITY_FILTER_KEY = 'severity';
  private timeoutId: number = null;
  private filterValues=''; 
  public selectedAlerts: string[] = [];
 
  public severity = EAlertSeverity;
  public warningAlert: string;
  public criticalAlert: string;
  public getTimeStamp = getTimeStamp;
  public alertSourceFilterInput: string;
  public alertMessageFilterInput: string;
  @ViewChild('tmpl', { read: TemplateRef }) tmpl: TemplateRef<any>;
  public partialLinkToConfigureHostDetailsView: string;

  constructor(
    private operationsService: OperationsService,
    private modalService: VmwNgxModalService,
    private alertService: AlertsService,
    private router: Router,
    private l10service: L10nService,
    private alertMsgService: KeswickAlertService,
    private activatedRoute: ActivatedRoute
  ) {
    super(alertService);
    this.subs = this.activatedRoute.queryParams.subscribe ((queryParams) => {
      alertService.extraFilters = queryParams;
    });
    this.partialLinkToConfigureHostDetailsView = getAdjustedRoute(this.router, CONFIGURE_HOSTS_DETAILS_ROUTE);
  }

  
  onFilterChange(filterColumn: string, event: Event) {
    clearTimeout(this.timeoutId);
   
    this.timeoutId = setTimeout(() => {
      const target = event.target as HTMLInputElement;
      const filterValue = target.value;

      if (filterColumn === 'severity') {
        if (this.warningAlert && this.criticalAlert) {
          this.filterValues ='';
        } else if (this.warningAlert) {
          this.filterValues=EAlertSeverity.WARNING;
        } else if (this.criticalAlert) {
          this.filterValues=EAlertSeverity.CRITICAL;
        } else {
          this.filterValues ='';
        }
      } 
       this.onDatagridRefresh({});

    }, 500) as unknown as number;
  }

  onPageChange(): void {
    this.selectedAlerts = [];
  }

  // sort(sortColumn: string, sortDirection: number): void {
  //   this.loading = true;
  //   this.sortColumn = sortColumn;
  //   this.sortOrder = sortDirection === 1 ? ESortOrder.ASCENDING : ESortOrder.DESCENDING;
  //   this.operationsService
  //     .fetchAlerts(this.currentPage, this.pageSize, this.filterValues, this.sortColumn, this.sortOrder)
  //     .subscribe((resp) => {
  //       this.alerts = resp.results;
  //       this.loading = false;
  //       this.totalAlerts = resp.total;
  //     });
  // }

  openDismissAlertModal() {
    const modalRef = this.modalService.openOkCancel(this.tmpl, {
      title: this.l10service.getMessage('ecs.dismissalert'),
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.yes'),
          handler: (ref) => {
            Lumos.trackClickEvent('Alerts', 'Dismiss Alert');
            // the modal is closed with 'accept'
            ref.close('accept');
          }
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.no'),
          handler: (ref) => {
            // the modal is closed with 'deny'
            ref.close('deny');
          }
        }
      }
    });

    modalRef.onClose().subscribe((result) => {
      if (result === 'accept') {
        
        this.operationsService.dismissAlerts(this.selectedAlerts).subscribe(() => {          
          this.selectedAlerts = [];
          this.alertMsgService.showMessage({
            type: AlertType.success,
            message: this.l10service.getMessage('ecs.dismissalert.success')
          });
          this.onRefresh();
          
        });
      }
    });
  }

  // refreshAlertsDatagrid() {
  //   // Set loading state to true while fetching the new grid values
  //   this.loading = true;

  //   // Reset filters map on refresh
  //   this.filterValues?.clear();

  //   // Reset all filter values
  //   this.alertMessageFilterInput = '';
  //   this.alertSourceFilterInput = '';
  //   this.warningAlert = this.criticalAlert = '';

  //   this.operationsService.fetchAlerts(this.currentPage, this.pageSize, this.filterValues).subscribe((resp) => {
  //     this.alerts = resp?.results;
  //     this.loading = false;
  //     this.totalAlerts = resp?.total;
  //     // Clear previous selections on datagrid refresh
  //     this.selectedAlerts = [];
  //   });
  // }

  override onDatagridRefresh(data: ClrDatagridStateInterface<any>): void {
    this.subs = this.pagination$.pipe(take(1)).subscribe(pagination => {
        const { current, size } = data?.page ?? pagination.page;
        const { by, reverse } = data?.sort ?? pagination.sort ?? { by: '', reverse: false };
        const filters = this.adjustFilters(pagination);
        const updatedPagination = {
            ...pagination,
            page: {
                ...pagination.page,
                current,
                size
            },
            sort: {
                by,
                reverse
            },
            filters: filters  //data?.filters ?? [],
        };
        this.alertService.pagination = updatedPagination;
    });
  }

  private adjustFilters(pagination: ClrDatagridStateInterface<AlertResp>) {
    let filters = pagination?.filters ? pagination?.filters.map((filter) => filter) : [];
    // if column filters are present, add/modify the values of the pagination filters
    if (this.filterValues) {
      const foundIndex = filters.findIndex(item => item.property == this.SEVERITY_FILTER_KEY);
      if (foundIndex == -1) {
        filters.push({ property: this.SEVERITY_FILTER_KEY, value: this.filterValues });
      } else {
        filters[foundIndex].value = this.filterValues;
      }
    }
    // if column filters are not present, remove the earlier ones if added to pagination filters
    if (!this.filterValues) {
      filters = filters.filter((filterValue) => {
        return filterValue.property != this.SEVERITY_FILTER_KEY;
      });
    }
    return filters;
  }
}
