export const ENG_ECS_HOST_DETAILS_TRANSLATIONS = {
  'ecs.hostdetail.backtohostlist': 'Back To Host List',
  'ecs.hostdetail.actions': 'Actions',
  'ecs.hostdetail.host': 'Host',
  'ecs.hostdetail.actions.addtogroup': 'Add to group',
  'ecs.hostdetail.actions.remove': 'Remove',
  'ecs.hostdetail': 'Host Details',
  'ecs.hostdetail.nogitrepo': 'No Git Repo',
  'ecs.hostdetail.inactive': 'Inactive',
  'ecs.hostdetail.esxi': 'ESXi',
  'ecs.hostdetail.ecsHostVersion': 'ECS Host Version',
  'ecs.hostdetail.logs': 'Logs',
  'ecs.hostdetail.logbundle': 'Latest log bundle',
  'ecs.hostdetail.generatelogbundle': 'Generate Log Bundle',
  'ecs.hostdetail.capacitydetail.title': 'Capacity and Usage',
  'ecs.hostdetail.capacitydetail.title.tooltip':
    'Note as hosts connect to Edge Compute Service asynchronously, this usage data is a snapshot only.',
  'ecs.hostdetail.kubernetesdetails.title': 'Kubernetes Details',
  'ecs.hostdetail.kubernetesdetails.nodetails': 'No details available',
  'ecs.hostdetail.grouplabels.appliedgrouptooltip': 'This label is successfully applied on the host.',
  'ecs.hostdetail.grouplabels.unappliedgrouptooltip': 'This label is intended, but not yet applied on the host.',
  'ecs.hostdetail.gitops': 'GitOps',
  'ecs.host.gitRepoPath': 'Path',
  'ecs.host.gitRepoBranch': 'Branch',
  'ecs.hostdetail.hardware': 'Hardware',
  'ecs.hostdetail.generatelogbundlesuccess':
    'Log generation request has been sent, please click latest log bundle button after a few minutes to get logs!',
  'ecs.hostdetail.capacitydata.utilization.unit': 'GHz',
  'ecs.hostdetail.capacitydata.utilization.label': 'CPU',
  'ecs.hostdetail.capacitydata.utilization.warningText': 'CPU utilization above "{0}"% can cause performance degradation',
  'ecs.hostdetail.capacitydata.memory.unit': 'GB',
  'ecs.hostdetail.capacitydata.memory.label': 'Memory',
  'ecs.hostdetail.capacitydata.memory.warningText': 'Memory utilization above "{0}"% can cause performance degradation',
  'ecs.hostdetail.capacitydata.storage.unit': 'GB',
  'ecs.hostdetail.capacitydata.storage.label': 'Storage',
  'ecs.hostdetail.capacitydata.storage.warningText': 'Storage utilization above "{0}"% can cause performance degradation',
  'ecs.hostdetail.actions.reboot': 'Reboot',
  'ecs.hostdetail.kubernetesdetails.workernode': 'Worker Node',
  'ecs.hostdetail.kubernetesdetails.workernode.version': 'Version',
  'ecs.hostdetail.kubernetesdetails.workernode.ipaddress': 'IP Address',
  'ecs.hostdetail.kubernetesdetails.control.plane.version': 'Control Plane Version'
};
