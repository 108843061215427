import {
   Component,
   Input,
   Output,
   EventEmitter,
   ViewChildren,
   QueryList,
   ChangeDetectionStrategy,
   ChangeDetectorRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClrVerticalNavLink } from '@clr/angular';
import { NavLink, NavSection } from '../sidenav.config';
import { Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
   selector: 'ecs-sidenav-section',
   templateUrl: './section.component.html',
   styleUrls: ['./section.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavSectionComponent extends Unsubscriber {
   @Input() section: NavSection;
   @Input() collapsed: boolean;
   @Output() linkSelected: EventEmitter<NavLink> = new EventEmitter<NavLink>();

   @ViewChildren(ClrVerticalNavLink) navLinks: QueryList<unknown>;

   hideSection$ = new BehaviorSubject<boolean>(true);

   constructor(private cd: ChangeDetectorRef) {
      super();
   }

   navLinkClicked(link: NavLink): void {
      this.linkSelected.emit(link);
   }

   ngAfterViewInit(): void {
      this.navLinks.notifyOnChanges();
      this.subs = this.navLinks.changes.subscribe((links) => {
         this.hideSection$.next(links.length === 0);
         this.cd.detectChanges();
      });

      setTimeout(() => {
         this.hideSection$.next(this.navLinks.length === 0);
         this.cd.detectChanges();
      });
   }

   trackByName(index: number, item: NavLink): string {
      return item.name;
   }
}
