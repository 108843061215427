<ng-container *ngIf="!isWarning; else warning">
    <div>{{ message }}</div>
</ng-container>

<ng-template #warning>
    <clr-alert [clrAlertType]="warningType" [clrAlertClosable]="false">
        <clr-alert-item>
            {{ message }}
        </clr-alert-item>
    </clr-alert>
    <div *ngIf='bodyContent' class="additional-block">{{ bodyContent }}</div>
    <div *ngIf='additionalMessage' class="additional-block">{{ additionalMessage }}</div>
</ng-template>