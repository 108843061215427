import { ChangeDetectorRef, Component, Input, OnInit, forwardRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { HostsService } from '../../services/hosts.service';
import { requiredWhen } from '../../utils/validators/validators';
import { AddSupportedServerInfo } from '@ecs/ecs-platform';
import { HostRegistrationType, SourceMode } from '../add-host/add-host.component';
import { L10nService } from '@vmw/ngx-vip';
import { GlobalConst } from '@ecs/ecs-common';




@Component({
  selector: 'app-add-host-modal-content',
  templateUrl: './add-host-modal-content.component.html',
  styleUrls: ['./add-host-modal-content.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddHostModalContentComponent),
      multi: true
    }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddHostModalContentComponent extends Unsubscriber implements OnInit, ControlValueAccessor {
  @Input() mode: SourceMode = SourceMode.CREATE

  addHostForm: FormGroup;
  supportedHostVendors: string[] = [];
  supportedHostModels: string[] = [];
  newHostVendor: string;
  addingVendor = false;
  newHostModel: string;
  addingModel = false;
  vendorAdded = false;
  modelAdded = false;
  isAddVendorModelModalOpen = false;
  addNewVendorModelReq: AddSupportedServerInfo;
  hostVendorResp: any;
  file: File = null;
  http: any;
  private vendor: string;
  private model: string;
  private serialNumber: string;
  private name: string;
  public isModelAlreadyPresent = false;
  public isVendorAlreadyPresent = false;
  filePath = '';
  formControlvalue: any;
  selectedActivationType = ''
  hostRegistrationTypeEnum = HostRegistrationType
  isHostNameValid=true;
  hostNameFormGroup: FormGroup;

  constructor(
    private cdr: ChangeDetectorRef,
    private hostsService: HostsService,
    private l10nService: L10nService
  ) {
    super()
  }

  get hostDetailType() {
    return this.addHostForm.get('hostDetailType');
  }

  get csvFile() {
    return this.addHostForm.get('csvFile');
  }

  get fileName() {
    return this.addHostForm.get('fileName').value
  }

  get isModeEdit() {
    return this.mode === SourceMode.EDIT
  }

  get hostName(){
    return this.addHostForm.get('name').value
  }

  writeValue(obj: any): void {
    let hostDetailType = obj.hostDetailType
    this.activationTypeChanged(hostDetailType)
    obj && this.addHostForm.setValue(obj, { emitEvent: false })
    this.hostNameFormGroup.get('name').setValue(obj.name)
    this.subscribeToHostNameChanges();
  }

  registerOnChange(fn: any): void {
    this.subs = this.addHostForm.valueChanges.subscribe(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {
  }

  ngOnInit(): void {
    this.buildForm()

    // Call Get Vendors API to fetch list of supported vendors
    if (this.mode === SourceMode.CREATE) {
      this.subs = this.hostsService.getSupportedVendors().subscribe((res) => {
        this.supportedHostVendors = res.result;
      });
    }
  }

  buildForm() {
    this.addHostForm = new FormGroup({
      hostDetailType: new FormControl(HostRegistrationType.HARDWARE_INFO, [Validators.required]),
      vendor: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.HARDWARE_INFO)]),
      model: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.HARDWARE_INFO)]),
      serialNumber: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.HARDWARE_INFO)]),
      name: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.HARDWARE_INFO),Validators.pattern(GlobalConst.patterns.nameRegex)]),
      csvFile: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.BULK_UPLOAD)]),
      fileName: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.BULK_UPLOAD)]),
      activationKey: new FormControl('', [requiredWhen('hostDetailType', HostRegistrationType.ACTIVATION_KEY)]),
      isHostDetailsValid: new FormControl(true),
    });
    this.hostNameFormGroup=new FormGroup({
      name: new FormControl('', [Validators.pattern(GlobalConst.patterns.nameRegex)]),
    })
    if (this.mode === SourceMode.EDIT) {
      this.addHostForm.disable()
    }
  }

  private subscribeToHostNameChanges(){
    this.subs = this.hostNameFormGroup.get('name').valueChanges.subscribe((name: string) => {
      if (this.hostNameFormGroup.get('name').errors?.['pattern']) {
        this.hostNameFormGroup.get('name').setErrors({ invalidPattern: true })
      }
      this.addHostForm.get('name').setValue(name)
    })
  }


  onVendorInput(event: string) {
    if (event === this.addHostForm.get('vendor').value)
      return;

    this.isVendorAlreadyPresent = false;
    this.addHostForm.get('vendor').setValue(event.trim());

    // Reset value for model, on every vendor input
    if (event.length != 0) {
      const modelFormControl = this.addHostForm.get('model');
      modelFormControl.clearValidators(); // Clear the validator temporarily
      modelFormControl.setValue(''); // Reset the form value
      modelFormControl.setValidators(Validators.required); // Restore the validator
    }

    // Call Get Model API if this is a known vendor
    if (this.supportedHostVendors.includes(event)) {
      this.onVendorSelectChange();
    } else {
      this.supportedHostModels = [];
    }
  }

  onModelInput(event: string) {
    this.isModelAlreadyPresent = false;
    this.addHostForm.get('model').setValue(event.trim());
  }

  onVendorSelectChange() {
    const selectedVendorValue = this.addHostForm.get('vendor').value;

    // Call GetModels API to fetch valid models corresponding to selected make
    this.subs = this.hostsService.getSupportedModels(selectedVendorValue).subscribe((res) => {
      this.supportedHostModels = res.result ? res.result : [];
    });
  }

  AddNewVendorModel(type: string) {
    const modelFormControl = this.addHostForm.get('model');
    const vendorFormControl = this.addHostForm.get('vendor');
    if (type == 'vendor') {
      this.addingVendor = true;
      this.newHostVendor = this.addHostForm.get('vendor').value;
      this.isVendorAlreadyPresent = this.supportedHostVendors.includes(vendorFormControl?.value);
      if (!this.isVendorAlreadyPresent) {
        this.supportedHostVendors.push(this.newHostVendor);
        vendorFormControl.setValue(this.newHostVendor);
      } else {
        vendorFormControl.setErrors({ vendorExists: true });
      }
    } else {
      // Only save the combination of vendor and model, not each individually.
      this.addingModel = true;
      this.newHostModel = this.addHostForm.get('model').value;
      this.isModelAlreadyPresent = this.supportedHostModels.includes(modelFormControl?.value);
      // Add new model to the list of supported models if it is not a duplicate value
      if (!this.isModelAlreadyPresent) {
        this.supportedHostModels.push(this.newHostModel);
      }

      modelFormControl.setValue(this.newHostModel);
      this.modelAdded = true;

      if (vendorFormControl.value.length != 0) {
        // Call AddSupportedServer API to add custom vendor and model if the
        // same vendor, model pair is not present already
        if (!this.isModelAlreadyPresent) {
          this.addNewVendorModelReq = {
            vendor: vendorFormControl.value,
            model: modelFormControl.value
          };

          this.subs = this.hostsService.addSupportedServer(this.addNewVendorModelReq).subscribe(() => null);
        } else {
          modelFormControl.setErrors({ modelExists: true });
        }
      }
    }

    this.cdr.detectChanges();
  }


  onFileSelected(event: Event) {
    const formElement = event.target as HTMLInputElement;
    if (formElement.files && formElement.files[0]) {
      const file: File = formElement.files[0];
      this.file = formElement.files[0];
      this.addHostForm.get('fileName').setValue(file.name)
      this.addHostForm.get('csvFile').setValue(file)

      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.filePath = (formElement as any).result;
      };
      reader.readAsDataURL(formElement.files[0]);
    }
  }

  activationTypeChanged(activationtype: string) {
    switch (activationtype) {
      case HostRegistrationType.ACTIVATION_KEY:
        this.selectedActivationType = this.l10nService.getMessage('ecs.addhostmodal.hostdetails.withActivationKey')
        break;
      case HostRegistrationType.BULK_UPLOAD:
        this.selectedActivationType = this.l10nService.getMessage('ecs.addhostmodal.hostdetails.withBulkUpload')
        break;
      case HostRegistrationType.HARDWARE_INFO:
        this.selectedActivationType = this.l10nService.getMessage('ecs.addhostmodal.hostdetails.withHardwareInfo')
        break;
      default:
        this.selectedActivationType = this.l10nService.getMessage('ecs.addhostmodal.hostdetails.withHardwareInfo')
        break;
    }
    this.addHostForm.get('hostDetailType').setValue(activationtype)
  }

}
