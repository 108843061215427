import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { EventResp } from '@ecs/ecs-api';
import { AbstractPaginatableListComponent, Lumos } from '@ecs/ecs-common';
import { EVENT_LIST_COLUMNS } from './event-list.config'; 
import { validate as uuidValidate } from 'uuid';
import { EventsService } from './events.service';
import { ESortOrder } from './events.model';
import { CONFIGURE_HOSTS_DETAILS_ROUTE, getAdjustedRoute } from '@ecs/ecs-platform';
import { ClrDatagridSortOrder } from '@clr/angular';


@Component({
  selector: 'ecs-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends AbstractPaginatableListComponent<EventResp> { 
  readonly columns = EVENT_LIST_COLUMNS;
  readonly SortOrder = ClrDatagridSortOrder;

  public partialLinkToConfigureHostDetailsView: string;

  constructor(private eventsService: EventsService, private router: Router, private activatedRoute: ActivatedRoute) {
    super(eventsService);
    this.subs = activatedRoute.queryParams.subscribe((queryParams) => {
      eventsService.extraFilters = queryParams;
    });
    this.partialLinkToConfigureHostDetailsView = getAdjustedRoute(this.router, CONFIGURE_HOSTS_DETAILS_ROUTE);
  }
 

  isUUID(data: string) {
    return uuidValidate(data);
  }

}
