<div class="clr-row">
  <div class="clr-col-9">
    <h2>{{'ecs.launchpad.edgecomputeservice.title' | vtranslate}}</h2>
    <p>
      {{'ecs.launchpad.edgecomputeservice.subtitle' | vtranslate}}
    </p>
    <br />
    <p>
      {{'ecs.launchpad.edgecomputeservice.description' | vtranslate}}
    </p>
  </div>
  <div class="clr-col-3">
    <img src="/apps/ecs/assets/vmw-prod-ico-Edge-Compute-Stack.svg" alt="Getting Started" />
  </div>
</div>
<div class="clr-row buttons">
  <div class="clr-col">
    <a [href]="LEARN_MORE_ABOUT_KESWICK" target="_blank" rel="noopener noreferrer">
      <button class="btn btn-primary">{{'ecs.launchpad.edgecomputeservice.learnmore' | vtranslate}}</button>
    </a>

    <button class="btn btn-info" (click)="isPlayButtonClicked = true">{{'ecs.launchpad.edgecomputeservice.watchademo' | vtranslate}}</button>

    <app-video-poster
      [videoSource]="videoSource"
      [altText]="altText"
      [autoPlay]="isPlayButtonClicked"
      (notifyVideoClose)="isPlayButtonClicked = !$event">
    </app-video-poster>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col">
    <h3>{{'ecs.launchpad.edgecomputeservice.gettingstarted' | vtranslate}}</h3>
  </div>
</div>

<app-getting-started></app-getting-started>

<app-launchpad-footer></app-launchpad-footer>
