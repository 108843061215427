import { Injectable } from "@angular/core";
import { ClrDatagridStateInterface } from "@clr/angular";
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, startWith } from "rxjs";

export const DEFAULT_PAGINATION: ClrDatagridStateInterface<any> = {
    page: {
        from: -1,
        to: -1,
        size: 20,
        current: 1
    },
    sort: {
        by: '',
        reverse: false
    },
    filters: []
};

@Injectable()
export abstract class AbstractPaginatableListService<T> {
    protected readonly loadingSource = new BehaviorSubject<boolean>(false);
    protected readonly dataSource = new BehaviorSubject<T[]>([]);
    protected readonly totalItemsSource = new BehaviorSubject<number>(0);
    protected readonly paginationSource = new BehaviorSubject<ClrDatagridStateInterface<T>>(DEFAULT_PAGINATION);
    protected readonly forceRefreshSource = new Subject<number>();
    protected _extraFilters: Record<string, string>;

    readonly loading$ = this.loadingSource.asObservable();
    readonly data$ = this.dataSource.asObservable();
    readonly totalItems$ = this.totalItemsSource.asObservable();
    readonly pagination$ = this.paginationSource.asObservable();
    readonly forceRefresh$ = this.forceRefreshSource.pipe(
        distinctUntilChanged((prev, current) => prev === current),
        startWith(null)
    );

    set pagination(pagination: ClrDatagridStateInterface<T>) {
        this.paginationSource.next(pagination);
    }


  set extraFilters(filters: Record<string, string>) {
    // Modify the pagination object by removing earlier extra filters and adding the new ones if any
    const currentPagination = this.paginationSource.getValue();

    // Remove earlier extra filters that were put in pagination object
    const currFilters = currentPagination.filters;
    const newFilters = [];
    for (const filter of currFilters) {
      const remove = this._extraFilters && this._extraFilters[filter.property] && this._extraFilters[filter.property] == filter.value;
      if (!remove) {
       newFilters.push(filter);
      }
   }

   // Save the new extra filters
   this._extraFilters = filters;

   // Add the new extra filters
   for (const [key, value] of Object.entries(this._extraFilters)) {
    newFilters.push({property: key, value: value});
   }
  
   // Reset the pagination object with the new combined filters
   this.pagination = {
    ...currentPagination,
    filters: newFilters
   }
  }

    forceRefresh() {
        this.forceRefreshSource.next(new Date().getTime());
    }

    abstract fetchData(pagination: ClrDatagridStateInterface<T>): Observable<void>;
}
