import {ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { HostRegDetails, HostResp } from '@ecs/ecs-api';
import { convertLabelsToUILabels } from '@ecs/ecs-common';

@Component({
  selector: 'ecs-host-detail-pane',
  templateUrl: './host-detail-pane.component.html',
  styleUrls: ['./host-detail-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostDetailPaneComponent extends Unsubscriber {

  
  @Input() host: HostResp; 

  constructor() {
    super();
  } 

  private get autoGeneratedLabels(){
    return convertLabelsToUILabels(this.host.autoGeneratedLabels)
  }

  get combinedLabels(){
    return this.host.desired_group_labels.concat(this.autoGeneratedLabels)
  }

  public isShowDesiredLabels( lable:string){     
    return !this.host.applied_group_labels.includes(lable);
  }

  get isRegistrationTypeHardwareInfo(): boolean {
    return this.host.host_registration_details.host_registration_type === HostRegDetails.HostRegistrationTypeEnum.HardwareInfo
  }
}
