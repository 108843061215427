import { Injectable } from '@angular/core';
import { GroupsService as GroupApi, GroupResp, SortOrder } from '@ecs/ecs-api';
import { OrgService } from '@ecs/ecs-platform';

import { ClrDatagridStateInterface } from '@clr/angular';
import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { Observable, ReplaySubject, finalize, map, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends AbstractPaginatableListService<GroupResp> {
  private readonly url = '/api/ecs/v0/orgs';
  private orgID: string;
  private defaultSortColumn = 'label';
  
  groupsList = new ReplaySubject<GroupResp>(1);
  modalRef: VmwNgxModalRef<OkCancel>;

  constructor( private orgService: OrgService, private groupApi: GroupApi) {
    super(); 
  }

  
  fetchData(pagination: ClrDatagridStateInterface<GroupResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      const filters: Record<string, string> = pagination.filters?.reduce((acc, filter) => ({
        ...acc,
        [filter.property]: filter.value
      }), {});
      return this.groupApi.listGroups(orgId, current, size, sortColumn as any, sortOrder, filters);
    }), map(events => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: events.page,
          size: events.per_page,
          from: events.per_page * (events.page - 1) + 1,
          to: events.per_page * events.page
        }
      });
      this.dataSource.next(events.results ?? []);
      this.totalItemsSource.next(events.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
      finalize(() => {
        this.loadingSource.next(false);
      }));
  }
 
}
