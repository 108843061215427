<div>
    {{ 'ecs.labelsEditor.description' | vtranslate}}
</div>
<ng-container *ngFor="let tagForm of formArray.controls; let i = index">
    <div class="clr-row" [formGroup]="tagForm">
        <div class="clr-col-sm-12 clr-col-md-5 clr-col-lg-5">
            <clr-input-container ecsFormControlContainer>
                <input clrInput class="input-size-md" type="text" name="tagKey" formControlName="label_key"
                    [placeholder]=" 'common.labels.key' | vtranslate ">
                <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
            </clr-input-container>
        </div>
        <div class="clr-col-sm-8 clr-col-md-5 clr-col-lg-5">
            <clr-input-container ecsFormControlContainer>
                <input clrInput class="input-size-md" type="text" name="tagValue" formControlName="label_value"
                    [placeholder]=" 'common.labels.value' | vtranslate ">
                <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
            </clr-input-container>
        </div>
        <div class="clr-col-sm-4 clr-col-md-2 clr-col-lg-2 tag-actions-icon">
            <button class="btn btn-link btn-icon" href="javascript:void(0)" (click)="removeTag(i)" [disabled]="tagForm.pristine && formArray.controls.length === 1">
                <cds-icon shape="minus-circle" size="20"></cds-icon>
            </button>
            <button *ngIf="i === 0" class="btn btn-link btn-icon" href="javascript:void(0)" (click)="addTag()" [disabled]=" tagForm | deactivateAddTag: formArray | async">
                <cds-icon shape="plus-circle" size="20" ></cds-icon>
            </button>
        </div>
    </div>
</ng-container>