import { Injectable } from '@angular/core';
import { AlertType, KeswickAlertService } from '@ecs/ecs-platform';
import { OkCancel, VmwNgxModalRef, VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { AddGitrepoModalComponent } from '../git-repo/add-gitrepo-modal/add-gitrepo-modal.component';
import { CreateGitRepoInfo, GitRepoData, UpdateGitRepoInfo } from '../git-repo/gitrepos-list/gitrepos-list.model';
import { GitReposService } from './gitrepos.service';
import { Lumos } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class AddGitrepoModalService {
  modalRef: VmwNgxModalRef<OkCancel>;
  editedValue: any = {};
  updateGitRepoData: UpdateGitRepoInfo;
  editGitRepoData: GitRepoData;
  isGitRepoEditMode = false;

  constructor(
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private gitrepoService: GitReposService,
    private l10nService: L10nService
  ) {}

  /**
   * Open modal to add git repository
   * @param [redirectLinkVisible=false] to show the redirect button in success alert
   * @param editModeData host data to open modal in edit mode
   */
  openAddGitrepoModal(redirectLinkVisible = false, editModeData = {}) {
    this.editGitRepoData = editModeData as GitRepoData;
    if (Object.keys(this.editGitRepoData).length !== 0) {
      this.isGitRepoEditMode = true;
    }

    this.modalRef = this.modalService.openOkCancel(AddGitrepoModalComponent, {
      size: VmwNgxModalSize.Large,
      title: this.isGitRepoEditMode
        ? this.l10nService.getMessage('ecs.addgitrepomodal.updategitrepository')
        : this.l10nService.getMessage('ecs.addgitrepomodal.registergitrepository'),
      buttons: {
        submitButton: {
          label: this.l10nService.getMessage('ecs.CONFIRM'),
          disabled: true
        },
        cancelButton: {
          label: this.l10nService.getMessage('ecs.CANCEL')
        }
      }
    });

    if (this.isGitRepoEditMode) {
      this.modalRef.onClose().subscribe((result: GitRepoData | false) => {
        if (result) {
          // Form request body for Update Git Repo
          this.updateGitRepoData = {
            default_branch: result.default_branch,
            description: result.description
          };
          Lumos.trackClickEvent('GitRepo', 'Git Repo Edited');
          // Call Update Git Repo API
          this.gitrepoService.updateGitRepo(this.updateGitRepoData, result.id).subscribe(() => {
            this.gitrepoService.getGitRepoList();
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10nService.getMessage('ecs.addgitrepomodal.update.success')
            });
          });
        }
      });
      this.isGitRepoEditMode = false;
    } else {
      this.modalRef.onClose().subscribe((result: CreateGitRepoInfo | false) => {
        if (result) {
          Lumos.trackClickEvent('GitRepo', 'Git Repo Added');
          this.gitrepoService.createGitRepo(result).subscribe(() => {
            this.gitrepoService.getGitRepoList();
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10nService.getMessage('ecs.addgitrepomodal.add.success'),
              actionLabel: redirectLinkVisible ? this.l10nService.getMessage('ecs.addgitrepomodal.actionlabel') : null,
              navigationLink: redirectLinkVisible ? '/#/gitrepos/gitreposlist' : null
            });
          });
        }
      });
    }
  }

  enableConfirm(): void {
    this.modalRef.buttons.submitButton.disabled = false;
  }

  disableConfirm(): void {
    this.modalRef.buttons.submitButton.disabled = true;
  }

  getEditRepoData(): GitRepoData {
    return this.editGitRepoData;
  }
}
