import { Component } from '@angular/core';
import { ClrTimelineLayout, ClrTimelineStepState } from '@clr/angular';
import { ArtifactsService, GetHostsResp, GitRepoResp, InstallerType } from '@ecs/ecs-api';
import { CONFIGURE_ADD_HOST_ROUTE, CONFIGURE_ADD_SITE_ROUTE, NavigationService, OrgService } from '@ecs/ecs-platform';
import { AddGitrepoModalService } from '../../services/add-gitrepo-modal.service';
import { GitReposService } from '../../services/gitrepos.service';
import { HostsService } from '../../services/hosts.service';
import { DOWNLOAD_IMAGE_DOC_LINK, HOST_PRE_REQ_DOC_LINK, LEARN_MORE_ABOUT_GIT_REPO, LEARN_MORE_ABOUT_SITES, Lumos } from '@ecs/ecs-common';
import { PaginatedHostsResponse } from '@ecs/ecs-platform';
import { ConfigureSitesService } from '@ecs/ecs-configure-sites';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { BehaviorSubject, map, switchMap } from 'rxjs';

// export enum KeswickImageDownloadType {
//   ISO = 'iso',
//   IMG = 'img',
//   OVA = 'ova'
// }

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent extends Unsubscriber {
  private downloadStatusSrc = new BehaviorSubject<string>('');

  public readonly ClrTimelineLayout = ClrTimelineLayout;
  public readonly ClrTimelineStepState = ClrTimelineStepState;
  public isHostAvailable = false;
  public isGitRepoAvailable = false;
  public isSiteAvailable = false;
  public readonly LEARN_MORE_ABOUT_GIT_REPO: string = LEARN_MORE_ABOUT_GIT_REPO;
  public readonly DOWNLOAD_IMAGE_DOC_LINK = DOWNLOAD_IMAGE_DOC_LINK;
  public readonly LEARN_MORE_ABOUT_SITES = LEARN_MORE_ABOUT_SITES;
  public readonly HOST_PRE_REQ_DOC_LINK = HOST_PRE_REQ_DOC_LINK;

  public downloadStatus$ = this.downloadStatusSrc.pipe(map((downloadStatus) => {
    switch (downloadStatus) {
      case 'in_progress':
        return ClrTimelineStepState.PROCESSING;
      case 'completed':
        return ClrTimelineStepState.SUCCESS;
      default:
        return ClrTimelineStepState.CURRENT;
    }
  }))

  imageTypes = [InstallerType.Iso, InstallerType.Ova, InstallerType.Img];

  constructor(
    private addGitrepoService: AddGitrepoModalService,
    private hostService: HostsService,
    private gitRepoService: GitReposService,
    private orgService: OrgService,
    private navigationService: NavigationService,
    private configureSitesService: ConfigureSitesService,
    private artifactsService: ArtifactsService
  ) {
    super();
  }

  /*
    IMPORTANT NOTE:
    On the Launchpad, to convey to the users that they have hit the milestones
    of creating a git repo and registering a host, we need to pull both these resources from the API
    If we have a non-zero count, we mark the milestone markers as green

    Additionally, there's a possibility with CSP that the user's account gets set to "default" before CSP
    gives us the user's actual organization ID

    So, we need to ensure we do not end up querying the API with the org ID set to "default", which will
    lead to the API returning an error because of the non-existent "default" org

    Caching:
    Helps us reduce API calls every time the user navigates to the Launchpad page. We now cache fields in local
    storage that mark if hosts and/or repos have already been added. If it's set to 'yes', we don't trigger
    the API calls. If it isn't set to 'yes', we make the API calls, and set it to 'yes'. We use 'yes' instead
    of 'true' because localStorage doesn't store booleans and dealing with strings pretending to be booleans
    can be confusing
  */
  ngOnInit() {
    this.downloadStatusSrc.next(localStorage.getItem('download_status'));

    this.subs = this.downloadStatusSrc.subscribe((downloadStatus) => {
      localStorage.setItem('download_status', downloadStatus);
    });

    if (this.hostService.orgID != 'default' && localStorage.getItem('hosts_added') != 'yes') {
      this.subs = this.orgService.orgId$.subscribe(orgID => {
        if (orgID != '') {
          this.hostService.getHosts();
        }
      });

      this.subs = this.hostService.allHostsList.subscribe((hosts: GetHostsResp) => {
        if (Array.isArray(hosts.results)) {
          localStorage.setItem('hosts_added', 'yes');
          this.isHostAvailable = hosts.total > 0;
        }
      });
    } else if (localStorage.getItem('hosts_added') == 'yes') {
      this.isHostAvailable = true;
    }

    if (this.hostService.orgID != 'default' && localStorage.getItem('sites_added') != 'yes') {
      this.subs = this.orgService.orgId$.subscribe(orgID => {
        if (orgID != '') {
          this.configureSitesService.getSitesCount(orgID);
        }
      });

      this.subs = this.configureSitesService.siteList.subscribe((sitesCount: number) => {
        this.isSiteAvailable = sitesCount > 0;
        if (this.isSiteAvailable) {
          localStorage.setItem('sites_added', 'yes');
        }
      });
    } else if (localStorage.getItem('sites_added') == 'yes') {
      this.isSiteAvailable = true;
    }

    if (this.gitRepoService.orgID != 'default' && localStorage.getItem('repos_added') != 'yes') {
      this.subs = this.orgService.orgId$.subscribe(orgID => {
        if (orgID != '') {
          this.gitRepoService.getGitRepoList();
        }
      });

      this.subs = this.gitRepoService.gitRepoList.subscribe((gitRepos: GitRepoResp[]) => {
        if (Array.isArray(gitRepos)) {
          localStorage.setItem('repos_added', 'yes');
          this.isGitRepoAvailable = gitRepos.length > 0;
        }
      });
    } else if (localStorage.getItem('repos_added') == 'yes') {
      this.isGitRepoAvailable = true;
    }
  }

  getAddSiteStatus(): ClrTimelineStepState {
    return this.isSiteAvailable ? ClrTimelineStepState.SUCCESS : ClrTimelineStepState.CURRENT;
  }

  getAddHostStatus(): ClrTimelineStepState {
    return this.isHostAvailable ? ClrTimelineStepState.SUCCESS : ClrTimelineStepState.CURRENT;
  }

  getAddGitRepoStatus(): ClrTimelineStepState {
    return this.isGitRepoAvailable ? ClrTimelineStepState.SUCCESS : ClrTimelineStepState.CURRENT;
  }

  downloadImage(imageType: InstallerType): void {
    Lumos.trackImageDownload(imageType);

    // Set the download status to 'in_progress' in localStorage
    this.downloadStatusSrc.next('in_progress');

    this.subs = this.orgService.orgId$.pipe(
      switchMap((orgId) => {
        return this.artifactsService.downloadInstaller(orgId, imageType);
      })
    ).subscribe((response) => {
      const url = response.url;
      const fileName = url.split('#')[0].split('?')[0].split('/').pop();
      const downloadLink = document.createElement('a');
      downloadLink.download = fileName;
      downloadLink.href = url;
      downloadLink.click();

      this.downloadStatusSrc.next('completed');
    });


  }

  openAddGitRepoModal() {
    Lumos.trackClickEvent('GitRepo', 'Add GitRepo clicked');
    this.addGitrepoService.openAddGitrepoModal(true);
  }

  openAddSiteModal() {
    this.navigationService.sendNavigationRequest(CONFIGURE_ADD_SITE_ROUTE);
  }

  openAddHostModal() {
    this.navigationService.sendNavigationRequest(CONFIGURE_ADD_HOST_ROUTE);
  }
}
