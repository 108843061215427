import { Component } from '@angular/core';

//import { CspRobot } from '@vmw/csp-ngx-components';

@Component({
  templateUrl: 'page-not-found.component.html'
})
export class PageNotFoundComponent {
  //readonly robot: CspRobot = CspRobot.Lookout;
}
