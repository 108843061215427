import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { OrgService } from '@ecs/ecs-platform';
import { ESortOrder } from '../events/events.model';
import { IAlertsResp } from '../operations/operations.model';
import { handleError } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  private readonly url = '/api/ecs/v0/orgs';
  private orgID: string;
  constructor(private http: HttpClient, private orgService: OrgService) {
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  fetchAlerts(
    page = 1,
    perPage = 10,
    filterValues = new Map<string, string>(),
    sortColumn = 'updated_at',
    sortOrder = ESortOrder.DESCENDING
  ): Observable<IAlertsResp> {
    const url = `${this.url}/${this.orgID}/alerts`;

    let params = new HttpParams().set('page', page).set('per_page', perPage).set('sort_column', sortColumn).set('sort_order', sortOrder);

    if (filterValues.size != 0) {
      filterValues.forEach((value, key) => {
        params = params.set(key, value);
      });
    }

    return this.http.get<IAlertsResp>(url, { params });
  }

  dismissAlerts(alertIds: string[]): Observable<any> {
    return this.http.post(`${this.url}/${this.orgID}/alerts/suppress`, { alert_ids: alertIds }).pipe(catchError(handleError));
  }
}
