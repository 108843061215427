<div class="back-button-container">
  <button class="btn btn-sm btn-link" (click)="backClicked()">
    <cds-icon shape="angle" direction="left" class="arrow"></cds-icon>{{ 'ecs.hostdetail.backtohostlist' | vtranslate }}
  </button>
</div>

<div class="detail-header" *ngIf="hostData$ | async as hostData">
  <h2>
    {{ 'ecs.hostdetail.host' | vtranslate }}:
    {{ hostData ? hostData?.host_summary?.name : '--' }} |
  </h2>
  <clr-dropdown class="action-button">
    <button class="dropdown-toggle btn btn-link" clrDropdownTrigger (click)="alertsClicked()">
      {{ 'ecs.hostdetail.actions' | vtranslate }}
      <cds-icon shape="angle" direction="down"></cds-icon>
    </button>
    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
      <div clrDropdownItem (click)="openAddToGroupModal()">{{ 'ecs.hostdetail.actions.addtogroup' | vtranslate }}</div>
      <div clrDropdownItem (click)="redirectToAlerts()">{{ 'ecs.alerts' | vtranslate }}</div>
      <div clrDropdownItem (click)="redirectToEvents()">{{ 'ecs.events' | vtranslate }}</div>
      <div clrDropdownItem (click)="openRemoveHostConfirmationModal()">{{ 'ecs.hostdetail.actions.remove' | vtranslate }}</div>
      <div clrDropdownItem (click)="openRebootHostConfirmationModal(hostData)">{{ 'ecs.hostdetail.actions.reboot' | vtranslate }}</div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>

<div class="summary-container" *ngIf="hostData$ | async as hostData">
  <div class="clr-row">
    <div class="clr-col-8">
      <div class="clr-row">
        <div class="clr-col-4">
          <div class="card group-card">
            <div class="card-block groups-card-block">
              <h3 class="card-title">{{ 'ecs.hostdetail' | vtranslate }}</h3>
              <div class="host-details-container">
                <div class="detail-item">
                  <div>Status</div>
                  <div *ngIf="hostData?.host_summary?.host_registration_status === HostRegistrationStatus.NotActive; else ActivatingState">
                    <cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon>
                    {{ 'ecs.hostdetail.nogitrepo' | vtranslate }}
                  </div>
                  <ng-template #ActivatingState>
                    <div *ngIf="hostData?.host_summary?.host_registration_status === HostRegistrationStatus.Activating; else ActiveState">
                      <cds-icon shape="check-circle" solid status="success"></cds-icon>
                      {{ 'ecs.enums.hostRegistrationStatus.Activating' | vtranslate }}
                    </div>
                  </ng-template>
                  <ng-template #ActiveState>
                    <div *ngIf="hostData?.host_summary?.host_registration_status === HostRegistrationStatus.Active; else NotActiveState">
                      <cds-icon shape="check-circle" solid status="success"></cds-icon>
                      {{ 'ecs.enums.hostRegistrationStatus.Active' | vtranslate }}
                    </div>
                  </ng-template>
                  <ng-template #NotActiveState>
                    <div *ngIf="hostData?.host_summary?.host_registration_status === HostRegistrationStatus.Registered; else OtherState">
                      <cds-icon shape="warning-standard"></cds-icon>
                      {{ 'ecs.enums.hostRegistrationStatus.Registered' | vtranslate }}
                    </div>
                  </ng-template>
                  <!-- Add other states when implemented from backend -->
                  <ng-template #OtherState>
                    <div class="value">{{ ('ecs.enums.hostRegistrationStatus.' + hostData?.host_summary?.host_registration_status) | vtranslate }}</div>
                  </ng-template>
                </div>
                <div class="detail-item">
                  <div>{{ 'ecs.hostdetail.ecsHostVersion' | vtranslate }}</div>
                  <div class="value-small">{{ hostData?.host_system_details?.version }}</div>
                </div>
                <div class="detail-item">
                  <div>{{ 'ecs.hostdetail.logs' | vtranslate }}</div>
                  <div (click)="downloadLogBundle()" class="download-log">
                    <span>{{ 'ecs.hostdetail.logbundle' | vtranslate }}</span>
                    <cds-icon shape="download" size="sm"></cds-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer log-card-footer">
              <button class="btn btn-sm btn-link" (click)="generateLogBundle()">
                {{ 'ecs.hostdetail.generatelogbundle' | vtranslate }}
              </button>
            </div>
          </div>
        </div>
        <div class="clr-col-8">
          <div class="card">
            <div class="card-block">
              <div class="performance-header">
                <h3 class="card-title">
                  {{ 'ecs.hostdetail.capacitydetail.title' | vtranslate }}
                  <clr-tooltip>
                    <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                    <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                      <span>{{ 'ecs.hostdetail.capacitydetail.title.tooltip' | vtranslate }}</span>
                    </clr-tooltip-content>
                  </clr-tooltip>
                </h3>
                <div class="value-small">
                  {{ 'ecs.host.lastsynced' | vtranslate }}: {{ hostData?.host_summary?.last_seen | date : 'MMMM d, h:mm a' }}
                  <cds-icon shape="refresh" class="refresh-icon" (click)="refreshHostDetails()"></cds-icon>
                </div>
              </div>
              <div *ngIf="!loading; else spinnerTemplate">
                <app-capacity-and-usage [capacityAndUsageData]="capacityAndUsageData"> </app-capacity-and-usage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-4">
          <div class="card">
            <div class="card-block">
              <h3 class="card-title">{{ 'ecs.hostdetail.kubernetesdetails.title' | vtranslate }}</h3>
              <div class="detail-item">
                <div class="detail-label">
                  {{ 'ecs.hostdetail.kubernetesdetails.control.plane.version' | vtranslate }}
                </div>
                <div class="label-value">
                  <span *ngIf="hostData?.host_worker_details?.kubernetes_version; else notAvailableTemplate">
                    {{ splitKubernetesDetail(hostData?.host_worker_details?.kubernetes_version)[1] }}
                  </span>
                </div>
              </div>
              <div class="detail-item">
                <div class="detail-label">{{ 'ecs.hostdetail.kubernetesdetails.workernode' | vtranslate }}</div>
                <div class="clr-row">
                  <div class="clr-col-4 detail-label">{{ 'ecs.hostdetail.kubernetesdetails.workernode.version' | vtranslate }}</div>
                  <div class="clr-col-8">
                    <span *ngIf="hostData?.host_worker_details?.kubernetes_version; else notAvailableTemplate">
                      {{ splitKubernetesDetail(hostData?.host_worker_details?.kubernetes_version)[3] }}
                    </span>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col-4 detail-label">{{ 'ecs.hostdetail.kubernetesdetails.workernode.ipaddress' | vtranslate }}</div>
                  <div class="clr-col-8">
                    <span *ngIf="hostData?.host_worker_details?.network_details?.ip_address; else notAvailableTemplate">
                      {{ hostData?.host_worker_details?.network_details?.ip_address }}
                    </span>
                  </div>
                </div>
              </div>
              <ng-template #noDetails>
                <div class="detail-item">
                  <div>{{ 'ecs.hostdetail.kubernetesdetails.nodetails' | vtranslate }}!</div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="clr-col-4">
          <div class="card group-card scrollBar">
            <div class="card-block groups-card-block">
              <h3 class="card-title">{{ 'ecs.host.grouplabel' | vtranslate }}</h3>
              <div class="detail-item">
                <div
                  *ngIf="
                    !hostData?.host_summary?.applied_group_labels?.length && !hostData?.host_summary?.desired_group_labels?.length;
                    else groups
                  ">
                  This host is not part of any group.
                </div>
                <ng-template #groups>
                  <div *ngFor="let groupLabel of hostData?.host_summary?.applied_group_labels">
                    <clr-tooltip style="margin-top: 8px">
                      <label class="label label-light-blue" clrTooltipTrigger>
                        {{ groupLabel | ecsLabelParser : 'key' }}
                        <span class="badge">{{ groupLabel | ecsLabelParser : 'value' }}</span>
                      </label>
                      <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                        <span class="tooltip-content">{{ 'ecs.hostdetail.grouplabels.appliedgrouptooltip' | vtranslate }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </div>
                  <div *ngFor="let groupLabel of unappliedGroups$ | async">
                    <clr-tooltip style="margin-top: 8px">
                      <label class="label" clrTooltipTrigger>
                        {{ groupLabel | ecsLabelParser : 'key' }}
                        <span class="badge">{{ groupLabel | ecsLabelParser : 'value' }}</span>
                      </label>
                      <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                        <span class="tooltip-content">{{ 'ecs.hostdetail.grouplabels.unappliedgrouptooltip' | vtranslate }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="card-footer groups-card-footer">
              <button class="btn btn-sm btn-link" (click)="openAddToGroupModal()">
                {{ 'ecs.hostdetail.actions.addtogroup' | vtranslate }}
              </button>
            </div>
          </div>
        </div>
        <div class="clr-col-4">
          <div class="card">
            <div class="card-block">
              <h3 class="card-title">{{ 'ecs.hostdetail.gitops' | vtranslate }}</h3>
              <div class="host-gitops-container">
                <div class="detail-item">
                  <div class="clr-row detail-item">
                    <div class="clr-col-4 detail-label">{{ 'ecs.host.gitopsurl' | vtranslate }}</div>
                    <div class="clr-col-8">
                      <a
                        class="gitconfig__link"
                        href="{{ hostData?.host_summary?.git_repo_url }}"
                        target="_blank"
                        *ngIf="hostData?.host_summary?.git_repo_url; else notConfiguredTemplate">
                        <div role="tooltip" aria-haspopup="true" class="tooltip tooltip-md git-url">
                          <div>{{ hostData?.host_summary?.git_repo_url }}</div>
                          <span class="tooltip-content">{{ hostData?.host_summary?.git_repo_url }}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="clr-row detail-item">
                    <div class="clr-col-4 detail-label">{{ 'ecs.host.gitRepoBranch' | vtranslate }}</div>
                    <div class="clr-col-8">
                      <span *ngIf="hostData?.host_summary?.git_repo_branch; else notConfiguredTemplate">{{
                        hostData?.host_summary?.git_repo_branch
                      }}</span>
                    </div>
                  </div>
                  <div class="clr-row detail-item">
                    <div class="clr-col-4 detail-label">{{ 'ecs.host.gitRepoPath' | vtranslate }}</div>
                    <div class="clr-col-8">
                      <span *ngIf="hostData?.host_summary?.git_repo_url; else notConfiguredTemplate">{{
                        hostData?.host_summary?.git_repo_path
                      }}</span>
                    </div>
                  </div>
                  <div class="clr-row detail-item">
                    <div class="clr-col-4 detail-label">{{ 'ecs.host.status' | vtranslate }}</div>
                    <div class="clr-col-8">
                      <span *ngIf="hostData?.git_health_status?.git_desired_state; else notAvailableTemplate">{{
                        formatGitStatus(hostData?.git_health_status?.git_desired_state)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-col-4">
      <div class="card">
        <div class="card-block">
          <h3 class="card-title">{{ 'ecs.hostdetail.hardware' | vtranslate }}</h3>
          <div *ngIf="!loading; else spinnerTemplate">
            <app-host-hardware-detail [hostData]="hostData$ | async"></app-host-hardware-detail>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notConfiguredTemplate>
  <span>{{ 'ecs.notConfigured' | vtranslate }}</span>
</ng-template>
<ng-template #notAvailableTemplate>
  <span>{{ 'ecs.notAvailable' | vtranslate }}</span>
</ng-template>
<ng-template #spinnerTemplate>
  <div class="spinner-container">
    <span class="spinner spinner-md"> {{ 'ecs.loading' | vtranslate }}... </span>
  </div>
</ng-template>
