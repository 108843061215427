import { Component, Inject, Input } from '@angular/core';
import { MODAL_DATA, VmwNgxButtonType, VmwNgxModalSize } from '@vmw/ngx-modal-service';

export enum ClrAlertSeverity {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info'
}

export interface ConfirmationModalConfig {
  title: string;
  message: string;
  additionalMessage?: string;
  bodyContent?: string;
  size?: VmwNgxModalSize;
  isWarning?: boolean;
  warningType?: ClrAlertSeverity;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonType?: VmwNgxButtonType;
  cancelButtonType?: VmwNgxButtonType;
  confirm: () => void;
  cancel: () => void;
  confirmButtonDisabled?: boolean
}

@Component({
  selector: 'ecs-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() message: string;
  @Input() additionalMessage: string;
  @Input() isWarning = false;
  @Input() warningType: ClrAlertSeverity = ClrAlertSeverity.WARNING;
  @Input() bodyContent: string;

  constructor(@Inject(MODAL_DATA) data: ConfirmationModalConfig) {
    this.message = data.message ?? this.message
    this.additionalMessage = data.additionalMessage ?? this.additionalMessage
    this.isWarning = data.isWarning ?? this.isWarning
    this.warningType = data.warningType ?? this.warningType
    this.bodyContent = data.bodyContent ?? this.bodyContent
  }
}
