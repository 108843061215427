import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

const DEFAULT_DATETIME_FORMAT = 'MMM d, YYYY hh:mm a';

@Pipe({
  name: 'ecsDateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private localId: string) {}

  transform(value: string | Date | number | null, format: string = DEFAULT_DATETIME_FORMAT): string {
    if (moment(value).isValid()) {
      const datePipe = new DatePipe(this.localId ?? 'en-US');

      return datePipe.transform(value, format);
    }
    return 'N/A';
  }

}
