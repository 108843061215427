<div class="limit-height">
<clr-datagrid [clrDgLoading]="loading$ | async">
    <clr-dg-column [clrDgField]="'name'">{{ 'ecs.host.name' | vtranslate }}</clr-dg-column>
    <clr-dg-column>{{ 'ecs.sites.siteDetails.labels.ip' | vtranslate }}</clr-dg-column>
    <clr-dg-column [clrDgField]="'host_health_status'">{{ 'ecs.sites.siteDetails.labels.status' | vtranslate }}
        <clr-dg-filter>
          <div *ngFor="let status of statusList">
          <clr-checkbox-wrapper>
              <input
                type="checkbox"
                clrCheckbox
                [value]="status"
                [ngModel]="checkboxStates[status]"
                (ngModelChange)="handleStatusCheckboxChange(status, $event)" />
                <label *ngIf="status === HostHealthStatus.Unknown">
                  <cds-icon shape="help" solid></cds-icon> {{ 'ecs.host.health.unknown' | vtranslate }}
                </label>
                <label *ngIf="status === HostHealthStatus.Warning">
                  <cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon> {{ 'ecs.host.health.warning' | vtranslate }}
                </label>
                <label *ngIf="status === HostHealthStatus.Ok">
                  <cds-icon shape="check-circle" solid status="success"></cds-icon> {{ 'ecs.host.health.ok' | vtranslate }}
                </label>
            </clr-checkbox-wrapper>
          </div>
        </clr-dg-filter>
  </clr-dg-column>

    <clr-dg-row *clrDgItems="let host of (data$ | async)">
        <clr-dg-cell>{{ host.name }}</clr-dg-cell>
        <clr-dg-cell>{{ host.host_network_details?.ip_address }}</clr-dg-cell>
        <clr-dg-cell>{{ host.host_health }}</clr-dg-cell>
    </clr-dg-row>

    <!-- Empty Placeholder -->
    <clr-dg-placeholder>
        <div class="empty-placeholder">
            <p class="custom-para">{{ 'ecs.sites.siteDetails.noAssociatedHosts' | vtranslate }}</p>
        </div>
    </clr-dg-placeholder>

    <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
        <div class="footer-actions align-left">
            <a class="refresh-btn btn btn-link" (click)="onRefresh()">
                <cds-icon shape="refresh" size="14"></cds-icon>
                <span>{{'ecs.refresh' | vtranslate}}</span>
            </a>
        </div>
        <clr-dg-pagination
          #pagination
          [clrDgPageSize]="paginationInfo?.page?.size"
          [clrDgPage]="paginationInfo?.page?.current"
          [clrDgTotalItems]="totalItems$ | async">
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"> {{'ecs.sites.datagrid.pagination.pageSize' | vtranslate}} </clr-dg-page-size>
          {{'ecs.sites.datagrid.pagination.countDetails' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1: (totalItems$ | async)}}
            </clr-dg-pagination>
            <div></div>
      </clr-dg-footer>
</clr-datagrid>
</div>