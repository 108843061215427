<div>
    <p>{{'ecs.reboothostmodal.title' | vtranslate}}:
      <span><strong>{{ hostSerialNumber }}</strong></span>
    </p>
    <p>
      <span>
        {{'ecs.reboothostmodal.subtitle' | vtranslate}}
      </span>
    </p>
  </div>
  <p>{{'ecs.confirmtoproceed' | vtranslate}}</p>
  