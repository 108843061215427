import { Inject, Injectable } from '@angular/core';
//import { CspApiService } from '@vmw/csp-ngx-components';
import { APP_ENVIRONMENT, CceUiEnvironment } from '../environment.model';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private onboardingContextID = 'd41b0ac1-9a27-421a-98b2-2356b8c2aa8a';

  constructor(@Inject(APP_ENVIRONMENT) private environment: CceUiEnvironment) {
    if (this.environment.cspEnvironment === 'prd') {
      this.onboardingContextID = '8e3cbfb8-409b-4d35-9c7e-5360b644daeb';
    }
  }

  redirectToOnboarding() {
    // this.cspApiService.redirectToSelfOnboarding(this.onboardingContextID);
  }
}
