import { Injectable } from '@angular/core';
import { VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { GroupsRemoveComponent } from '../components/group/groups-remove/groups-remove.component';
import { GroupsService } from './groups.service';
import { AlertType, GroupRespData, KeswickAlertService } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoveGroupModalService {
  private successfulGroupDeletions = 0;
  private attemptedGroupDeletions = 0;
  constructor(
    private groupsService: GroupsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private l10service: L10nService
  ) {}

  openRemoveGroupModal(selectedGroups: Array<GroupRespData>): Observable<void> {
    const modalRef = this.modalService.openOkCancel(GroupsRemoveComponent, {
      size: VmwNgxModalSize.Medium,
      title: this.l10service.getMessage('ecs.removegroup'),
      data: {
        selectedGroups: selectedGroups
      },
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.yes'),
          handler: (ref) => {
            // the modal is closed with 'accept'
            ref.close('accept');
          }
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.no'),
          handler: (ref) => {
            // the modal is closed with 'deny'
            ref.close('deny');
          }
        }
      }
    });

    return modalRef.onClose().pipe(switchMap((result) => {    

      if (result === 'accept') {
        this.successfulGroupDeletions = 0;
        this.attemptedGroupDeletions = 0;

          const requests = selectedGroups.map(group => {
            return this.groupsService.deleteGroup(group?.id);
          });

          return forkJoin(requests).pipe(map((responses) => {
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10service.getMessage('ecs.removegroup.success')
            });
          }), catchError(() => {
            this.alertService.showMessage({
              type: AlertType.failure,
              message: this.l10service.getMessage('ecs.removegroup.failure')
            });
            return of();
          }));
      }else{
        return of();
      }
      }));
  }
}
