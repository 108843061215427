import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { CdsModule } from '@cds/angular';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { SidenavSectionComponent } from './navigation/sidenav/section/section.component';
import { VIPModule } from '@vmw/ngx-vip';
import { EcsCommonModule } from '@ecs/ecs-common';

@NgModule({
  imports: [
    CommonModule, 
    ClarityModule, 
    CdsModule,
    VIPModule,
    EcsCommonModule,
  ],
  exports: [
    SidenavComponent,
    SidenavSectionComponent
  ],
  declarations: [
    SidenavComponent,
    SidenavSectionComponent
  ]
})
export class EcsPlatformModule {}
