export const MODAL_ACCEPT = 'accept';
export const MODAL_DENY = 'deny';

// External Doc Links
export const KESWICK_DEMO_VIDEO_URL = 'https://www.youtube.com/watch?v=wpdaWlyNp7k';
export const BASE_URL = 'https://docs.vmware.com/en/VMware-Edge-Compute-Stack/3.5/vmware-edge-compute-stack-administration-guide';
export const LEARN_MORE_ABOUT_KESWICK = `${BASE_URL}/GUID-3DBACE14-E544-4407-9985-F67D4BE3A6EE.html`;
export const LEARN_MORE_ABOUT_GIT_REPO = `${BASE_URL}/GUID-E80FD3E8-6047-4658-9799-84738201C3AC.html`;
export const LEARN_MORE_ABOUT_SITES = `${BASE_URL}/GUID-6A38508E-4372-4B29-AFEB-7E229E3DF1FB.html`;
export const DOWNLOAD_IMAGE_DOC_LINK = `${BASE_URL}/GUID-A8BD634B-7F95-4815-AC3F-34E61AA6A652.html`;
export const HOST_PRE_REQ_DOC_LINK = `${BASE_URL}/GUID-EF39DF5D-DAA5-427C-AEEE-8B6EC5D062F3.html`;
export const DEPLOY_HOST_DOC_LINK = `${BASE_URL}/GUID-58B35A0B-93F5-477F-B7F4-9A9479054ECC.html`;
export const FULL_DEPLOYMENT_VIDEO_LINK = 'https://www.youtube.com/watch?v=wpdaWlyNp7k';
export const INTRODUCE_KESWICK_ARTICLE_LINK =
  'https://octo.vmware.com/edge-management-at-scale-announcing-free-availability-of-vmware-project-keswick/';
export const SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK = 'https://octo.vmware.com/project-keswick/';
export const DEPLOYING_HOME_LAB_WITH_KESWICK_LINK = 'https://www.youtube.com/watch?v=FtjfsE9QVHU';
export const HOME_DOC_LINK = `${BASE_URL}/GUID-0B13D9E5-26E4-4CFE-A933-B38846845DEC.html`;
export const TROUBLESHOOTING_HOST = `${BASE_URL}/GUID-0C4CE9D3-13BB-4B50-A59A-47D1A3F7B425.html`;
export const HOST_GROUP_DOC_LINK = `${BASE_URL}/GUID-E0195568-BA01-4479-9C5D-64924902B1A2.html`;
export const ADD_HOST_TO_GROUP_DOC_LINK = `${BASE_URL}/GUID-8B4F5BFA-14EF-4350-9CF4-99CBEE94885D.html`;
export const REGISTER_HOST_DOC_LINK = `${BASE_URL}/GUID-D4DECF6E-D312-4BB9-A6B1-357242BE64F2.html`;
export const REGISTER_GIT_REPO_DOC_LINK = `${BASE_URL}/GUID-48861456-F96E-4ADD-AA1F-AA6A7DC32372.html`;
export const ADD_GROUP_DOC_LINK = `${BASE_URL}/GUID-187071C3-3897-420B-974E-CAA05BD3F4EB.html`;
export const LEARN_MORE_ABOUT_HOSTS_ACTIVATION_STATUS = `${BASE_URL}/GUID-C2011B60-2C9C-420C-A923-EDF687D7BE96.html`;

// Global Patterns
export const GlobalConst = {
    patterns: {
      nameRegex: '^[a-zA-Z0-9](?:[a-zA-Z0-9_. ]*[a-zA-Z0-9])?$', // Compliant with K8s label value
      labelKey: /^(?:(?:[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}\/)?[a-zA-Z0-9](?:[a-zA-Z0-9-_.]*[a-zA-Z0-9])?$/g, // Optional Prefix followed by key. For example: example.com/my-label
      labelValue: '^[a-zA-Z0-9](?:[a-zA-Z0-9-_.]*[a-zA-Z0-9])?$'
    }
}