import { Injectable } from '@angular/core';
import { VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { AlertType, KeswickAlertService } from '@ecs/ecs-platform';
import { AddToGroupModalComponent } from '../components/add-to-group-modal/add-to-group-modal.component';
import { HostsService } from './hosts.service';
import { AddToGroupInfo, IHostData } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { Lumos } from '@ecs/ecs-common';
import { CreateGroup } from '@ecs/ecs-api';

@Injectable({
  providedIn: 'root'
})
export class AddHostToGroupModalService {
  constructor(
    private hostService: HostsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private l10nService: L10nService
  ) { }

  openAddHostToGroupModal(selectedHosts: IHostData[], openedFromDetailPage = false) {
    this.hostService.setSelectedHosts(selectedHosts);
    this.hostService.addToGroupModalRef = this.modalService.openOkCancel(AddToGroupModalComponent, {
      size: VmwNgxModalSize.Large,
      title: this.l10nService.getMessage('ecs.groups.title'),
      buttons: {
        submitButton: {
          label: this.l10nService.getMessage('ecs.addhosttogroupmodal.Ok'),
          disabled: true // Initially disabled
        },
        cancelButton: {
          label: this.l10nService.getMessage('ecs.cancel')
        }
      }
    });

    this.hostService.addToGroupModalRef.onClose().subscribe((result: AddToGroupInfo | false) => {
      if (result) {
        Lumos.trackClickEvent('Active Hosts', 'Groups - Group Created');
        // Create new group if new key and value have been specified
        if (result.new_group_info.label_key !== '' && result.new_group_info.label_value !== '') {
          this.hostService.createHostsGroup({ ...result.new_group_info, entity_type: CreateGroup.EntityTypeEnum.Host }).subscribe(() => {
            openedFromDetailPage
              ? this.hostService.fetchHostDetails(selectedHosts[0]?.unique_identifier)
              : this.hostService.getHosts();
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10nService.getMessage('ecs.addhosttogroupmodal.success')
            });
        })} else {
          // Update existing group with the newly specified members
          this.hostService.updateHostsGroup(result.update_group_info).subscribe(() => {
            openedFromDetailPage
              ? this.hostService.fetchHostDetails(selectedHosts[0]?.unique_identifier)
              : this.hostService.getHosts();
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10nService.getMessage('ecs.updatehosttogroupmodal.success')
            });
          });
        }
      } else {
        Lumos.trackClickEvent('Active Hosts', 'Groups - Close/Cancel Host Groups Modal');
      }
    });
  }
}
