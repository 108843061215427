import { Injectable } from '@angular/core';
import { OkCancel, VmwNgxModalRef, VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { EditHostModalComponent, EditHostModalData } from '../components/edit-host-modal/edit-host-modal.component';
import { ActivateHostService } from './activate-host.service';
import { GroupsService } from './groups.service';
import { HostsService } from './hosts.service';
import { AlertType, EditHostData, KeswickAlertService } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { Label } from '@ecs/ecs-api';

@Injectable({
  providedIn: 'root'
})
export class EditHostService {
  private modalRef: VmwNgxModalRef<OkCancel>;
  private updateCallsMade = 0;
  constructor(
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private groupsService: GroupsService,
    private hostsService: HostsService,
    private activateHostService: ActivateHostService,
    private l10nService: L10nService
  ) {}

  openEditHostModal(
    hostSerialNumber: string,
    hostIdentifier: string,
    hostGitUrl: string,
    hostGitPath: string,
    hostGitBranch: string,
    groupLabels: Label[],
    fromDetailPage = false
  ) {
    this.hostsService.fetchHostDetails(hostIdentifier);
    this.modalRef = this.modalService.openOkCancel(EditHostModalComponent, {
      data: {
        hostIdentifier: hostIdentifier,
        groupLabels: groupLabels,
        selectedHostGitUrl: hostGitUrl,
        selectedHostGitPath: hostGitPath,
        selectedHostGitBranch: hostGitBranch
      } as EditHostModalData,
      size: VmwNgxModalSize.Large,
      title: this.l10nService.getMessage('ecs.edithost', [hostSerialNumber]),
      buttons: {
        submitButton: {
          label: this.l10nService.getMessage('ecs.OK'),
          disabled: true // Disabled initially
        },
        cancelButton: {
          label: this.l10nService.getMessage('ecs.cancel')
        }
      }
    });

    this.modalRef.onClose().subscribe((result: EditHostData) => {
      if (result) {
        this.updateCallsMade = 0;

        // Call update group for every group to which the host needs to be added/removed
        result?.groupData?.forEach((groupData) => {
          this.groupsService.updateGroup(groupData).subscribe(() => {
            this.updateCallsMade += 1;
            if (this.updateCallsMade == result?.groupData?.length) {
              fromDetailPage
                ? this.hostsService.fetchHostDetails(hostIdentifier)
                : this.hostsService.getHosts();
              this.alertService.showMessage({
                type: AlertType.success,
                message: this.l10nService.getMessage('ecs.edithost.groupupdationsuccess')
              });
            }
          });
        });

        // Change Git Association for the Host by re-activating with the specified
        // Git Details
        if (result?.gitRepoData) {
          this.activateHostService.associateHostToGit(result?.gitRepoData, result?.hostIdentifier).subscribe(() => {
            fromDetailPage ? this.hostsService.fetchHostDetails(hostIdentifier) : this.hostsService.getHosts();
            this.alertService.showMessage({
              type: AlertType.success,
              message: this.l10nService.getMessage('ecs.edithost.reactivate')
            });
          });
        }
      }
    });
  }
}
