import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export function requiredWhen<T>(otherControlName: string, requiredOtherControlValue: T) {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!(control.parent && control.parent.get(otherControlName)))
        return null;

      const otherControl = control.parent.get(otherControlName);
      if (otherControl && otherControl.value === requiredOtherControlValue && otherControl.dirty) {
        return Validators.required(control);
      }
      return null;
    }
  }