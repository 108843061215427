<div class="gauge-container">
  <div *ngFor="let gaugeData of capacityAndUsageData.data">
    <vmw-gauge
      [value]="gaugeData?.usage"
      [capacity]="gaugeData?.capacity"
      [threshold]="gaugeData?.threshold"
      [unit]="gaugeData?.unit"
      [size]="'medium'">
      <h5 class="gauge-title">
        {{ gaugeData.label }}
        <clr-tooltip *ngIf="gaugeData.showWarning">
          <cds-icon shape="warning-standard" status="warning" clrTooltipTrigger></cds-icon>
          <clr-tooltip-content clrPosition="top-right" clrSize="md" *clrIfOpen>
            <span class="tooltip-content">{{ gaugeData.warningText }}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </h5>
    </vmw-gauge>
  </div>
</div>
