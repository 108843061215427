import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
   HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor
} from '@angular/common/http';

@Injectable()
export class RestXsrfInterceptor implements HttpInterceptor {
   constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const headerName = 'x-vco-csrf';
      const token = this.tokenExtractor.getToken() as string;

      if (token) {
         return next.handle(req.clone({ headers: req.headers.set(headerName, token) }));
      }

      return next.handle(req);
   }
}
