<div class="max-height-host-detail">
  <section class="general-details limit-height">
    <table class="table table-noborder">
      <tbody>
        <tr>
          <td class="left field-label">{{ 'ecs.host.name' | vtranslate }}</td>
          <td class="left">{{ host?.name || '-' }}</td>
        </tr>
        <ng-container *ngIf="isRegistrationTypeHardwareInfo;else activationKeyTemplate">
          <tr>
            <td class="left field-label">{{ 'ecs.host.serial.number' | vtranslate }}</td>
            <td class="left">{{ host?.host_registration_details?.host_serial_number || '-' }}</td>
          </tr>

          <tr>
            <td class="left field-label">{{ 'ecs.host.hostDetail.vendor' | vtranslate }} </td>
            <td class="left">{{ host?.host_registration_details?.host_vendor_identifier || '-' }}</td>
          </tr>
          <tr>
            <td class="left field-label">{{ 'ecs.host.hostDetail.model' | vtranslate }}</td>
            <td class="left">{{ host.host_registration_details.host_model_identifier || '-' }}</td>
          </tr>
        </ng-container>
        <tr>
          <td class="left field-label">{{ 'ecs.host.siteDetails.associatedSites' | vtranslate }}</td>
          <td class="left">{{ host.site_name }}</td>
        </tr>
        <tr>
          <h4>{{ 'ecs.host.hostDetail.associatedGetrepo' | vtranslate }}</h4>
        </tr>
        <tr>
          <td class="left field-label">{{ 'ecs.host.hostDetail.getconfigSource' | vtranslate }} </td>
          <td class="left">{{ host.git_config_source | titlecase }}</td>
        </tr>
        <tr>
          <td class="left field-label">{{ 'ecs.host.hostDetail.getrepo' | vtranslate }} </td>
          <td class="left"><ng-container *ngIf="host.git_repo_url; else notConfigured">{{ host.git_repo_url
              }}</ng-container></td>
        </tr>
        <tr>
          <td class="left field-label">{{ 'ecs.host.hostDetail.branch' | vtranslate }}</td>
          <td class="left">{{ host.git_repo_branch || '-' }}</td>
        </tr>
        <tr>
          <td class="left field-label">{{ 'ecs.host.hostDetail.path' | vtranslate }}</td>
          <td class="left">{{ host.git_repo_path || '-' }}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section>
    <h4>{{ 'ecs.host.details.label' | vtranslate }}</h4>
    <table class="table table-noborder">
      <tbody>
        <tr>
          <td class="left field-label">
            <div class="detail-item">
              <div *ngIf="
            !host?.applied_group_labels?.length && !combinedLabels?.length;">
                {{ 'ecs.hostdetail.grouplabels.notassociatedwithgroup' | vtranslate }}
              </div>
              <div *ngIf="
          host?.applied_group_labels?.length || combinedLabels?.length">
                <div class="inline-block" *ngFor="let groupLabel of host?.applied_group_labels">
                  <clr-tooltip>
                    <label class="label label-light-blue" clrTooltipTrigger>
                      {{groupLabel | ecsLabelParser :'key'}}
                      <span class="badge"> {{groupLabel | ecsLabelParser :'value'}}</span>
                    </label>
                    <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                      <span class="tooltip-content">{{ 'ecs.hostdetail.grouplabels.appliedgrouptooltip' | vtranslate
                        }}</span>
                    </clr-tooltip-content>
                  </clr-tooltip>
                </div>
                <ng-container *ngFor="let groupLabel of combinedLabels">
                  <div class="inline-block" *ngIf="isShowDesiredLabels(groupLabel)">
                    <clr-tooltip>
                      <label class="label" clrTooltipTrigger>
                        {{groupLabel | ecsLabelParser :'key'}}
                        <span class="badge"> {{groupLabel | ecsLabelParser :'value'}}</span>
                      </label>
                      <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                        <span class="tooltip-content">{{ 'ecs.hostdetail.grouplabels.unappliedgrouptooltip' | vtranslate
                          }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </div>
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
<ng-template #notConfigured>
  <em>{{ 'ecs.notConfigured' | vtranslate }}</em>
</ng-template>

<ng-template #activationKeyTemplate>
  <tr>
    <td class="left field-label">{{ 'ecs.hardware.activationkey' | vtranslate }}</td>
    <td class="left">{{ host.host_registration_details?.host_activation_key || '-' }}</td>
  </tr>
</ng-template>