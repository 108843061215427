<div class="clr-row">
  <div class="clr-col-4">
    <div class="card">
      <div class="card-block">
        <h5>{{'ecs.launchpad.footer.documents' | vtranslate}}</h5>
        <div class="clr-row">
          <a [href]="LEARN_MORE_ABOUT_KESWICK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.documents.quickstart' | vtranslate}} </a>
          <a [href]="DEPLOY_HOST_DOC_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.documents.deployedge' | vtranslate}} </a>
          <a href="#" class="link" (click)="downloadOSL()"> {{'ecs.launchpad.footer.documents.opensourcelicense' | vtranslate}} </a>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-4">
    <div class="card">
      <div class="card-block">
        <h5>{{'ecs.launchpad.footer.videotutorials' | vtranslate}}</h5>
        <div class="clr-row">
          <a [href]="FULL_DEPLOYMENT_VIDEO_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.videotutorials.fulldeployment' | vtranslate}} </a>
          <a [href]="DEPLOY_HOST_DOC_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.videotutorials.deployingyourhost' | vtranslate}} </a>
          <a [href]="FULL_DEPLOYMENT_VIDEO_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.videotutorials.configuringhost' | vtranslate}} </a>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-4">
    <div class="card">
      <div class="card-block">
        <h5>{{'ecs.launchpad.footer.articles'| vtranslate}}</h5>
        <div class="clr-row">
          <a [href]="INTRODUCE_KESWICK_ARTICLE_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.articles.introduceedgecompute'| vtranslate}} </a>
          <a [href]="SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK" class="link" target="_blank" rel="noopener noreferrer"> {{'ecs.launchpad.footer.articles.simplyedgedeployments'| vtranslate}} </a>
          <a [href]="DEPLOYING_HOME_LAB_WITH_KESWICK_LINK" class="link" target="_blank" rel="noopener noreferrer">
            {{'ecs.launchpad.footer.articles.deployyourhome' | vtranslate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
