<div class="breadcrumb-container" *ngIf="breadcrumbs?.length">
    <span class="breadcrumb-item" *ngFor="let item of breadcrumbs">
        <a *ngIf="item.url" class="breadcrumb-item-label" [routerLink]="item.url">{{ item.label }}</a>
        <span *ngIf="!item.url" class="breadcrumb-item-label">{{ item.label }}</span>
    </span>
</div>
<div class="page-title">
    <h2>{{ pageTitle }}</h2>
    <ng-content></ng-content>
</div>
