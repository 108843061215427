<div *ngIf="alert" [ngClass]="['alert', alert.type]" role="alert" class="alert-container">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon *ngIf="alert.type === alertType.success" class="alert-icon" shape="check-circle"></cds-icon>
        <cds-icon *ngIf="alert.type === alertType.failure" class="alert-icon" shape="info-circle"></cds-icon>
      </div>
      <span class="alert-text">
        {{ alert.message }}
      </span>
      <div *ngIf="alert.actionLabel" class="alert-actions">
        <a href="{{ alert.navigationLink }}" class="alert-action">{{ alert.actionLabel }}</a>
      </div>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>
