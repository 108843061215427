export const ENG_ECS_CONFIGURE_SITES_TRANSLATIONS = {
    'ecs.sites.configure.label': 'Sites',
    'ecs.sites.datagrid.column.name': 'Name',
    'ecs.sites.datagrid.column.description': 'Description',
    'ecs.sites.datagrid.column.hostsCount': 'No. of Hosts',
    'ecs.sites.datagrid.column.labels': 'Labels',
    'ecs.sites.datagrid.column.gitDetails': 'Git Details',
    'ecs.sites.datagrid.column.updatedAt': 'Last Modified On',
    'ecs.sites.datagrid.column.createdOn': 'Created On',
    'ecs.sites.datagrid.actions.addSite': 'Add Site',
    'ecs.sites.datagrid.actions.editSite': 'Edit',
    'ecs.sites.datagrid.noSiteMessageTitle': 'Currently you have no sites configured.',
    'ecs.sites.datagrid.noSiteMessageSubtitle': 'Learn more on how to add site and manage them in Edge Compute',
    'ecs.sites.datagrid.pagination.pageSize': 'Sites per page',
    'ecs.sites.datagrid.pagination.countDetails': '{0} - {1} of {2} sites',
    'ecs.sites.datagrid.actions.deleteSite': 'Delete',
    'ecs.removesitemodal.title': 'Delete Sites',
    'ecs.removesitemodal.additionalMessage': 'Are you sure you want to Delete the selected Sites ?',
    'ecs.removesitemodal.warningtext': 'Irreversible Operation',
    'ecs.removesitemodal.bodycontent': 'These are "{0}" selected sites for deletion. All the labels asscoiated with this site will also be deleted. The action can impact the DesiredState configuration',
    'ecs.removesitemodal.error.sitesattachedtohosts': 'The Selected Sites have Hosts attached to it. Delete the Hosts from the Site or Unselect Site to continue',
    'ecs.removesitemodal.error.defaultsite': 'The Selected Sites has a default site which cannot be deleted. Unselect it to continue',
    'ecs.removesitemodal.success': 'The selected Sites have been deleted',
    'ecs.removesitemodal.error': '"{0}" sites deleted. However, "{1}" hosts failed to delete.',
    'ecs.sites.configure.addNewSite': 'Add a new site',
    'ecs.sites.configure.editSite': 'Edit site',
    'ecs.sites.manageSite.step.siteDetails.title': 'Site Details',
    'ecs.sites.manageSite.step.siteDetails.description': 'Name/Description',
    'ecs.sites.manageSite.step.siteDetails.status.empty': 'Empty',
    'ecs.sites.manageSite.step.gitDetails.title': 'Associate Git Repository',
    'ecs.sites.manageSite.step.gitDetails.description': 'Optional',
    'ecs.sites.manageSite.step.gitDetails.status.associated': 'Associated',
    'ecs.sites.manageSite.step.gitDetails.status.notAssociated': 'Not Associated',
    'ecs.sites.manageSite.step.labels.title': 'Labels',
    'ecs.sites.manageSite.step.labels.description': 'Optional',
    'ecs.sites.manageSite.form.name.label': 'Name',
    'ecs.sites.manageSite.form.name.tooltip': 'Specify a name for the site',
    'ecs.sites.manageSite.form.name.helpText': 'Required. Max {0} characters',
    'ecs.sites.manageSite.form.description.label': 'Description',
    'ecs.sites.manageSite.form.location.label': 'Location',
    'ecs.sites.manageSite.form.location.helpText': 'Optional. Max {0} characters',
    'ecs.sites.manageSite.form.gitRepo.label': 'Git Repository',
    'ecs.sites.manageSite.form.gitRepo.helpText': 'Select a Git repository to be associated with this site',
    'ecs.sites.manageSite.form.gitRepoBranch.label': 'Branch',
    'ecs.sites.manageSite.form.gitRepoBranch.helpText': 'For example: main',
    'ecs.sites.manageSite.form.gitRepoPath.label': 'Path',
    'ecs.sites.manageSite.form.gitRepoPath.helpText': 'For example: /path/in/repo',
    'ecs.sites.manageSite.form.gitRepoUsername.label': 'Username',
    'ecs.sites.manageSite.form.gitRepoUsername.helpText': 'For example: myusername',
    'ecs.sites.manageSite.form.gitRepoAccessToken.label': 'Access Token',
    'ecs.sites.manageSite.form.gitRepoAccessToken.helpText': 'Provide a valid access token to access the Git repository',
    'ecs.sites.manageSite.buttons.addNew': 'Add Site',
    'ecs.sites.manageSite.buttons.addAnotherSite': 'Add another site',
    'ecs.sites.manageSite.addNew.success': 'Site {0} added successfully',
    'ecs.sites.manageSite.addNew.failure': 'Failed to add site {0}',
    'ecs.sites.siteDetails.labels.description': 'Description',
    'ecs.sites.siteDetails.labels.gitRepository': 'Git Repository',
    'ecs.sites.siteDetails.labels.branch': 'Branch',
    'ecs.sites.siteDetails.labels.path': 'Path',
    'ecs.sites.siteDetails.labels.ip': 'IP Address',
    'ecs.sites.siteDetails.labels.status': 'Status',
    'ecs.sites.siteDetails.labels.associatedHosts': 'Associated Hosts',
    'ecs.sites.siteDetails.labels.title': 'Labels',
    'ecs.sites.siteDetails.labels.tags': 'Tags',
    'ecs.sites.siteDetails.labels.autogenerated.title': 'Autogenerated',
    'ecs.sites.siteDetails.noAssociatedHosts': 'No associated hosts found.',
    'ecs.sites.newSite':'New Site',
    'ecs.sites.editSite':'Save',
    'ecs.site.banner': 'Sites serve as a centralized mechanism for managing multiple hosts within a unified edge location, '+
    'facilitating the use of common configuration files and enabling streamlined visualization and troubleshooting across the edge location.',
    'ecs.site.banner.gettingstarted': 'Getting Started with Sites',
    'ecs.site.banner.addsite': 'ADD A SITE',
    'ecs.site.banner.learnmore': 'LEARN MORE',
    'ecs.site.banner.subdescription': 'To use this feature, first add a Site. All Sites will be listed here, Git repositories can be assigned '+ 
    'to your sites to enable consistent configuration of edge locations. Auto-generated labels will be created and enabled on the hosts within a site, these labels can be used to make intelligent configuration and deployment decisions.',
    'ecs.sites.manageSite.form.updateAccessToken.label': 'Update Access Token',
    'ecs.addhost.duplicatesitedetails': 'There is already a Site present with the same name'
};