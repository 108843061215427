<clr-datagrid #gitReposDatagrid [(clrDgSelected)]="selectedGitRepos" [clrDgRowSelection]="true" [clrDgLoading]="loading">
  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary btn-link" (click)="openAddGitRepoModal()">
        <cds-icon shape="plus" class="refresh-icon"></cds-icon> {{ 'ecs.add' | vtranslate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary btn-link"
        [disabled]="selectedGitRepos?.length !== 1"
        (click)="openEditGitRepoModal()">
        <cds-icon shape="pencil" class="refresh-icon"></cds-icon> {{ 'ecs.edit' | vtranslate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary btn-link"
        [disabled]="selectedGitRepos?.length === 0"
        (click)="openRemoveGitRepoModal()">
        <cds-icon shape="trash" class="refresh-icon"></cds-icon> {{ 'ecs.delete' | vtranslate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary btn-link" (click)="refreshGitReposDatagrid()">
        <cds-icon shape="refresh" class="refresh-icon"></cds-icon> {{ 'ecs.refresh' | vtranslate }}
      </button>
    </div>
  </clr-dg-action-bar>

  <clr-dg-column class="hidden" [clrDgField]="'id'">{{ 'ecs.gitrepolist.column.repositoryid' | vtranslate }}</clr-dg-column>
  <clr-dg-column [clrDgField]="'name'">{{ 'ecs.gitrepolist.column.repositoryname' | vtranslate }}</clr-dg-column>
  <clr-dg-column [clrDgField]="'url'">{{ 'ecs.host.giturl' | vtranslate }}</clr-dg-column>
  <clr-dg-column [clrDgField]="'default_branch'">{{ 'ecs.addgit.branch' | vtranslate }}</clr-dg-column>
  <clr-dg-column [clrDgField]="'host_count'">{{ 'ecs.gitrepolist.column.hosts' | vtranslate }}</clr-dg-column>
  <clr-dg-column [clrDgField]="'description'">{{ 'ecs.addgit.description' | vtranslate }}</clr-dg-column>

  <clr-dg-row *clrDgItems="let repo of gitrepos; let i = index" [clrDgItem]="repo">
    <clr-dg-cell class="hidden">{{ repo.id }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.name }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.url }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.default_branch }}</clr-dg-cell>
    <clr-dg-cell>
      <span *ngIf="!repo.host_count; else hostListLink">{{ repo.host_count }}</span>
      <ng-template #hostListLink>
        <a class="link" (click)="redirectToHostsPage(repo?.url)">{{ repo.host_count }}</a>
      </ng-template>
    </clr-dg-cell>
    <clr-dg-cell>{{ repo.description || '-' }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-placeholder>
    <div class="empty-placeholder">
      <p class="custom-para">{{ 'ecs.gitrepo.noRepoMessageTitle' | vtranslate }}</p>
      <a class="link" [href]="REGISTER_GIT_REPO_DOC_LINK" target="_blank">{{ 'ecs.gitrepo.noRepoMessageSubtitle' | vtranslate }}</a>
      <button type="button" class="btn btn-primary" (click)="openAddGitRepoModal()">
        {{ 'ecs.gitrepo.addgitrepository' | vtranslate }}
      </button>
    </div>
  </clr-dg-placeholder>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'ecs.gitrepolist.footer' | vtranslate }}</clr-dg-page-size>
      {{ 'ecs.gitrepolist.pagination' | vtranslate : pagination.firstItem + 1 : pagination.lastItem + 1 : pagination.totalItems }}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<ng-template #tmpl>
  <div *ngIf="!isSelectedRepoAssociatedWithHost()">
    <p>{{ 'ecs.gitrepolist.remove.confirmation' | vtranslate }}:</p>
    <ul>
      <li *ngFor="let repo of selectedGitRepos">
        <strong>{{ repo?.url }}</strong>
      </li>
      <br />
    </ul>
  </div>
  <div>{{ 'ecs.confirmtoproceed' | vtranslate }}</div>
  <div *ngIf="isSelectedRepoAssociatedWithHost()" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{ 'ecs.gitrepolist.remove.alert' | vtranslate }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
