import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GitReposService } from '../../services/gitrepos.service';
import { OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { AddGitrepoModalService } from '../../services/add-gitrepo-modal.service';
import { GitRepoData } from '../gitrepos-list/gitrepos-list.model';
import { Lumos } from '@ecs/ecs-common';

@Component({
  selector: 'app-add-gitrepo-modal',
  templateUrl: './add-gitrepo-modal.component.html',
  styleUrls: ['./add-gitrepo-modal.component.scss']
})
export class AddGitrepoModalComponent implements OnInit {
  public addGitRepoForm: FormGroup;
  gitRepoBranch: string;
  gitRepoURL: string;
  gitRepoDescription: string;
  selectedGitRepoID: number;
  isGitRepoEditMode = false;
  gitRepoData: GitRepoData;
  @Input() addGitRepoModal = false;
  private gitrepoInfo = { url: '', description: '' };

  constructor(
    private modalRef: VmwNgxModalRef<OkCancel>,
    private fb: FormBuilder,
    private gitrepoService: GitReposService,
    private addGitRepoService: AddGitrepoModalService,
    private router: Router
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    // Fetch edit repo default data(if any)
    this.gitRepoData = this.addGitRepoService.getEditRepoData();

    if (this.gitRepoData.id) {
      this.isGitRepoEditMode = true;
      this.selectedGitRepoID = this.gitRepoData.id;
      this.gitRepoURL = this.gitRepoData.url;
      this.gitRepoBranch = this.gitRepoData.default_branch;
      this.gitRepoDescription = this.gitRepoData.description;
    }

    // Edit Modal
    if (this.isGitRepoEditMode) {
      this.modalRef.buttons.submitButton.onClick().subscribe(() => {
        this.modalRef.close({
          id: this.selectedGitRepoID,
          url: this.gitRepoURL,
          default_branch: this.gitRepoBranch,
          description: this.gitRepoDescription
        });
      });
    } else {
      // Add Modal
      this.modalRef.buttons.submitButton.onClick().subscribe(() => {
        this.modalRef.close({
          url: this.gitRepoURL,
          default_branch: this.gitRepoBranch,
          description: this.gitRepoDescription
        });
      });
    }
  }

  ngAfterViewInit() {
    this.addGitRepoForm.controls['gitRepoURL'].valueChanges.subscribe((value) => {
      this.gitRepoURL = value;
      this.isValidForm();
    });

    this.addGitRepoForm.controls['gitRepoBranch'].valueChanges.subscribe((value) => {
      this.gitRepoBranch = value;
      this.isValidForm();
    });

    this.addGitRepoForm.controls['gitRepoDescription'].valueChanges.subscribe((value) => {
      this.gitRepoDescription = value;
      this.isValidForm();
    });
  }

  isValidForm(): void {
    const isValidGitURL = this.addGitRepoForm.get('gitRepoURL').valid;
    const isValidGitBranch = this.addGitRepoForm.get('gitRepoBranch').valid;
    if (isValidGitURL && isValidGitBranch) {
      this.addGitRepoService.enableConfirm();
    } else {
      this.addGitRepoService.disableConfirm();
    }
  }

  onUrlInputComplete() {
    // Set Git URL validator
    this.addGitRepoForm.get('gitRepoURL').setValidators([this.gitrepoService.gitUrlValidator,Validators.required]);
    this.addGitRepoForm.get('gitRepoURL').updateValueAndValidity();
  }

  buildForm(): void {
    this.addGitRepoForm = this.fb.group({
      gitRepoURL: ['', [Validators.required]],
      gitRepoBranch: ['', [Validators.required]],
      gitRepoDescription: ['']
    });
  }

  hoverInfoCircleEvent(action: string) {
    Lumos.trackClickEvent('Info Circles', action);
  }
}
