<h2>{{ 'ecs.hosts' | vtranslate }}</h2>
  <clr-datagrid    
  [clrDgLoading]="loading$ | async"
  [clrDgSelected]="selected$ | async"
  [clrDgRowSelection]="true"
  (clrDgSelectedChange)="onSelectionChange($event)"
  (clrDgRefresh)="onDatagridRefresh($event)">
 
  <clr-dg-column [clrDgField]="'name'" (clrDgSortOrderChange)="sort('name', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.name' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostNameFilter">
      <app-monitor-host-filter #hostNameFilter filterColumn="name"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'host_serial_number'" (clrDgSortOrderChange)="sort('host_serial_number', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: true }">{{ 'ecs.host.serial.number' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostSerialNumberFilter">
      <app-monitor-host-filter #hostSerialNumberFilter filterColumn="host_serial_number"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'host_model_identifier'" (clrDgSortOrderChange)="sort('host_model_identifier', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: true }"> {{ 'ecs.host.model' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostModelFilter">
      <app-monitor-host-filter #hostModelFilter filterColumn="host_model_identifier"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'host_vendor_identifier'" (clrDgSortOrderChange)="sort('host_vendor_identifier', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: true }">{{ 'ecs.host.vendor' | vtranslate }}</ng-container> 
    <clr-dg-filter [clrDgFilter]="hostVendorFilter">
      <app-monitor-host-filter #hostVendorFilter filterColumn="host_vendor_identifier"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'host_registration_type'" (clrDgSortOrderChange)="sort('host_registration_type', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.hostDetail.registrationType' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostRegistrationType">
      <app-monitor-host-filter #hostRegistrationType filterColumn="host_registration_type"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'host_activation_key'" (clrDgSortOrderChange)="sort('host_activation_key', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: true }">{{ 'ecs.host.hostDetail.activationkey' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostActivationKey">
      <app-monitor-host-filter #hostActivationKey filterColumn="host_activation_key"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'status'" [attr.data-datagrid-column]="'status'" (clrDgSortOrderChange)="sort('host_registration_status', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }"> {{ 'ecs.host.activationStatus' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostHealthStatusFilter">
      <app-monitor-host-filter #hostHealthStatusFilter filterColumn="host_health_status"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.site.title' | vtranslate }}</ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'git_config_source'" (clrDgSortOrderChange)="sort('git_config_source', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.git.config.source' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostGitConfigSourceFilter">
      <app-monitor-host-filter #hostGitConfigSourceFilter filterColumn="git_config_source"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'git_repo_url'" (clrDgSortOrderChange)="sort('url', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }"> {{ 'ecs.host.gitopsurl' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostGitRepoUrlFilter">
      <app-monitor-host-filter #hostGitRepoUrlFilter filterColumn="url"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'git_repo_path'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.gitOpsPath' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostGitRepoPathFilter">
      <app-monitor-host-filter #hostGitRepoPathFilter filterColumn="git_repo_path"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'last_seen'" (clrDgSortOrderChange)="sort('last_seen', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.lastsynced' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostLastSeenFilter">
      <app-monitor-host-filter #hostLastSeenFilter filterColumn="last_seen"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'groups'" (clrDgSortOrderChange)="sort('groups_count', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.grouplabel' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostGroupLabelFilter">
      <app-monitor-host-filter #hostGroupLabelFilter filterColumn="desired_group_label"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'created_by'" (clrDgSortOrderChange)="sort('created_by', $event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.username' | vtranslate }}</ng-container>
    <clr-dg-filter [clrDgFilter]="hostCreatedByFilter">
      <app-monitor-host-filter #hostCreatedByFilter filterColumn="created_by"></app-monitor-host-filter>
    </clr-dg-filter>
  </clr-dg-column>

  <clr-dg-row *ngFor="let host of data$ | async" [clrDgItem]="host">
    <clr-dg-cell>
      <ng-container *ngIf="isHostHealthActiveOrPending(host); else nonClickableContent">
        <a [routerLink]="[partialLinkToMonitorHostDetailsView, host.unique_identifier]">
          <app-ellipsis-tooltip [text]="host?.name"></app-ellipsis-tooltip>
        </a>
      </ng-container>
      <ng-template #nonClickableContent>
        <app-ellipsis-tooltip [text]="host?.name"></app-ellipsis-tooltip>
      </ng-template>
    </clr-dg-cell>
    <clr-dg-cell>{{ host?.host_registration_details?.host_serial_number }}</clr-dg-cell>
    <clr-dg-cell>{{ host.host_registration_details.host_model_identifier }}</clr-dg-cell>
    <clr-dg-cell>{{ host.host_registration_details.host_vendor_identifier }}</clr-dg-cell>
    <clr-dg-cell>{{ host.host_registration_details.host_registration_type }}</clr-dg-cell>
    <clr-dg-cell>{{ host.host_registration_details.host_activation_key }}</clr-dg-cell>
    <clr-dg-cell>
      <div *ngIf="host.host_registration_status === HostRegistrationStatus.Registered">
        <cds-icon shape="exclamation-triangle" solid status="info"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Registered' | vtranslate }}
      </div>
      <div *ngIf="host.host_registration_status === HostRegistrationStatus.NotActive">
        <cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.NotActive' | vtranslate }}
      </div>
      <div *ngIf="host.host_registration_status === HostRegistrationStatus.Activating">
        <cds-icon shape="process-on-vm" solid status="success"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Activating' | vtranslate }}
      </div>
      <div *ngIf="host.host_registration_status === HostRegistrationStatus.Active">
        <cds-icon shape="check-circle" solid status="success"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Active' | vtranslate }}
      </div>
      <div *ngIf="host.host_registration_status === HostRegistrationStatus.DeletionPending">
        <cds-icon shape="check-circle" solid status="warning"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.DeletionPending' | vtranslate }}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>{{ host.site_name }}</clr-dg-cell>
    <clr-dg-cell>{{ host.git_config_source | titlecase }}</clr-dg-cell>
    <clr-dg-cell>
      <div class="git-url">
        {{ host.git_repo_url || '-' }}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>
      <div class="git-url">
        {{ host.git_repo_url ? host.git_repo_path : '-' }}
      </div>
    </clr-dg-cell>
    <clr-dg-cell>{{ host?.last_seen | ecsDateTime }}</clr-dg-cell>
    <clr-dg-cell>
      <clr-signpost>
        <a class="link-normal tags-link" href="javascript:void(0)" clrSignpostTrigger>{{combinedLabels(host).length}}</a>
        <clr-signpost-content *clrIfOpen [clrPosition]="'right-middle'">
            <div class="tags-container"  *ngFor="let label of combinedLabels(host)">
                <label class="label label-light-blue">
                  {{ label | ecsLabelParser : 'key' }}
                    <span class="badge">{{ label | ecsLabelParser : 'value' }}</span>
                </label>
            </div>
        </clr-signpost-content>
      </clr-signpost>
    </clr-dg-cell>
    <clr-dg-cell>
      <div class="user-name">
        {{ host?.created_by }}
      </div>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-placeholder>
    <div class="empty-placeholder">
      <img src="/apps/ecs/assets/empty-state-light.svg" alt="No entries" />
      <p class="custom-para">{{ 'ecs.hosts.noActiveHosts' | vtranslate }}</p>
      <a class="link" [href]="REGISTER_HOST_DOC_LINK" target="_blank">{{ 'ecs.host.nohostaddedsubtitle' | vtranslate }}</a>
    </div>
  </clr-dg-placeholder>

  <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
    <div class="footer-actions align-left">
        <a class="refresh-btn btn btn-link" (click)="onRefresh()">
            <cds-icon shape="refresh" size="14"></cds-icon>
            <span> {{'ecs.refresh' | vtranslate}}</span>
        </a>
    </div>
    <clr-dg-pagination
      #pagination
      [clrDgPageSize]="paginationInfo?.page?.size"
      [clrDgPage]="paginationInfo?.page?.current"
      [clrDgTotalItems]="totalItems$ | async">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]" > </clr-dg-page-size>
      {{ 'ecs.host.pagination' | vtranslate : pagination.firstItem + 1 : pagination.lastItem + 1 : (totalItems$ | async) }}
        </clr-dg-pagination>
        <div></div>
  </clr-dg-footer>

</clr-datagrid>
