import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export function validateGitRepoPath(control: AbstractControl): ValidationErrors | null {
  const GIT_REPO_PATH_VALIDATION_PATTERN = /^(\/([a-zA-Z0-9_-]+(\/?))*)?$/gi;
  const path = control.value;

  if (path && GIT_REPO_PATH_VALIDATION_PATTERN.test(path) === false) {
    return { invalidPath: true };
  }

  return null;
}
