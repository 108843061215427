export const ENG_ECS_ADD_HOST_TRANSLATIONS = {
    'ecs.addhost.title':'Add a New Host',
    'ecs.addhost.addhost':'Add Host',
    'ecs.addhost.addhost.failure':'Failed to add host',
    'ecs.addhost.edithost.failure':'Failed to edit host',
    'ecs.addhost.edithost.success':'Host has been edited successfully!',
    'ecs.addhost.addToSite':'Add to Site',
    'ecs.addhost.next':'next',
    'ecs.addhost.hardwareinfo.duplicatehostdetails':'There is already a host present with the same model, serial number and vendor',
    'ecs.addhost.activationnkey.duplicatehostdetails':'There is already a host present with the Activation key',
    'ecs.addhost.duplicatename':'There is already a host present with the Name',
    'ecs.addhost.gitAssociation': 'Associate Git Repository',
    'ecs.addhost.form.gitRepo.label': 'Git Repository',
    'ecs.addhost.form.gitRepo.helpText': 'Select a Git repository to be associated with this site',
    'ecs.addhost.form.gitRepoBranch.label': 'Branch',
    'ecs.addhost.form.gitRepoBranch.helpText': 'For example: main',
    'ecs.addhost.form.gitRepoPath.label': 'Path',
    'ecs.addhost.form.gitRepoPath.helpText': 'For example: /path/in/repo',
    'ecs.addhost.form.gitRepoUsername.label': 'Username',
    'ecs.addhost.form.gitRepoUsername.helpText': 'For example: myusername',
    'ecs.addhost.form.gitRepoAccessToken.label': 'Access Token',
    'ecs.addhost.form.gitRepoAccessToken.helpText': 'Provide a valid access token to access the Git repository',
    'ecs.addHost.form.inheritFromSite.label': 'Use same as the selected site',
    'ecs.addHost.form.configureAtHost.label': 'Configure for this host',
    'ecs.addHost.form.gitRepo.step.description': 'Select the path to the desired state file to be associated with the site. The hosts can inherit the configurations from the Site or can be configured independently.',
  };
  