import { Injectable } from '@angular/core';
import { VmwNgxModalService } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { AlertType, KeswickAlertService } from '@ecs/ecs-platform';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { RebootHostModalContentComponent } from '../components/reboot-host-modal-content/reboot-host-modal-content.component';
import { HostsService } from './hosts.service';
import { HostResp } from '@ecs/ecs-api';
import { MODAL_ACCEPT } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class RebootHostService {
  constructor(
    private hostService: HostsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private l10service: L10nService
  ) {}

  openRebootHostModal(host: HostResp): Observable<void> {
    const modalRef = this.modalService.openOkCancel(RebootHostModalContentComponent, {
      title: this.l10service.getMessage('ecs.action.reboothost'),
      data: host,
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.yes'),
          handler: (ref) => {
            // the modal is closed with 'accept'
            ref.close('accept');
          }
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.no'),
          handler: (ref) => {
            // the modal is closed with 'deny'
            ref.close('deny');
          }
        }
      }
    });
    return modalRef.onClose().pipe(
      switchMap((result: string) => {
        if (result === MODAL_ACCEPT) {
          return this.hostService.rebootHost(host.unique_identifier).pipe(
            catchError((err) => {
              this.handleRebootHostError(host.name);
              return of(err);
            }),
            tap(() => {
              this.handleRebootHostSuccess(host.name);
            })
          );
        }
        return of(null)
      })
    );
  }

  private handleRebootHostSuccess(hostName: string) {
    this.alertService.showMessage({
      type: AlertType.success,
      message: this.l10service.getMessage('ecs.reboothostmodal.success', [hostName])
    });
  }

  private handleRebootHostError(hostName: string) {
    this.alertService.showMessage({
      type: AlertType.failure,
      message: this.l10service.getMessage('ecs.reboothostmodal.failure', [hostName])
    });
  }
}
