import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-poster',
  templateUrl: './video-poster.component.html',
  styleUrls: ['./video-poster.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPosterComponent {
  @Input() public autoPlay: boolean;
  @Input() public videoSource: SafeResourceUrl;
  @Input() public altText: string;
  @Output() private notifyVideoClose = new EventEmitter<boolean>();

  public onClose(): void {
    this.autoPlay = false;
    this.notifyVideoClose.emit(true);
  }
}
