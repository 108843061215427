import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ClrPopoverToggleService } from '@clr/angular';
import { GetGitReposResp, GitRepoResp, HostResp } from '@ecs/ecs-api';
import { MODAL_DATA, OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { BehaviorSubject } from 'rxjs';
import { GitReposService } from '../../services/gitrepos.service';
import { validateGitRepoPath } from '../../utils/validators/git-repo-path.validator';
import { validateGitRepoUrl } from '../../utils/validators/git-repo-url.validator';
import { Lumos } from '@ecs/ecs-common';
import { Unsubscriber } from '@velocloud/angular-vc-common';

export interface ActivateHostModalData {
  selectedHosts: {
    name:string;
    id:string;
    gitrepo:string;
  }[];
}

@Component({
  selector: 'app-activate-host-modal',
  templateUrl: './activate-host-modal.component.html',
  styleUrls: ['./activate-host-modal.component.scss'],
  providers: [ClrPopoverToggleService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivateHostModalComponent extends Unsubscriber implements OnInit{
  private gitRepoDataSource = new BehaviorSubject<GitRepoResp[]>([]);


  public readonly gitRepoData$ = this.gitRepoDataSource.asObservable();
  public hosts:ActivateHostModalData["selectedHosts"]
  public hostsWithoutGitRepo:ActivateHostModalData["selectedHosts"]

  @Input() activateHostModal = false;

  constructor(
    @Inject(MODAL_DATA) private data: ActivateHostModalData,
  ) {
    super();
  }

  ngOnInit(){
    this.hosts=this.data.selectedHosts
    this.hostsWithoutGitRepo=this.hosts.filter(host=>!host.gitrepo)
  }

}
