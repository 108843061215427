import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIPService, LocaleService, getBrowserCultureLang, VIPModule } from '@vmw/ngx-vip';
import { EcsI18nConfig } from './ecs-i18n.config';
import { I18nLinkTargetDirective } from './directives/i18n-link-target.directive';

export function initVIPConfig(vipService: VIPService, localeService: LocaleService) {
  const currentlocale: string = getBrowserCultureLang();
  localeService.init(currentlocale);
  return () => vipService.initData(EcsI18nConfig);
}

@NgModule({
  declarations: [I18nLinkTargetDirective],
  imports: [CommonModule, VIPModule.forRoot()],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initVIPConfig,
      deps: [VIPService, LocaleService],
      multi: true
    }
  ],
  exports: [VIPModule, I18nLinkTargetDirective]
})
export class EcsI8nModule {}
