import { Pipe, PipeTransform } from '@angular/core';

// Parses a label of format 'key:value' and return either key or value based on the argument passed
// Usage : label | ecsLabelParser : 'key'
@Pipe({
  name: 'ecsLabelParser'
})
export class LabelParserPipe implements PipeTransform {
  transform(label: string, element: 'key' | 'value'): string {
    let parsedLabel = '';
    if (!label || label.trim() === '' || label.indexOf(' ') !== -1) {
      return '';
    }
    const parts = label.split(':');
    if (element === 'key' && parts.length > 0) {
      parsedLabel = parts[0].trim();
    } else if (element === 'value' && parts.length > 1) {
      parsedLabel = parts[1].trim();
    }
    return parsedLabel;
  }
}
