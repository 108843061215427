import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertType, KeswickAlertService } from './keswick-alert.service';
import { L10nService } from '@vmw/ngx-vip';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {
  constructor(private alertService: KeswickAlertService, private l10service: L10nService) {}

  showApiError(error: HttpErrorResponse): Observable<never> {
    // Backend API for batch-register will take care of returning appropriate
    // API status
    if (error?.url?.includes('batch-register')) {
      if (
        error?.status === HttpStatusCode.NotFound ||
        error?.status === HttpStatusCode.Conflict ||
        error?.status === HttpStatusCode.InternalServerError
      ) {
        this.alertService.showMessage({
          type: AlertType.failure,
          message: error?.error?.status?.status
        });
      } else {
        // Failed to register all hosts in the csv file due to multiple backend errors
        if (error?.error?.status?.status) {
          this.alertService.showMessage({
            type: AlertType.failure,
            message: error?.error?.status?.status
          });
        } else {
          // Failed to register all hosts due to incorrect registration data sent
          this.alertService.showMessage({
            type: AlertType.failure,
            message: this.l10service.getMessage('ecs.error.incorrectbatchregistrationdata')
          });
        }
      }
      console.log('An error occured in batch registration:', error.error);
    } else if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.alertService.showMessage({
        type: AlertType.failure,
        message: this.l10service.getMessage('ecs.error.generalerror')
      });
      console.error('An error occurred:', error?.error);
    } else if (error.status == HttpStatusCode.BadRequest) {
      this.alertService.showMessage({
        type: AlertType.failure,
        message: error?.error?.message
      });
      console.error('An error occurred:', error?.error);
    } else if (error.status == HttpStatusCode.GatewayTimeout) {
      this.alertService.showMessage({
        type: AlertType.failure,
        message: error?.message
      });
      console.error('An error occurred:', error?.error);
    } else if (error.status == HttpStatusCode.Conflict) {
      this.alertService.showMessage({
        type: AlertType.failure,
        message: error?.error?.message
      });
      console.error('An error occurred:', error?.error);
    } else if (error.status === HttpStatusCode.NotFound) {
      this.alertService.showMessage({
        type: AlertType.failure,
        message: error?.error?.message
      });
      console.error('An error occurred:', error?.error);
    } else {
      // The response body may contain clues as to what went wrong.

      // TODO: THIS NEEDS A FIX
      // IMPORTANT: THE showMessage() IS COMMENTED OUT FOR NOW BECAUSE WE SHOULD NOT BE
      // USING A BLANKET MESSAGE FOR ALL ERRORS THAT POP UP
      // INSTEAD WE SHOULD FOLLOW AN APPROACH WHERE EACH INDIVIDUAL COMPONENT HANDLES
      // THIS SITUATION AT A MORE GRANULAR LEVEL

      // this.alertService.showMessage({
      //   type: AlertType.failure,
      //   message: 'Something went wrong!'
      // });
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }

    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
