import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicComponentService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef) {}

  createComponent(component: Type<unknown>, element: HTMLElement, injector: Injector): void {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const compRef = compFactory.create(injector);

    this.appRef.attachView(compRef.hostView);
    element.appendChild((compRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement);
  }
}
