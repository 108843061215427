import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { ConfirmationModalComponent } from './components/confirmation/confirmation-modal/confirmation-modal.component';
import { EcsFormControlContainerDirective } from './components/form-controls/ecs-form-control-container.directive';
import { EcsFormControlErrorsComponent } from './components/form-controls/ecs-form-control-errors/ecs-form-control-errors.component';
import { HeaderItemsComponent } from './components/header-items/header-items.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DateTimePipe } from './pipes/date-time.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TagsEditorComponent } from './components/tags-editor/tags-editor.component';
import { VIPModule } from '@vmw/ngx-vip';
import { DeactivateAddTagPipe } from './components/tags-editor/deactivate-add-tag.pipe';
import { GitRepoConfigEditorComponent } from './components/git-repo-config-editor/git-repo-config-editor.component';
import { VcCommonModule } from '@velocloud/angular-vc-common';
import { LabelFormatterPipe } from './pipes/label-formatter.pipe';
import { LabelParserPipe } from './pipes/label-parser.pipe';
import { EllipsisTooltipComponent } from './components/ellipsis-tooltip/ellipsis-tooltip.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    HeaderItemsComponent,
    DateTimePipe,
    LabelFormatterPipe,
    LabelParserPipe,
    PageHeaderComponent,
    ConfirmationModalComponent,
    EcsFormControlContainerDirective,
    EcsFormControlErrorsComponent,
    TagsEditorComponent,
    DeactivateAddTagPipe,
    GitRepoConfigEditorComponent,
    DateTimePipe,
    EllipsisTooltipComponent,
  ],
  imports: [CommonModule, ClarityModule, RouterModule, FormsModule, ReactiveFormsModule, VIPModule],
  providers:[EcsFormControlContainerDirective],
  exports: [
    SpinnerComponent,
    HeaderItemsComponent,
    PageHeaderComponent,
    DateTimePipe,
    LabelFormatterPipe,
    LabelParserPipe,
    ConfirmationModalComponent,
    EcsFormControlContainerDirective,
    EcsFormControlErrorsComponent,
    TagsEditorComponent,
    DeactivateAddTagPipe,
    GitRepoConfigEditorComponent,
    EllipsisTooltipComponent,
  ]
})
export class EcsCommonModule {}
