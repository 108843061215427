<vmw-search
  *ngIf="filterColumn !== 'host_health_status' && filterColumn !== 'last_seen'"
  (search)="onFilterValueChange($event)"
  [disabled]="isSearching"
  [debounceTime]="seachDebounceTime"
  [searchQueryValue]="getNgModelValue(filterColumn)"
  [placeholder]="getPlaceholderMessage()"
  [searchTermMinimalLength]="searchTermMinimalLength"
  clearSearchTitle="Clear search results">
</vmw-search>
<div *ngIf="filterColumn === 'host_health_status'">
  <!-- <clr-checkbox-wrapper>
    <input
      #activeCheckbox
      type="checkbox"
      clrCheckbox
      value="Active"
      [ngModel]="getNgModelValue('Active')"
      (change)="handleStatusCheckboxChange('Active', activeCheckbox.checked, $event)" />
    <label><cds-icon shape="check-circle" solid status="success"></cds-icon>{{ 'ecs.host.status.active' | vtranslate }}</label>
  </clr-checkbox-wrapper>
  <clr-checkbox-wrapper>
    <input
      #pendingCheckbox
      type="checkbox"
      clrCheckbox
      value="Pending"
      [ngModel]="getNgModelValue('Pending')"
      (change)="handleStatusCheckboxChange('Pending', pendingCheckbox.checked, $event)" />
    <label><cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon>{{ 'ecs.host.status.pending' | vtranslate }}</label>
  </clr-checkbox-wrapper> -->
  <div *ngFor="let status of statusList">
    <clr-checkbox-wrapper>
      <input
        type="checkbox"
        clrCheckbox
        [value]="status"
        [ngModel]="checkboxStates[status]"
        (ngModelChange)="handleStatusCheckboxChange(status, $event)" />
        <label *ngIf="status === HostRegistrationStatus.Registered">
          <cds-icon shape="exclamation-triangle" solid status="info"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Registered' | vtranslate }}
        </label>
        <label *ngIf="status === HostRegistrationStatus.NotActive">
          <cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.NotActive' | vtranslate }}
        </label>
        <label *ngIf="status === HostRegistrationStatus.Activating">
          <cds-icon shape="process-on-vm" solid status="success"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Activating' | vtranslate }}
        </label>
        <label *ngIf="status === HostRegistrationStatus.Active">
            <cds-icon shape="check-circle" solid status="success"></cds-icon> {{ 'ecs.enums.hostRegistrationStatus.Active' | vtranslate}}
        </label>
        <label *ngIf="status === HostRegistrationStatus.DeletionPending">
            <cds-icon shape="check-circle" solid status="warning"></cds-icon><span>{{ 'ecs.enums.hostRegistrationStatus.DeletionPending' | vtranslate }}</span>
        </label>
    </clr-checkbox-wrapper>
  </div>
</div>
<div *ngIf="filterColumn === 'last_seen'">
  <clr-date-container>
    <label>{{ 'ecs.host.startdate' | vtranslate }}:</label>
    <input
      type="date"
      autocomplete="off"
      name="filterStartDate"
      clrDate
      [ngModel]="getNgModelValue('LastSeenRangeStart')"
      (ngModelChange)="handleLastSeenFilterChange(true, $event)" />
  </clr-date-container>
  <clr-date-container>
    <label>{{ 'ecs.host.enddate' | vtranslate }}:</label>
    <input
      type="date"
      autocomplete="off"
      name="filterEndDate"
      clrDate
      [ngModel]="getNgModelValue('LastSeenRangeEnd')"
      (ngModelChange)="handleLastSeenFilterChange(false, $event)" />
  </clr-date-container>
</div>
