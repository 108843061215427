import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { HostResp, HostsService, SortOrder } from '@ecs/ecs-api';
import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { OrgService } from '@ecs/ecs-platform';
import { BehaviorSubject, Observable, combineLatest, filter, finalize, map, switchMap, tap } from 'rxjs';

@Injectable()
export class AssociatedHostListService  extends AbstractPaginatableListService<HostResp> {
  private readonly siteIdSource = new BehaviorSubject<string | null>(null);
  private defaultSortColumn = 'last_seen';

  set siteId(value: string) {
    this.siteIdSource.next(value);
  }

  constructor(private orgService: OrgService, private hostService: HostsService) {
    super();
  }

  fetchData(pagination: ClrDatagridStateInterface<HostResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return combineLatest([this.orgService.orgId$, this.siteIdSource]).pipe(
      filter(([orgId, siteId]) => orgId !== null && siteId !== null),
      switchMap(([orgId, siteId]) => {
      const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      const filters = pagination.filters?.reduce((acc, filter) => ({
          ...acc,
          [filter.property]: filter.value
        }), {});
      return this.hostService.listHosts(orgId, current, size, sortColumn as any, sortOrder, null,null, {
        ...filters,
        site_id: siteId,
      });
    }), map(response => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: response.page,
          size: response.per_page,
          from: response.per_page * (response.page - 1) + 1,
          to: response.per_page * response.page
        }
      });
      this.dataSource.next(response.results ?? []);
      this.totalItemsSource.next(response.total ?? 0);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
    finalize(() => {
      this.loadingSource.next(false);
    }));
  }

}
