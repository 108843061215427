import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ESortOrder, MONITOR_HOSTS_DETAILS_ROUTE, HOST_DEFAULT_FILTERS, getAdjustedRoute } from '@ecs/ecs-platform';
import { HostResp, HostRegistrationStatus } from '@ecs/ecs-api';
import { getTimeStamp, REGISTER_HOST_DOC_LINK, AbstractPaginatableListComponent, convertLabelsToUILabels } from '@ecs/ecs-common';
import { MonitorHostsService } from './monitor-hosts.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { take } from 'rxjs';

@Component({
  selector: 'app-hosts',
  templateUrl: './monitor-hosts.component.html',
  styleUrls: ['./monitor-hosts.component.scss']
})
export class MonitorHostsComponent extends AbstractPaginatableListComponent<HostResp> implements OnInit {
  readonly REGISTER_HOST_DOC_LINK = REGISTER_HOST_DOC_LINK;
  readonly HostRegistrationStatus = HostRegistrationStatus;

  private hostID: string;

  private hostHealthArr : any= undefined;

  private sortOrder = ESortOrder.ASCENDING; // Default sortOrder
  private sortColumn = 'id'; // Default sortColumn
  private timeoutId: number | null = null;
  private filterValues: Map<string, string> = new Map<string, string>();

  public hosts: Array<any> = [];
  public selectedHosts: Array<HostResp> = [];
  selected$ = this.hostService.selected$;
  public selectedHostCount: number;
  public item: any;
  public pageSize: number;
  public currentPage: number;
  public totalHosts = 0;
  public loading = false;
  public readonly EXPECTED_FILTER_CHANGE_ENTRIES: number = 2;
  public getTimeStamp = getTimeStamp;
  public appliedFilters = {};
  public partialLinkToMonitorHostDetailsView: string;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private hostService: MonitorHostsService,
    private router: Router) {
    super(hostService);
    this.partialLinkToMonitorHostDetailsView = getAdjustedRoute(this.router, MONITOR_HOSTS_DETAILS_ROUTE);
    this.subs = this.pagination$.pipe(take(1)).subscribe(pagination => {
      this.pageSize = pagination.page.size;
      this.currentPage = pagination.page.current;
    });
    this.checkForQueryParams();
    this.listenToValueChanges();
  }

  checkForQueryParams() {
    this.route.queryParams.subscribe(selected => {
      this.appliedFilters = selected;
    })
  }

  listenToValueChanges() {
    this.subs = this.hostService.filterInputChanged.subscribe((vals: [string, string]) => {
      if (vals.length === this.EXPECTED_FILTER_CHANGE_ENTRIES) {
        this.onFilterChange(vals[0], vals[1]);
      }
    });

    this.subs = this.hostService.lastSeenStartDateChanged.subscribe(() => {
      this.onStartDateChange();
    });

    this.subs = this.hostService.lastSeenEndDateChanged.subscribe(() => {
      this.onEndDateChange();
    });

    this.subs = this.route.queryParams.subscribe((params) => {
      const groupFilterValue = params?.['group_label'];
      const gitUrlFilterValue = params?.['git_repo_url'];
      if (groupFilterValue) {
        this.filterValues.set('group_label', groupFilterValue);
      }

      if (gitUrlFilterValue) {
        this.filterValues.set('url', gitUrlFilterValue);
      }

      const filterValue = params?.['desired_group_label'];
      if (filterValue) {
        this.filterValues.set('desired_group_label', filterValue);
      }
      this.checkFiltersFromQueryParam();
    });
  
  }
  
  checkFiltersFromQueryParam() { // Reading the filters from query params and setting that to variables, so that it will be used in API call.
    this.hostService.filterValues.clear();
    this.hostService.hostHealthArr = undefined;
    this.hostService.hostHealthStatus = [];
    let removeDefaultFilters = {}
    if (!_.isEmpty(this.appliedFilters)) {
      removeDefaultFilters = this.appliedFilters;
      const defaultFilters = Object.keys(this.hostService.defaultSelectValue);
   
      removeDefaultFilters = _.omit(removeDefaultFilters, defaultFilters);
      Object.keys(removeDefaultFilters).forEach((key: any) => {
        if (key === 'host_registration_status') {
          this.hostHealthArr = [removeDefaultFilters[key as keyof typeof removeDefaultFilters]];
          this.hostHealthArr = this.hostHealthArr[0].split(',')
          this.hostService.hostHealthArr = this.hostHealthArr;
        } else {
          this.filterValues.set(key, removeDefaultFilters[key as keyof typeof removeDefaultFilters]);
          this.hostService.filterValues = this.filterValues;
        }
      });
      this.router.navigate([], { queryParams: this.appliedFilters })
    } else {
      this.router.navigate([], { queryParams: this.hostService.defaultSelectValue })
    }
    this.hostService.setQueryParams(removeDefaultFilters, this.hostHealthArr);
  }

  sort(sortColumn: string, sortDirection: number): void {
    this.loading = true;
    this.sortColumn = sortColumn;
    this.sortOrder = sortDirection === 1 ? ESortOrder.ASCENDING : ESortOrder.DESCENDING;
    this.hostService.getHosts(this.hostHealthArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onStartDateChange() {
    this.loading = true;
    if (this.hostService.filterStartDate != undefined && this.hostService.filterStartDate != '') {
      const lastSeenStartRange = new Date(this.hostService.filterStartDate).toISOString();
      this.filterValues.set('last_seen_range_start', lastSeenStartRange);
    } else {
      this.filterValues.delete('last_seen_range_start');
    }
    this.setFilterValues(this.hostHealthArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onEndDateChange() {
    this.loading = true;
    if (this.hostService.filterEndDate != undefined && this.hostService.filterEndDate != '') {
      const lastSeenEndRange = new Date(this.hostService.filterEndDate).toISOString();
      this.filterValues.set('last_seen_range_end', lastSeenEndRange);
    } else {
      this.filterValues.delete('last_seen_range_end');
    }
    this.setFilterValues(this.hostHealthArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onFilterChange(filterColumn: string, filterValue: string | string[]) {
    if (!filterValue) {
      // Empty filter value, delete the filterColumn from filterValues
      this.filterValues.delete(filterColumn);
    } else if (Array.isArray(filterValue) && filterColumn === 'host_health_status') {
      // Filters for host_health_status
      this.hostHealthArr = []
      if (filterValue.length > 0) {
        this.hostHealthArr.push(...filterValue);
      }
    } else {
      if (typeof filterValue === 'string'){
        this.filterValues.set(filterColumn, filterValue);
      }
    }

    // Clear previous timeout if exists
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.setFilterValues(this.hostHealthArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  setFilterValues(hostHealthArr: Array<HostRegistrationStatus>, filterValues: Map<string, string>) { // Applying the filter in URL as query params
    let params;
    if (hostHealthArr && hostHealthArr.length > 0) {
      params = { ...HOST_DEFAULT_FILTERS, host_registration_status: hostHealthArr.join(',') }
    } else {
      params = HOST_DEFAULT_FILTERS;
    }
    this.appliedFilters = { ...params, ...Object.fromEntries(filterValues) };
    this.router.navigate([], { queryParams: this.appliedFilters });
  }

  isHostHealthActiveOrPending(selectedHost: HostResp) {
    return [HostRegistrationStatus.Active, HostRegistrationStatus.NotActive].some((value) => value === selectedHost?.host_registration_status);
  }

  onSelectionChange(selection: Array<any>) {
    this.hostService.setSelected(selection);
  }

  combinedLabels(host:HostResp){
    return host.desired_group_labels.concat(convertLabelsToUILabels(host.autoGeneratedLabels))
  }
}
