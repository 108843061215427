import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HostResp, SiteResp } from '@ecs/ecs-api';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ecs-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteDetailsComponent extends Unsubscriber {
  private readonly associatedHosts = new BehaviorSubject<HostResp[]>([]);
  
  associatedHosts$ = this.associatedHosts.asObservable();

  @Input() site: SiteResp;

  constructor() {
    super();
  }
  
}
