import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { CceCrossOrgSelectorComponent } from './cross-org-selector.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ClarityModule],
  declarations: [CceCrossOrgSelectorComponent],
  exports: [CceCrossOrgSelectorComponent]
})
export class CceCrossOrgSelectorModule {}
