export const GROUP_LIST_COLUMNS = [
    {
        name: 'label',
        label: 'ecs.grouplist.column.grouplabel',
        field: 'label'
    },
    {
        name: 'description',
        label: 'ecs.grouplist.column.groupdescription',
        field: 'description'
    },
    {
        name: 'total_host_count',
        label: 'ecs.grouplist.column.hostnumber',
        field: 'total_host_count'
    },
    {
        name: 'alerts_count',
        label: 'ecs.alerts',
        field: 'alerts_count'
    }
];