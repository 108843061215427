export const ENG_ECS_LAUNCHPAD_FOOTER_TRANSLATIONS = {
  'ecs.launchpad.footer.documents': 'Documents',
  'ecs.launchpad.footer.documents.quickstart': 'Quickstart guide',
  'ecs.launchpad.footer.documents.deployedge': 'Learn how to deploy an Edge Compute Host',
  'ecs.launchpad.footer.documents.opensourcelicense': 'Edge Compute Open Source Licenses',
  'ecs.launchpad.footer.videotutorials': 'Videos and Tutorials',
  'ecs.launchpad.footer.videotutorials.fulldeployment': 'Full Deployment Video',
  'ecs.launchpad.footer.videotutorials.deployingyourhost': 'Deploying your Host',
  'ecs.launchpad.footer.videotutorials.configuringhost': 'Configuring your host',
  'ecs.launchpad.footer.articles': 'Articles',
  'ecs.launchpad.footer.articles.introduceedgecompute': 'Introducing Edge Compute Stack',
  'ecs.launchpad.footer.articles.simplyedgedeployments': 'Simplifying Edge Deployments at Scale',
  'ecs.launchpad.footer.articles.deployyourhome': 'Deploying your home lab with Edge Compute Stack'
};
