<h2>{{ 'ecs.sites.configure.label' | vtranslate }}</h2>
<p *ngIf="!heroBanner" class="page-info">
  {{ 'ecs.site.banner' | vtranslate }}
</p>
<div class="hero-section" *ngIf="heroBanner">
  <div class="hero-content">
    <div>
      <div class="hero-top">
        <h2>{{ 'ecs.site.banner.gettingstarted' | vtranslate }}</h2>
      </div>

      <div>
        {{ 'ecs.site.banner' | vtranslate }}
      </div>
      <p></p>
      <div>
        <span
          appI18nLinkTarget="link"
          (i18nLinkTargetClicked)="openSiteInstructions()"
          [innerHTML]="'ecs.site.banner.subdescription' | vtranslate">
        </span>
      </div>
    </div>
    <img src="/apps/ecs/assets/sites-hero-image-light.svg" alt="sites hero section"/>
  </div>
  <button class="btn btn-primary" (click)="openAddSiteModal()">{{ 'ecs.site.banner.addsite' | vtranslate }}</button>
  <a [href]="LEARN_MORE_ABOUT_SITE" target="_blank" rel="noopener noreferrer">
    <button class="btn btn-outline">{{ 'ecs.site.banner.learnmore' | vtranslate }}</button>
  </a>

  <div class="close-banner" (click)="hideHeroBanner()">
    <cds-icon shape="window-close" size="md"></cds-icon>
  </div>
</div>

<ecs-sites-list></ecs-sites-list>
