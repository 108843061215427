<ul class="nav-group list-unstyled">
   <li *ngIf="section.sectionName && !collapsed && ((hideSection$ | async) === false)"><h4 class="group-label">{{ section.sectionName | vtranslate }}</h4></li>
   <ng-container *ngFor="let link of section.links; trackBy:trackByName">
      <li *ngIf="!link.isExternal || link.visible" #navLink>
         <a clrVerticalNavLink  (click)="navLinkClicked(link)"
         [class.active]="link.selected">
            <ng-container 
               *ngIf="link.icon" 
               clrVerticalNavIcon
               [ngTemplateOutlet]="collapsed ? panelCollapsed : panelExpanded"
               [ngTemplateOutletContext]="{icon: link.icon, name: link.name}">
            </ng-container>
            <span title="{{ link.name | vtranslate }}">{{ link.name | vtranslate }}</span>
          </a>
      </li>
   </ng-container>
</ul>

<ng-template #panelCollapsed let-icon="icon" let-name="name">
   <clr-tooltip>
      <div class="clr-icon-container" clrTooltipTrigger>
         <cds-icon clrVerticalNavIcon [shape]="icon"></cds-icon>
         <!-- <clr-tooltip-content clrPosition="right" clrSize="md" *clrIfOpen>
            <span>{{ name | vtranslate }}</span>
         </clr-tooltip-content> -->
      </div>
   </clr-tooltip>
</ng-template>

<ng-template #panelExpanded let-icon="icon">
   <cds-icon clrVerticalNavIcon [shape]="icon"></cds-icon>
</ng-template>
