export const ENG_ECS_EDIT_HOST_MODAL_TRANSLATIONS = {
  'ecs.edithostmodal.title': 'You can edit the following details for the host',
  'ecs.edithostmodal.branch.placeholdershort': 'Add branch',
  'ecs.edithostmodal.username.placeholdershort': 'Add username',
  'ecs.edithostmodal.gitRepo.path': 'Path',
  'ecs.edithostmodal.gitRepo.path.tooltip': 'Specify the directory in Git Repository where desired state manifests are stored',
  'ecs.edithostmodal.gitRepo.path.placeholder': 'Specify path',
  'ecs.edithostmodal.gitRepo.path.helper': 'e.g. /ecs/manifests',
  'ecs.edithostmodal.errors.invalidPath': 'Invalid path format.'
};
