import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KESWICK_DEMO_VIDEO_URL, LEARN_MORE_ABOUT_KESWICK } from '@ecs/ecs-common';

@Component({
  selector: 'app-keswick-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.scss']
})
export class LaunchpadComponent {
  public isPlayButtonClicked = false;
  public videoSource: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(KESWICK_DEMO_VIDEO_URL.replace("watch?v=", "embed/"));
  public altText = 'Click Here to Play the Video';
  public readonly LEARN_MORE_ABOUT_KESWICK = LEARN_MORE_ABOUT_KESWICK;

  constructor(private sanitizer: DomSanitizer) {}
}
