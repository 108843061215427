import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertMessage, AlertType, KeswickAlertService } from '@ecs/ecs-platform';

@Component({
  selector: 'app-keswick-alert',
  templateUrl: './keswick-alert.component.html',
  styleUrls: ['./keswick-alert.component.scss']
})
export class KeswickAlertComponent {
  alert: AlertMessage | null = null;
  alertType = AlertType;
  routeSubscription!: Subscription;
  timeOutId: number = null;
  navigationLabel: string = null;

  constructor(private appAlertService: KeswickAlertService, private router: Router) {
    const clearTime = 10000;
    this.appAlertService.onMessage().subscribe((alert) => {
      this.alert = alert;
      this.timeOutId = setTimeout(() => {
        this.alert = null;
      }, clearTime) as unknown as number;
    });
  }

  closeAlert() {
    this.alert = null;
    clearTimeout(this.timeOutId);
  }
}
