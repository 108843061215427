import { Inject, Component, ViewContainerRef, Renderer2, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
//import { CspServiceSdkToolkit } from '@vmw/csp-service-sdk';
//import { CspHeaderMode, CspHeaderTheme, CspHeaderComponent } from '@vmw/csp-ngx-components/header';
//import { Org } from '@vmw/csp-common';
//import { OAuth2TokenSubject } from '@vmw/ngx-csp-oauth2';
import { VmwContextualHelpService } from '@vmw/ngx-contextual-help';
import { AppRoutes, CommonAppRoutes, NavigationService, NavigationState, OnboardingService, getAdjustedRoute, getLastRouteWithSidenavConfig, getSidenavLinks } from '@ecs/ecs-platform';
import { APP_ENVIRONMENT, CceUiEnvironment, OrgService } from '@ecs/ecs-platform';
import { VmwClarityTheme, VmwClarityThemeService, VmwIdleDetectionService } from '@vmw/ngx-utils';

//import { CspInitializationConfiguration } from '@vmw/csp-ngx-components';
// import { OAuth2Client } from '@vmw/csp-oauth2';

import { Title } from '@angular/platform-browser';
import { DynamicComponentService, ACCESS_TOKEN_TTL, ACCESS_TOKEN_EXPIRY_WARN_DELAY } from '@ecs/ecs-platform';
//import { HeaderItemsComponent, Lumos } from '@ecs/ecs-common';
import { L10nService } from '@vmw/ngx-vip';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { isEqual as _isEqual } from 'lodash';
import { NavItem, Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
  selector: 'ecs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsubscriber implements OnInit, AfterViewInit {
  private APP_TITLE = '';
  private PRODUCT_NAME = '';

  //@ViewChild(CspHeaderComponent, { read: ElementRef }) cspHeader: ElementRef;

  public authToken: string;
  //public headerOptions: CspHeaderOptions = new CspHeaderOptions();
  public readonly serviceId: string;
  //public openDrawer = new Subject<CspHeaderMode>();
  public showOrgSelector = new Observable<boolean>();
  public open = false;
  public showSessionExpiredModal = false;
  public selectedThemeStyles: string;

  @ViewChild('contentArea', { static: true }) contentArea: ElementRef;
  horizontalTabs: NavItem[];
  selectedHorizontalTabId = '';
  private navStates: NavigationState[];
  private relativeRoute: ActivatedRoute;
  private enterpriseHorizontalTabs: NavigationState[] = [
    NavigationState.ENTERPRISE_MONITOR,
    NavigationState.ENTERPRISE_CONFIGURE,
  ];

  private stateToRoute: Record<NavigationState, AppRoutes> = {
    [NavigationState.ENTERPRISE_MONITOR]: AppRoutes.monitor,
    [NavigationState.ENTERPRISE_CONFIGURE]: AppRoutes.configure,
 };

  public get isLocalhost(): boolean {
    return window.location.host.includes('localhost');
  }

  public set localMock(val: any) {
    localStorage.setItem('usage-mock-local', val.toString());
  }

  public get localMock(): boolean {
    return localStorage.getItem('usage-mock-local') === 'true';
  }

  constructor(
    // private oauthToken$: OAuth2TokenSubject,
    // public cspServiceSdk: CspServiceSdkToolkit,
    private helpService: VmwContextualHelpService,
    // private oauth2Client: OAuth2Client,
    @Inject(APP_ENVIRONMENT) public environment: CceUiEnvironment,
    private orgService: OrgService,
    private idleDetectionService: VmwIdleDetectionService,
    // private cspApiService: CspApiService,
    private onboardingService: OnboardingService,
    private titleService: Title,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private dynamicCompService: DynamicComponentService,
    private l10service: L10nService,
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: VmwClarityThemeService,
  ) {
    super();
    this.serviceId = this.environment.cspServiceDefinitionId;
    this.showOrgSelector = this.orgService.crossOrgAvailableForUser$;
    this.themeService.theme = VmwClarityTheme.Light; // Setting default theme to light, below line can be enabled while implementing theme switcher
    // this.selectedThemeStyles = this.themeService.theme === VmwClarityTheme.Light ? 'ecs-light' : null;
    //this.headerOptions.theme = CspHeaderTheme.LIGHT;
  }

  ngOnInit() {
    this.setFocus();
    this.subs = this.router.events
         .pipe(filter((event: Event) => event instanceof NavigationEnd))
         .subscribe(() => {
            this.setSelectedTab();
            this.setFocus.bind(this);
            //const isOperatorLogin = window['sharedServices']['AuthService'].isOperator;
            //const isMspLogin = window['sharedServices']['AuthService'].isMSP;
            const isEnterpriseLogin = window['sharedServices']['AuthService'].isEnterprise;

            if (isEnterpriseLogin || this.router.url.startsWith(CommonAppRoutes.ecs)) {
              const sessionContext = window['sharedServices']['AuthService'].sessionContext;
              this.orgService.setEnterpriseId(sessionContext.enterpriseId, false);
              this.orgService.enterpriseLogicalId = sessionContext.enterpriseLogicalId;
            } else {
              const parts = this.router.url.split(CommonAppRoutes.ecs);
              const moreParts = parts[0].split('/');
              const customerId = +moreParts[moreParts.length-2];
              this.orgService.setEnterpriseId(customerId);
             }
         });


  
    this.APP_TITLE = this.l10service.getMessage('ecs.apptitle');
    this.PRODUCT_NAME = this.l10service.getMessage('ecs.productname');

    this.titleService.setTitle(this.APP_TITLE);

    // this.oauthToken$.subscribe({
    //   next: (token) => {
    //     this.authToken = token.access_token;
    //   }
    // });

    // Subscribe to the base token returned by GAZ, forcing a refresh if it is invalid.
    // this.oauthToken$.autoRefresh().subscribe({
    //   next: (token) => {
    //     this.authToken = token.access_token;
    //   }
    // });

    this.helpService.toggler.subscribe(() => {
      //this.openDrawer.next(CspHeaderMode.HELP);
    });

    /*
    this.headerOptions.title = this.PRODUCT_NAME;
    this.headerOptions.allowNoAccessOrganizationSwitch = true;
    this.headerOptions.disableSupportFeedback = true;
    this.headerOptions.enableOneVMwareSupportExperience = false;
    this.headerOptions.showNotificationsMenu = false;
    this.headerOptions.theme = CspHeaderTheme.LIGHT;
    this.headerOptions.showHeaderBeforeFullyInitialized = true;
    

    if (this.headerOptions) {
      switch (this.environment.cspEnvironment) {
        case 'prd': {
          this.headerOptions.feedbackConfig = {
            environment: 'https://feedback.esp.vmware.com',
            lumosEnvironment: 'https://lumos.vmware.com',
            clientID: 'esp-prod-954-ftbwh',
            clientName: 'Keswick'
          };
          break;
        }
        default: {
          this.headerOptions.feedbackConfig = {
            environment: 'https://feedback.esp-staging.vmware-aws.com',
            lumosEnvironment: 'https://lumos.esp-staging.vmware-aws.com',
            clientID: 'esp-stg-954-gmcsk',
            clientName: 'Keswick'
          };
          break;
        }
      }
      this.headerOptions.proactiveFeedbackForm = 'overall-feedback';
    }
      */

    // start idle watch for 30 minutes
    this.idleDetectionService.watch(ACCESS_TOKEN_TTL, ACCESS_TOKEN_EXPIRY_WARN_DELAY);
    // show warning modal to user if no activity is detected for 30 minutes or more
    this.idleDetectionService.isExpired$.subscribe(() => (this.showSessionExpiredModal = true));

    // this.triggerApiService();

    this.subs = this.navigationService
      .getNavigationRequest()
      .subscribe((path: string) => {
        const adjustedPath = getAdjustedRoute(this.router, path);
        this.router.navigate([adjustedPath]);
      });
  }

  ngAfterViewInit(): void {
    // const clrBranding = this.cspHeader.nativeElement.querySelector('.branding') as HTMLElement;
    // if (!clrBranding) {
    //   console.error('Failed to find branding in header');

    //   return;
    // }
    // const dropdownContainer = this.renderer.createElement('div');
    // this.renderer.setAttribute(dropdownContainer, 'id', 'header-items-container');
    // this.renderer.setAttribute(dropdownContainer, 'class', 'header-items-container');
    // this.renderer.insertBefore(clrBranding.parentNode, dropdownContainer, clrBranding.nextSibling);
    // this.dynamicCompService.createComponent(HeaderItemsComponent, dropdownContainer, this.viewContainerRef.injector);
  }

  openSupportForm() {
    //window.open('https://support.broadcom.com/', '_blank', 'noopener');
  }

  async triggerApiService() {
    /*const cspInitConfig: CspInitializationConfiguration = {
      localizationEnabled: true,
      authToken: this.authToken,
      serviceDefinitionId: this.serviceId
    };*/
    // const result: CspInitializationResult = await firstValueFrom(this.cspApiService.initialize(cspInitConfig));
    // const organization = result.userOrganization;

    // const userresult = await firstValueFrom(this.cspApiService.fetchUserProfile());

    // if (organization == null) {
    //   this.onboardingService.redirectToOnboarding();
    // }

    // Lumos.loginLumos(userresult.userId, organization.displayName);
    // Lumos.initLumos(this.environment.cspEnvironment, this.environment.lumosUrl);
  }

  redirect(navItem: NavItem): void {
    const routeSegment: NavigationState = navItem.id as NavigationState;

    this.router.navigate([`../${this.stateToRoute[routeSegment]}`], {
       relativeTo: this.relativeRoute
    });
  }

  setSelectedTab(): void {
    this.relativeRoute = getLastRouteWithSidenavConfig(this.activatedRoute);

    const sidenavState = this.relativeRoute.snapshot.data['navigationState'];
    const tabs = this.enterpriseHorizontalTabs

    // Filter out the enterprise configure tab from here if vco-wide flag is OFF and
    // Show horizontal tab only in case when at least one sidenav menu item is visible
    const navStates = tabs.filter((tab) => {
       const sidenavLinks = getSidenavLinks(          
          tab,
       );
       return sidenavLinks;
    });
    //this.navStates = navStates;
    if (!_isEqual(navStates, this.navStates)) {
      this.navStates = navStates;

      this.horizontalTabs = this.navStates.map(navState => ({
         id: navState,
         name: this.l10service.getMessage(`ecs.header.horizontalTabs.${navState}`)
      }));
   }
    if (this.navStates.includes(sidenavState)) {
      this.selectedHorizontalTabId = sidenavState as string;
    } else {
      // What is to be selected here?
      this.selectedHorizontalTabId = '';
    }
 }

// TODO: This can be enabled during theme switcher button implementation
// onThemeChange(event: any): void {
//   this.selectedThemeStyles = (this.themeService.theme === VmwClarityTheme.Light) ? 'ecs-light' : null;
// }

  private setFocus(): void {
    this.contentArea.nativeElement.focus();
  }
}
