import { Component, Input } from '@angular/core';
import { HostDetailsResp, HostRegDetails } from '@ecs/ecs-api';

@Component({
  selector: 'app-host-hardware-detail',
  templateUrl: './host-hardware-detail.component.html',
  styleUrls: ['./host-hardware-detail.component.scss']
})
export class HostHardwareDetailComponent {
  @Input() hostData: HostDetailsResp;

  get isRegistrationTypeHardwareInfo(): boolean {
    return this.hostData.host_summary.host_registration_details.host_registration_type === HostRegDetails.HostRegistrationTypeEnum.HardwareInfo
  }
}
