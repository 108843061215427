import { Component } from '@angular/core';

//import { CspRobot } from '@vmw/csp-ngx-components';

@Component({
  templateUrl: 'request-role-error.component.html'
})
export class RequestRoleErrorComponent {
  //readonly robot: CspRobot = CspRobot.Forbidden;
}
