import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface PageHeaderBreadcrumb {
  label: string;
  url?: string;
  isExternal?: boolean;
}

@Component({
  selector: 'ecs-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
  @Input() breadcrumbs: PageHeaderBreadcrumb[] = [];
  @Input() pageTitle = '';

}
