import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private navigateSubject: Subject<string> = new Subject<string>();

  sendNavigationRequest(path: string): void {
    this.navigateSubject.next(path);
  }

  getNavigationRequest(): Observable<string> {
    return this.navigateSubject.asObservable();
  }
}
