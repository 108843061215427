import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Configuration } from './configuration';
import { ApiModule } from './api.module';
import { HttpClientModule } from '@angular/common/http';


export function initApiModuleFactory() {
  return new Configuration({
    basePath: '/api/ecs/v0'
  });
}

@NgModule({
  imports: [CommonModule, ApiModule.forRoot(initApiModuleFactory), HttpClientModule],
  exports: [ApiModule, HttpClientModule]
})
export class EcsApiModule {}
