import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, Subject, catchError, ReplaySubject, BehaviorSubject, map, switchMap, tap, finalize } from 'rxjs';
import { HostDetailsResp, HostResp, HostRegistrationStatus, HostsService as HostsApiService, SortOrder, Status, UpdateHost, GetHostsResp, HostRegDetails } from '@ecs/ecs-api';

import {
  AddSupportedServerInfo,
  ESortOrder,
  GroupInfo,
  GroupsResp,
  IHost,
  IHostData,
  PaginatedHostsResponse,
  SupportedServersResp,
  UpdateGroupInfo,
  AlertType,
  IHostDetails,
  KeswickAlertService,
  OrgService,
  HOST_DEFAULT_FILTERS,
} from '@ecs/ecs-platform';

import { OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { AbstractPaginatableListService, handleError } from '@ecs/ecs-common';
import { L10nService } from '@vmw/ngx-vip';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Params } from '@angular/router';
import * as _ from 'lodash';

interface IAddSingleHostRes {
  host_registration_details: IHost;
  unique_identifier: string;
}

interface HostRegistrationStatus2 {
  status: string;
}

class CustomEventEmitter extends EventEmitter<[string, Event]> { }

interface IAddMultipleHostRes {
  registered_hosts: Array<{
    host_response: {
      host_registration_details?: IHost;
      unique_identifier?: string;
    };
    registration_error: {
      message?: string;
    };
  }>;
  status: HostRegistrationStatus2;
}
@Injectable({
  providedIn: 'root'
})
export class HostsService extends AbstractPaginatableListService<HostResp> {
  private readonly url = '/api/ecs/v0/orgs';
  private readonly hostDetailsSource = new BehaviorSubject<HostDetailsResp | null>(null);
  private selectedSource = new BehaviorSubject<Array<HostResp>>([]);

  readonly dataSource$ = this.dataSource.asObservable();
  readonly selected$ = this.selectedSource.asObservable();

  private selectedHosts: Array<string>;
  modalRef: VmwNgxModalRef<OkCancel>;
  private inactiveHostsPresent = false;
  private activeHostsPresent = false;
  orgID: string;
  addToGroupModalRef: VmwNgxModalRef<OkCancel>;
  allHostsList = new Subject<GetHostsResp>();
  activeHostList = new ReplaySubject<GetHostsResp>(1);
  inActiveHostList = new ReplaySubject<GetHostsResp>(1);

  hostDetails$ = this.hostDetailsSource.asObservable();

  // Filters for host datagrid
  public hostNameInput: string;
  public serialNumberFilterInput: string;
  public modelFilterInput: string;
  public vendorFilterInput: string;
  public hostHealthStatus: HostRegistrationStatus[];
  public usernameFilterInput: string;
  public groupLabelFilterInput: string;
  // Filter for host_health
  public hostStatusPending: string;
  public hostStatusActive: string;
  public urlFilterInput: string;
  public gitConfigSourceInput: string;
  // Filter for last_seen
  public filterStartDate: string;
  public filterEndDate: string;
  hostHealthArr: HostRegistrationStatus[];
  public filterInputChanged = new EventEmitter<[string, string | string[]]>();
  public lastSeenStartDateChanged = new EventEmitter();
  public lastSeenEndDateChanged = new EventEmitter();
  public defaultSelectValue = HOST_DEFAULT_FILTERS;
  public filterValues: Map<string, string> = new Map<string, string>();

  constructor(private http: HttpClient, private orgService: OrgService, private hostsApi: HostsApiService, private alertService: KeswickAlertService, private l10nService: L10nService) {
    super();
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  fetchData(pagination: ClrDatagridStateInterface<HostResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      // const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      return this.hostsApi.listHosts(orgId, current, size, null, sortOrder, undefined, this.hostHealthArr, Object.fromEntries(this.filterValues));
    }), map(hosts => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: hosts.page,
          size: hosts.per_page,
          from: hosts.per_page * (hosts.page - 1) + 1,
          to: hosts.per_page * hosts.page
        }
      });
      this.dataSource.next(hosts.results);
      this.totalItemsSource.next(hosts.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
      finalize(() => {
        this.loadingSource.next(false);
      }));
  }

  getHosts(
    hostStatus?: Array<HostRegistrationStatus>,
    page = 1,
    perPage = 20,
    sortColumn = 'id',
    sortOrder = ESortOrder.ASCENDING,
    filterValues = new Map<string, string>()
  ) {
    hostStatus = hostStatus && hostStatus.length > 0 ? hostStatus : undefined;
    return this.orgService.orgId$.pipe(switchMap((orgID) => {
      return this.hostsApi.listHosts(
        orgID,
        page,
        perPage,
        sortColumn as any,
        sortOrder,
        undefined,
        hostStatus,
        Object.fromEntries(filterValues)
      );
    })).subscribe((hostsResp) => {
      this.setHostResults(hostsResp)
      this.activeHostsPresent = (hostsResp?.results) ? true : false;
      this.activeHostList.next({
        results: hostsResp.results,
        total: hostsResp.total,
      });
    });
  }

  setHostResults(hostsResp: GetHostsResp) {
    this.dataSource.next(hostsResp.results ?? []);
    this.totalItemsSource.next(hostsResp.total ?? 0);
  }

  setQueryParams(params: Params, hostHealthArr: HostRegistrationStatus[]) {
    if (!_.isEmpty(params)) {
      Object.keys(params).forEach((key: string) => {
        this.onFilterValueChange(key, params[key as keyof typeof params], hostHealthArr)
      })
    } else {
      this.resetFilterValues();
    }
  }
  onFilterValueChange(filterColumn: string, filterValue: string, hostHealthArr?: HostRegistrationStatus[]) {
    switch (filterColumn) {
      case 'host_serial_number': {
        this.serialNumberFilterInput = filterValue;
        break;
      }
      case 'name': {
        this.hostNameInput = filterValue;
        break;
      }
      case 'host_model_identifier': {
        this.modelFilterInput = filterValue;
        break;
      }
      case 'host_registration_status': {
        this.hostHealthStatus = hostHealthArr;
        break;
      }
      case 'host_vendor_identifier': {
        this.vendorFilterInput = filterValue;
        break;
      }
      case 'git_config_source': {
        this.gitConfigSourceInput = filterValue;
        break;
      }
      case 'url': {
        this.urlFilterInput = filterValue;
        break;
      }
      case 'created_by': {
        this.usernameFilterInput = filterValue;
        break;
      }
      case 'desired_group_label': {
        this.groupLabelFilterInput = filterValue;
        break;
      }
      case 'last_seen_range_start': {
        this.filterStartDate = filterValue;
        break;
      }
      case 'last_seen_range_end': {
        this.filterEndDate = filterValue;
        break;
      }
      default: {
        console.log('Unsupported filterColumn received');
      }
    }
  }

  resetFilterValues() {
    this.serialNumberFilterInput = '';
    this.hostNameInput = '';
    this.modelFilterInput = '';
    this.vendorFilterInput = '';
    this.hostStatusActive = '';
    this.hostStatusPending = '';
    this.gitConfigSourceInput = '';
    this.urlFilterInput = '';
    this.filterStartDate = this.filterEndDate = '';
    this.usernameFilterInput = '';
    this.groupLabelFilterInput = '';
  }

  activateHost(hostId: string) {
    return this.hostsApi.activateHost(hostId, this.orgID)
  }

  addHostBySerialNumber(hostData: IHost): Observable<IAddSingleHostRes> {
    return this.http.post<IAddSingleHostRes>(this.getHostsUrl(), hostData, {});
  }

  addHostByCSVFile(file: File, siteId: string): Observable<IAddMultipleHostRes> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('site_id', siteId);

    return this.http.post<IAddMultipleHostRes>(`${this.getHostsUrl()}/batch-register`, formData);
  }

  fetchHost(hostId: string): Observable<IHostDetails> {
    const url = `${this.getHostsUrl()}/${hostId}`;
    return this.http.get<IHostDetails>(url);
  }

  fetchHostDetails(hostId: string) {
    this.hostsApi.getHostDetails(hostId, this.orgID).subscribe((hostDetailsResp: HostDetailsResp) => {
      this.hostDetailsSource.next(hostDetailsResp);
    });
  }

  enableConfirm(): void {
    this.modalRef.buttons.submitButton.disabled = false;
  }

  disableConfirm(): void {
    this.modalRef.buttons.submitButton.disabled = true;
  }

  getSupportedVendors(): Observable<SupportedServersResp> {
    const url = `${this.orgService.getVcoEnterpriseOrgBaseUrl()}/vendors`;

    return this.http.get<SupportedServersResp>(url, {});
  }

  getSupportedModels(hostVendor: any): Observable<SupportedServersResp> {
    const url = `${this.orgService.getVcoEnterpriseOrgBaseUrl()}/models`;
    const params = new HttpParams().set('vendor', hostVendor);

    return this.http.get<SupportedServersResp>(url, { params });
  }

  addSupportedServer(serverDetails: AddSupportedServerInfo): Observable<any> {
    const url = `${this.orgService.getVcoEnterpriseOrgBaseUrl()}/servers`;
    return this.http.post<AddSupportedServerInfo>(url, JSON.stringify(serverDetails), this.httpOptions).pipe(catchError(handleError));
  }

  deregisterHost(hostId: string): Observable<any> {
    return this.http.post(`${this.getHostsUrl()}/${hostId}/deregister`, {}).pipe(catchError(handleError));
  }

  setSelectedHosts(hosts: Array<IHostData>) {
    this.selectedHosts = [];
    hosts.forEach((value, key) => {
      this.selectedHosts.push(value.unique_identifier);
    });
  }

  getSelectedHosts() {
    return this.selectedHosts;
  }

  getGroupLabels() {
    const url = this.getGroupsUrl();

    return this.http.get<GroupsResp>(url);
  }

  enableAddToGroupConfirm(): void {
    this.addToGroupModalRef.buttons.submitButton.disabled = false;
  }

  disableAddToGroupConfirm(): void {
    this.addToGroupModalRef.buttons.submitButton.disabled = true;
  }

  createHostsGroup(groupInfo: GroupInfo) {
    const url = this.getGroupsUrl();
    return this.http.post<GroupInfo>(url, JSON.stringify(groupInfo), this.httpOptions).pipe(catchError(handleError));
  }

  updateHostsGroup(updateGroupInfo: UpdateGroupInfo) {
    const url = `${this.getGroupsUrl()}/${updateGroupInfo.id}`;
    return this.http.put<UpdateGroupInfo>(url, JSON.stringify(updateGroupInfo), this.httpOptions).pipe(catchError(handleError));
  }

  HostsRegistered(): boolean {
    return this.activeHostsPresent || this.inactiveHostsPresent;
  }

  generateLogBundle(hostId: string): Observable<any> {
    return this.http.post(`${this.getHostsUrl()}/${hostId}/log`, {}).pipe(catchError(handleError));
  }

  fetchLogBundle(hostId: string, token: string): Observable<any> {
    return this.http.get(`${this.getHostsUrl()}/${hostId}/log?token=${token}`);
  }

  deleteHost(hostId: string): Observable<any> {
    const url = `${this.getHostsUrl()}/${hostId}`;
    return this.http.delete<any>(url);
  }

  rebootHost(hostId: string): Observable<any> {
    return this.hostsApi.rebootHost(hostId, this.orgID);
  }

  checkDuplicateHost(model?: string, vendor?: string, serialNumber?: string, activationKey?: string, registrationType?: HostRegDetails.HostRegistrationTypeEnum, name?: string): Observable<boolean> {
    return this.hostsApi.checkDuplicateHost(this.orgID, model, vendor, serialNumber, registrationType, activationKey, name)
  }

  updateHost(host: string, updateHost: UpdateHost): Observable<any> {
    return this.hostsApi.updateHost(host, this.orgID, updateHost)
  }

  setSelected(selection: Array<any>): void {
    this.selectedSource.next(selection);
  }

  private getUrl(subPath: string): string {
    const prefixUrl = this.orgService.getVcoEnterpriseOrgBaseUrl();
    return `${prefixUrl}/${subPath}`;
  }

  private getHostsUrl(): string {
    return `${this.getUrl('hosts')}`;
  }

  private getGroupsUrl(): string {
    return `${this.getUrl('groups')}`;
  }

}
