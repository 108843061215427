import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingHelperService {
  constructor(private router: Router) {}

  reloadCurrentRoute(skipLocationChange = true) {
    const currentRoute = this.router.routerState.snapshot.url;

    this.router.navigateByUrl('/', { skipLocationChange }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }
}
