import { HttpErrorResponse } from '@angular/common/http';
import { Label } from '@ecs/ecs-api';
import { throwError } from 'rxjs';

export const handleError = (err: HttpErrorResponse) => {
  let errorMessage = '';
  if (err.error instanceof ErrorEvent) {
    errorMessage = `An error occurred: ${err.error.message}`;
  } else {
    errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
  }
  console.error(errorMessage);
  return throwError(errorMessage);
};

export const getTimeStamp = (date: Date) => {
  return new Date(date).getTime();
};

export const findChangedValue = (sourceObj: { [key: string]: any }, destObj: { [key: string]: any }) => {
  return Object.keys(destObj)
    .filter((key) => !sourceObj.hasOwnProperty(key) || JSON.stringify(destObj[key]) !== JSON.stringify(sourceObj[key]))
    .reduce((acc, key) => {
      acc[key] = destObj[key];
      return acc;
    }, {} as Record<string, any>);
}


export const convertLabelsToUILabels = (labels: Label[]): string[] => {
  return labels.map((label) => label.label_key + ":" + label.label_value)
}