import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { CspEnvironment } from '@vmw/csp-common';
//import { CspApiService } from '@vmw/csp-ngx-components';
// import { OAuth2Client } from '@vmw/csp-oauth2';
import { CspServiceSdkToolkit } from '@vmw/csp-service-sdk';
//import { OAuth2ModuleConfiguration } from '@vmw/ngx-csp-oauth2';
import { VmwClarityTheme, VmwClarityThemeService } from '@vmw/ngx-utils';

import { OrgService } from './cross-org-selector/org-service.service';
import { APP_ENVIRONMENT, CceUiEnvironment } from './environment.model';

const CSP_CODE_QUERYPARAM = 'code';
const CSP_STATE_QUERYPARAM = 'state';

export interface EarlyPreloadResult {
  cspHost: string;
}

export function earlyPreloadFactory(earlyPreloadService: EarlyPreloadService) {
  return () => {
    return earlyPreloadService
      .initialize()
      .then()
      .catch((e) => {
        console.error(e);
        return new Promise((resolve) => resolve(0));
      });
  };
}

function removeQueryParam(name: string) {
  let url = window.location.href;
  url = url.replace(new RegExp('[?&]' + name + '=[^&#]*(#.*)?$'), '$1').replace(new RegExp('([?&])' + name + '=[^&]*&'), '$1');
  history.pushState('', '', url);
}

@Injectable({
  providedIn: 'root'
})
export class EarlyPreloadService extends Subject<EarlyPreloadResult> {
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: CceUiEnvironment,
    // private cspServiceSdk: CspServiceSdkToolkit,
    // private oauth2Client: OAuth2Client,
    private router: Router,
    //private cspApiService: CspApiService,
    private themeService: VmwClarityThemeService,
    private orgService: OrgService
  ) {
    super();
  }

  async initialize() {
    // If we're running in dev or stg, we will allow messages from any origin
    // const allowCspLocalDev =
    //   this.cspServiceSdk.isEmbedded &&
    //   window.parent.location.origin.indexOf('localhost') !== -1 &&
    //   ['dev', 'stg'].indexOf(this.environment.cspEnvironment) !== -1;

    const config = {
      environment: this.environment.cspEnvironment as CspEnvironment,
      serviceDefinitionId: this.environment.cspServiceDefinitionId
    };

    // With CSP UI running locally we need to specify the environment as the URL where it is running.
    // const configureResult = await this.cspServiceSdk.configure(config);

    // Embedded pathway does not redirect for login and instead asks for tokens from host
    // Check if we have just come out of an auth code flow
    // if (this.oauth2Client.hasAuthorizationResponse()) {
    //   const state = await this.oauth2Client.validateAuthorizeResponse();
    //   removeQueryParam(CSP_CODE_QUERYPARAM);
    //   removeQueryParam(CSP_STATE_QUERYPARAM);
    // }


    // store org details in local storage for further usage
    // localStorage.setItem('currentOrgId', orgId);

    await this.themeService.initialize();

    // this.cspServiceSdk.attachThemeChangeListener((theme: VmwClarityTheme) => {
    //   this.themeService.theme = theme ?? VmwClarityTheme.Light;
    // });
    this.themeService.theme = VmwClarityTheme.Light;

    const result: EarlyPreloadResult = {
      cspHost: ""
    };

    this.next(result);
  }
}

export const EARLY_PRELOAD_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [EarlyPreloadService, APP_ENVIRONMENT, VmwClarityThemeService, OrgService],
  useFactory: earlyPreloadFactory
};
