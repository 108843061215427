import { EnterpriseRoutes } from '../../constants/app-routing.constants';
import { NavigationState } from '../../utils/routing/routing.utils';

export interface NavSection {
   sectionName: string;
   links: NavLink[];
 }

export interface NavLink
{
   name: string; // key of the resource string to be translated
   path: any[];
   selected?: boolean;
   icon?: string;
   isExternal?: boolean;
   visible?: boolean;
   canShow?: (
      customerId?: number,
      roleName?: string,
   ) => boolean;
}

export type SidenavTabs = {[key in NavigationState]?: NavSection[]};

export enum NavSections {
   manage = 'manage',
   monitor = 'monitor'
}

export const enterpriseLinks: SidenavTabs = {
   [NavigationState.ENTERPRISE_MONITOR]: [
      {
         sectionName: 'ecs.sidenav.section.monitor',
         links: [
            {
               name: 'ecs.sidenav.section.link.hosts',
               path: [EnterpriseRoutes.inventory],
               icon: 'host',
            },
            {
               name: 'ecs.sidenav.section.link.alerts',
               path: [EnterpriseRoutes.alerts],
               icon: 'blocks-group',
            },
            {
               name: 'ecs.sidenav.section.link.events',
               path: [EnterpriseRoutes.events],
               icon: 'organization',
            }
         ]
      }
   ],
   [NavigationState.ENTERPRISE_CONFIGURE]: [
      {
         sectionName: 'ecs.sidenav.section.configure',
         links: [
            {
               name: 'ecs.sidenav.section.link.launchpad',
               path: [EnterpriseRoutes.launchpad],
               icon: 'launchpad',
            },
            {
               name: 'ecs.sidenav.section.link.sites',
               path: [EnterpriseRoutes.sites],
               icon: 'building',
            },
            {
               name: 'ecs.sidenav.section.link.gitrepositories',
               path: [EnterpriseRoutes.gitrepos],
               icon: 'ci-cd',
            },
            {
               name: 'ecs.sidenav.section.link.hosts',
               path: [EnterpriseRoutes.inventory],
               icon: 'host',
            },
            {
               name: 'ecs.sidenav.section.link.groups',
               path: [EnterpriseRoutes.groups],
               icon: 'event',
            },
         ]
      },
   ],
};

export function getSidenavLinks(
   navigationState: NavigationState,
): NavSection[] {
   const sections = enterpriseLinks[navigationState];
   
   if(sections && sections.length > 0) {

      return sections.filter(section => section.links.length > 0);
   } else {
      return [];
   }

}
