export const CommonAppRoutes = {
   operator: 'operator',
   customers: 'customers',
   msp: 'msp',
   ecs: 'ecs'
 };
 
export const CommonRouteParams = {
   customerId: 'customerId',
   mspId: 'mspId'
 };

export enum AppRoutes {
   monitor = 'monitor',
   configure = 'configure',
}

export enum EnterpriseRoutes {
   alerts = 'alerts',
   events = 'events',
   gitrepositories = 'gitrepositories',
   gitrepos = 'gitrepos',
   gitreposlist = 'gitreposlist',
   groups = 'groups',
   hosts = 'hosts',
   launchpad = 'launchpad',
   inventory = 'inventory',
   operations = 'operations',
   addHost = 'hosts/add',
   hostDetails = 'host-details',
   sites = 'sites',
   siteDetails = 'sites/:siteId',
   newSite = 'sites/new',
}

export const CONFIGURE_HOSTS_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.inventory}/${EnterpriseRoutes.hosts}`;
export const MONITOR_HOSTS_ROUTE = `/${AppRoutes.monitor}/${EnterpriseRoutes.inventory}/${EnterpriseRoutes.hosts}`;
export const CONFIGURE_ADD_HOST_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.inventory}/${EnterpriseRoutes.addHost}`;
export const CONFIGURE_HOSTS_DETAILS_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.inventory}/${EnterpriseRoutes.hostDetails}`;
export const MONITOR_HOSTS_DETAILS_ROUTE = `/${AppRoutes.monitor}/${EnterpriseRoutes.inventory}/${EnterpriseRoutes.hostDetails}`;
export const MONITOR_ALERTS_ROUTE = `/${AppRoutes.monitor}/${EnterpriseRoutes.alerts}`;
export const MONITOR_EVENTS_ROUTE = `/${AppRoutes.monitor}/${EnterpriseRoutes.events}`;
export const CONFIGURE_SITES_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.sites}`;
export const CONFIGURE_ADD_SITE_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.newSite}`;
export const CONFIGURE_SITE_DETAILS_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.siteDetails}`;
export const CONFIGURE_LAUNCHPAD_ROUTE = `/${AppRoutes.configure}/${EnterpriseRoutes.launchpad}`;
