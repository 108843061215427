export const ENG_ECS_ADD_TO_GROUP_MODAL_TRANSLATIONS = {
  'ecs.addtogroup.title': 'Select from existing groups, or provide a new key:value pair to define a new Host Group.',
  'ecs.addtogroup.hostselected': 'Hosts Selected',
  'ecs.addtogroup.groupdescription': 'Group Description',
  'ecs.addtogroup.groupdescription.placeholder': 'Input group description',
  'ecs.addtogroup.grouplabel': 'Group Label',
  'ecs.addtogroup.grouplabel.tooltip': 'This key value pair will be applied to the host as a kubernetes label.',
  'ecs.keyvalue': 'Key:Value',
  'ecs.addtogroup.grouplabel.helper': 'Add new or select from existing values',
  'ecs.addtogroup.grouplabel.key': 'Group Label Key',
  'ecs.addtogroup.grouplabel.key.tooltip': 'This is the key of the label that will be applied to the host as a kubernetes label.',
  'ecs.addtogroup.grouplabel.key.placeholder': 'Input group label key',
  'ecs.addtogroup.grouplabel.value': 'Group Label Value',
  'ecs.addtogroup.grouplabel.value.tooltip': 'This is the value of the label that will be applied to the host as a kubernetes label.',
  'ecs.addtogroup.grouplabel.value.placeholder': 'Input group label value'
};
