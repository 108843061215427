<ecs-page-header [breadcrumbs]="breadcrumbs" [pageTitle]="pageLabel$ | async">
    <ng-container *ngIf="form">
        <form clrStepper [formGroup]="form" [clrInitialStep]="currentStep" clrForm clrLayout="horizontal"
            [clrLabelSize]="2">
            <clr-stepper-panel formGroupName="siteDetails">
                <clr-step-title>{{ 'ecs.sites.manageSite.step.siteDetails.title' | vtranslate }}</clr-step-title>
                <clr-step-description>
                    <span>{{ 'ecs.sites.manageSite.step.siteDetails.description' | vtranslate}}</span>
                    <span *ngIf="siteName" class="label label-success">{{ siteName }}</span>
                    <span *ngIf="!siteName" class="label">{{
                        'ecs.sites.manageSite.step.siteDetails.status.empty' | vtranslate }}</span>
                </clr-step-description>
                <clr-step-content *clrIfExpanded class="clr-row">
                    <div class="clr-col-sm-12 clr-col-md-10 clr-col-lg-8">
                        <clr-input-container ecsFormControlContainer>
                            <label class="required">{{ 'ecs.sites.manageSite.form.name.label' | vtranslate }}</label>
                            <input clrInput class="input-size-xl" focusVisible type="text" name="name"
                                formControlName="name">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.name.helpText' |
                                vtranslate:[SITE_NAME_MAX_STRING_LENGTH] }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.description.label' | vtranslate }}</label>
                            <input clrInput class="input-size-xl" name="description" formControlName="description">
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <!-- Location input container -->
                        <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.location.label' | vtranslate }}</label>
                            <input clrInput type="text" class="input-size-xl" name="location"
                                formControlName="location">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.location.helpText' |
                                vtranslate:[MAX_STRING_LENGTH] }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <button class="clr-step-button btn btn-outline" (click)="navigateToGitDetails()" [disabled]="form.get('siteDetails').invalid">{{
                            'common.labels.next' | vtranslate }}</button>
                    </div>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="gitDetails">
                <clr-step-title>{{ 'ecs.sites.manageSite.step.gitDetails.title' | vtranslate }}</clr-step-title>
                <clr-step-description>
                    <span>{{ 'ecs.sites.manageSite.step.gitDetails.description' | vtranslate}}</span>
                    <span *ngIf="hasGitRepoAssociated" class="label label-success">{{
                        'ecs.sites.manageSite.step.gitDetails.status.associated' | vtranslate }}</span>
                    <span *ngIf="!hasGitRepoAssociated" class="label">{{
                        'ecs.sites.manageSite.step.gitDetails.status.notAssociated' | vtranslate }}</span>
                </clr-step-description>
                <clr-step-content *clrIfExpanded>
                    <div class="clr-col-sm-12 clr-col-md-10 clr-col-lg-8">
                        <div>Select the Git Repository and path to the desired state files to be associated with this
                            site.</div>
                        <clr-select-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.gitRepo.label' | vtranslate }}</label>
                            <select class="input-size-xl" clrSelect formControlName="git_repo_id" name="git_repo_id">
                                <option *ngFor="let repo of gitRepos" [value]="repo?.id">{{ repo?.url }}</option>
                            </select>
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepo.helpText' | vtranslate
                                }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-select-container>

                        <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.gitRepoBranch.label' | vtranslate
                                }}</label>
                            <input clrInput class="input-size-xl" type="text" name="git_repo_branch"
                                formControlName="git_repo_branch">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepoBranch.helpText' | vtranslate
                                }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.gitRepoPath.label' | vtranslate
                                }}</label>
                            <input clrInput class="input-size-xl" type="text" name="git_repo_path"
                                formControlName="git_repo_path">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepoPath.helpText' | vtranslate
                                }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.gitRepoUsername.label' | vtranslate
                                }}</label>
                            <input clrInput class="input-size-xl" type="text" name="git_repo_username"
                                formControlName="git_repo_username">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepoUsername.helpText' | vtranslate
                                }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container>
                        <!-- <clr-input-container ecsFormControlContainer>
                            <label>{{ 'ecs.sites.manageSite.form.gitRepoAccessToken.label' | vtranslate
                                }}</label>
                            <input clrInput class="input-size-xl" type="password" name="git_repo_access_token"
                                formControlName="git_repo_access_token">
                            <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepoAccessToken.helpText' | vtranslate
                                }}</clr-control-helper>
                            <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                        </clr-input-container> -->
                       
                        <div class="password-container" *ngIf="mode==='create' || showToken">
                            <clr-password-container ecsFormControlContainer >
                                <label>{{ 'ecs.sites.manageSite.form.gitRepoAccessToken.label' | vtranslate
                                    }}</label>

                                <input clrPassword class="input-size-xl clr-password" name="git_repo_access_token"
                                    formControlName="git_repo_access_token">

                                <clr-control-helper>{{ 'ecs.sites.manageSite.form.gitRepoAccessToken.helpText' | vtranslate
                                    }}</clr-control-helper>
                                <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
                            </clr-password-container>
                            <button *ngIf="mode==='edit'" (click)="cancelUpdateToken()" type="button" class="btn btn-sm btn-secondary btn-link btn-refresh">
                                <cds-icon shape="undo" class="icon"></cds-icon>
                            </button>
                        </div>
                        <div class="clr-form-control  clr-row" *ngIf="mode==='edit' && !showToken">
                            <label class="clr-control-label">{{ 'ecs.sites.manageSite.form.gitRepoAccessToken.label' | vtranslate
                                }}</label>
                            <div class="clr-control-container input-size-xl clr-col-md-10 clr-col-12">
                                <div class="clr-input-wrapper">
                                    <button class="btn btn-xl btn-success-outline" (click)="updateToken()">{{ 'ecs.sites.manageSite.form.updateAccessToken.label' | vtranslate
                                    }}</button>                        
                                    <cds-icon *ngIf="tokenUpdateSuccess" status="success" shape="check-circle" size="25" class="icon"></cds-icon>
                                </div>
                            </div>
                        </div>

                        <button (click)="updateTokenSuccess()" clrStepButton="next" [disabled]="form.get('gitDetails').invalid">{{ 'common.labels.next'
                            | vtranslate }}</button>
                    </div>
                </clr-step-content>
            </clr-stepper-panel>

            <clr-stepper-panel formGroupName="tagsDetails">
                <clr-step-title>{{ 'ecs.sites.manageSite.step.labels.title' | vtranslate }}</clr-step-title>
                <clr-step-description>{{ 'ecs.sites.manageSite.step.labels.description' | vtranslate
                    }}</clr-step-description>
                <clr-step-content *clrIfExpanded>
                    <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-6">
                        <ecs-tags-editor formControlName="tags"></ecs-tags-editor>
                    </div>
                </clr-step-content>
            </clr-stepper-panel>
        </form>

        <clr-checkbox-container *ngIf="mode==='create'">
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox value="addAnotherSite" [(ngModel)]="addAnotherSite"
                    name="addAnotherSite" />
                <label>{{ 'ecs.sites.manageSite.buttons.addAnotherSite' | vtranslate }}</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <div class="actions-button-bar">
            <button type="submit" class="btn btn-primary"
                [disabled]="form.invalid || (loading$ | async) || form.pristine" (click)="saveChanges()">{{
                    (mode==='create' ? 'ecs.sites.manageSite.buttons.addNew' :'ecs.sites.editSite') | vtranslate }}</button>
            <button type="button" class="btn btn-link" (click)="cancel()">{{ 'common.labels.cancel' | vtranslate
                }}</button>
        </div>
    </ng-container>
    <ecs-spinner *ngIf="loading$ | async"></ecs-spinner>

</ecs-page-header>