<div class="hardware-detail">

  <ng-container *ngIf="isRegistrationTypeHardwareInfo;else activationKeyTemplate">
    <div class="clr-row detail-item">
      <div class="clr-col-4 detail-label">{{ 'ecs.hardware.vendor' | vtranslate }}</div>
      <div class="clr-col-8">{{ hostData?.host_summary?.host_registration_details?.host_vendor_identifier }}</div>
    </div>
    <div class="clr-row detail-item">
      <div class="clr-col-4 detail-label">{{ 'ecs.hardware.model' | vtranslate }}</div>
      <div class="clr-col-8">{{ hostData?.host_summary?.host_registration_details?.host_model_identifier }}</div>
    </div>
    <div class="clr-row detail-item">
      <div class="clr-col-4 detail-label">{{ 'ecs.hardware.serialnumber' | vtranslate }}</div>
      <div class="clr-col-8">{{ hostData?.host_summary?.host_registration_details?.host_serial_number }}</div>
    </div>
  </ng-container>
  
  <div class="clr-row detail-item">
    <div class="clr-col-4 detail-label">{{ 'ecs.hardware.storagecapacity' | vtranslate }}</div>
    <div class="clr-col-8">{{ hostData?.host_summary?.host_storage_capacity | number : '1.0-0' }} GB</div>
  </div>
  <div class="clr-row detail-item">
    <div class="clr-col-4 detail-label">{{ 'ecs.hardware.network' | vtranslate }}</div>
    <div class="clr-col-8">
      <div>{{ hostData.host_summary?.host_network_details?.name }}</div>
      <div>{{ hostData.host_summary?.host_network_details?.ip_address }}</div>
    </div>
  </div>
  <div class="clr-row detail-item">
    <div class="clr-col-4 detail-label">{{ 'ecs.hardware.compatibility' | vtranslate }}</div>
    <div class="clr-col-8">{{ hostData?.host_system_details?.compatibility_version }}</div>
  </div>
</div>

<ng-template #activationKeyTemplate>
  <div class="clr-row detail-item">
    <div class="clr-col-4 detail-label">{{ 'ecs.hardware.activationkey' | vtranslate }}</div>
    <div class="clr-col-8">{{ hostData?.host_summary?.host_registration_details?.host_activation_key }}</div>
  </div>
</ng-template>
