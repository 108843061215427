export const ENG_ECS_GETTING_STARTED_TRANSLATIONS = {
  'ecs.launchpad.gettingtarted.gitrepository.title': 'Git Repository',
  'ecs.launchpad.gettingtarted.gitrepository.description': 'Learn how to get started',
  'ecs.launchpad.gettingtarted.download.title': 'Download the Edge Compute Stack image',
  'ecs.launchpad.gettingtarted.download.description':
    'Download Edge Compute Stack Image for installation. We offer multiple formats for download.',
  'ecs.launchpad.gettingtarted.sitemanagment.title': 'Site management',
  'ecs.launchpad.gettingtarted.sitemanagment.description': `<a class="link" (click)="openAddSiteModal()">Setup a site</a>&nbsp; to enable consistent configuration of edge locations. &nbsp;`,
  'ecs.launchpad.gettingtarted.hostmanagment.title': 'Host management',
  'ecs.launchpad.gettingtarted.exploregroups.title': 'Explore groups and labels post successful host activation',
  'ecs.launchpad.gettingtarted.exploregroups.description':
    'Once you have the host and git repository connected. You can explore other advanced functions to support management at scale.',
  'ecs.launchpad.gettingtarted.gitrepository': `<a class="link">Add a git repository </a>&nbsp;to support host management. Don't have a git repository?&nbsp;`,
  'ecs.launchpad.gettingtarted.hostmanagment.description': `<a class="link" (click)="openAddHostModal()">Setup one or more hosts</a>&nbsp;that you want to manage. &nbsp;`,
  'ecs.launchpad.gettingtarted.hostmanagment.subdescription': `View pre-requisites.`
};
