<p class="groups-para">
  {{ 'ecs.grouplist.title' | vtranslate }}
  <a class="link" [href]="HOST_GROUP_DOC_LINK" target="_blank" rel="noopener noreferrer">{{ 'ecs.learnmore' | vtranslate
    }}</a>
</p>
<ng-container *ngIf="data$ | async  as data">
  <clr-datagrid [(clrDgSelected)]="selectedGroups" [clrDgRowSelection]="true" [clrDgLoading]="loading$ | async"
    (clrDgRefresh)="onDatagridRefresh($event)">
    <clr-dg-action-bar>
      <div class="btn-group">
        <button type="button" class="btn btn-sm btn-secondary btn-link" [disabled]="selectedGroups?.length === 0"
          (click)="openDeleteGroupModal()">
          <cds-icon shape="trash" class="refresh-icon"></cds-icon> {{ 'ecs.grouplist.action.delete' | vtranslate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary btn-link" [disabled]="selectedGroups?.length !== 1"
          (click)="openUpdateGroupModal()">
          <cds-icon shape="pencil" class="refresh-icon"></cds-icon> {{ 'ecs.grouplist.action.edit' | vtranslate }}
        </button>

      </div>
    </clr-dg-action-bar>

    <!-- Columns -->
    <ng-container *ngFor="let column of columns">
      <clr-dg-column *ngIf="column.field !== 'alerts_count'" [clrDgField]="column.field" [clrDgSortBy]="column.field" [clrDgSortOrder]="SortOrder.DESC">
        {{ column.label | vtranslate }}
      </clr-dg-column>
      <clr-dg-column  *ngIf="column.field === 'alerts_count'" >
        {{ column.label | vtranslate }}
      </clr-dg-column>
    </ng-container>

    <clr-dg-row *ngFor="let group of data" [clrDgItem]="group">
      <clr-dg-cell>
        <label class="label label-light-blue">
          {{ group?.label_key }}
          <span class="badge">{{ group?.label_value }}</span>
        </label>
      </clr-dg-cell>
      <clr-dg-cell>{{ group?.description }}</clr-dg-cell>
      <clr-dg-cell>
        <span *ngIf="!group?.member_details?.total_host_count; else hostListLink">{{
          group?.member_details?.total_host_count }}</span>
        <ng-template #hostListLink>
          <a class="link" (click)="redirectToHostsPage(group?.label_key, group?.label_value)">{{
            group?.member_details?.total_host_count }}</a>
        </ng-template>
      </clr-dg-cell>
      <clr-dg-cell>
        <span *ngIf="!group?.alerts_count; else alertsLink">{{ group?.alerts_count }}</span>
        <ng-template #alertsLink>
          <a class="link" (click)="redirectToAlertsPage(group?.label_key, group?.label_value)">{{ group?.alerts_count
            }}</a>
        </ng-template>
      </clr-dg-cell>
    </clr-dg-row>
     
    <!-- Empty Placeholder -->
    <clr-dg-placeholder>
      <div class="empty-placeholder">
        <p class="custom-para">{{ 'ecs.groups.noGroupsTitle' | vtranslate }}</p>
        <a class="link" [href]="ADD_GROUP_DOC_LINK" target="_blank">{{ 'ecs.groups.noGroupsSubtitle' | vtranslate }}</a>
      </div>
    </clr-dg-placeholder>

    <!-- Footer -->
    <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
      <div class="footer-actions align-left">
        <a class="refresh-btn btn btn-link" (click)="onRefresh()">
          <cds-icon shape="refresh" size="14"></cds-icon>
          <span>{{'ecs.refresh' | vtranslate}}</span>
        </a>
      </div>
      <clr-dg-pagination #pagination [clrDgPageSize]="paginationInfo?.page?.size"
        [clrDgPage]="paginationInfo?.page?.current" [clrDgTotalItems]="totalItems$ | async">
        <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"> {{'ecs.grouplist.footer' | vtranslate}}
        </clr-dg-page-size>
        {{'ecs.grouplist.pagination' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1: (totalItems$ |
        async)}}
      </clr-dg-pagination>
      <div></div>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>