export const ENG_ECS_ACTIVATE_HOST_MODAL_TRANSLATIONS = {
  'ecs.activatehostmodal.title': 'Activate Hosts',
  'ecs.activatehostmodal.hostidentifier': 'Host Identifier',
  'ecs.activatehostmodal.connectgitlabel': 'Connect a Git Repository',
  'ecs.addgit.branch.placeholder': 'Add branch associated with the Git',
  'ecs.addgit.username.placeholder': 'Add username associated with the Git',
  'ecs.activateHostModal.gitRepoUrl.noRepos': 'Not Git Repositories found',
  'ecs.activatehostmodal.gitRepoUrl.helpText': 'Example: https://github.com/user/repo.git',
  'ecs.activateHostModal.gitRepoPath.label': 'Path',
  'ecs.activateHostModal.gitRepoPath.placeholder': 'Specify path',
  'ecs.activateHostModal.gitRepoPath.tooltip': 'Specify the directory in Git Repository where desired state manifests are stored',
  'ecs.activateHostModal.gitRepoPath.helpText': 'e.g. /ecs/manifests',
  'ecs.activateHostModal.gitRepo.label': 'Git Repository',
  'ecs.activateHostModal.gitRepoconfigsource.label': 'Git Config Source',
  'ecs.activateHostModal.gitRepoconfigsource.tooltip': `Git Config Source is used to determine whether the host takes inherits git config from the site`,
  'ecs.activateHostModal.button.activate': 'Activate',
  'ecs.activateHostModal.badge.title':'Hosts(s) will be activated',
  'ecs.activateHostModal.body':'The following Hosts will receive the DesiredState configuration from the associated GitRepo after they are activated',
  'ecs.activateHostModal.body.hostswithoutgitconfig':'The following Hosts do not have any Git Repository associated with them. You can try again either by deselecting the following hosts or associating the Git Repository.',
  'ecs.activateHostModal.error': '"{0}" host(s) activated. However, "{1}" host(s) failed to activate.',
  'ecs.host.activation.success': 'The host(s) have been activated successfully',
};
