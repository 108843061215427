export const ENG_ECS_ADD_HOST_MODAL_TRANSLATIONS = {
  'ecs.addhostmodal.title':
    'Add a single Host or import multiple with a CSV file. The information should be an exact match from the deployed host Direct Console User Interface (DCUI) screen.',
  'ecs.addhostmodal.success': 'Host Vendor & Model added successfully',
  'ecs.addhostmodal.hostdetails': 'Host Details',
  'ecs.addhostmodal.activationType': 'Activation Type',
  'ecs.addhostmodal.hostdetails.withHardwareInfo': 'Hardware Information',
  'ecs.addhostmodal.hostdetails.withserialnumber.tooltip': "You can find this information on the Host's DCUI screen.",
  'ecs.addhostmodal.hostdetails.withActivationKey': 'Activation Key',
  'ecs.addhostmodal.hostdetails.withimportcsv.tooltip':
    "Import multiple hosts at once with a CSV file that indicates each host's Vendor, Model, and Serial Number.",
  'ecs.addhostmodal.hostdetails.withBulkUpload': 'Bulk Upload using CSV',
  'ecs.addhostmodal.hostdetails.vendor': 'Vendor',
  'ecs.addhostmodal.hostdetails.vendor.placeholder': 'Select host vendor',
  'ecs.addhostmodal.hostdetails.vendor.addnew': 'Add New Vendor',
  'ecs.addhostmodal.hostdetails.model': 'Model',
  'ecs.addhostmodal.hostdetails.model.placeholder': 'Select host model',
  'ecs.addhostmodal.hostdetails.model.addnew': 'Add New Model',
  'ecs.addhostmodal.hostdetails.serialnumber': 'Serial Number',
  'ecs.addhostmodal.hostdetails.serialnumber.placeholder': 'Input serial number',
  'ecs.addhostmodal.hostdetails.importcsv': 'Add Nodes by importing CSV',
  'ecs.addhostmodal.hostdetails.importcsv.import': 'Import',
  'ecs.addhostmodal.hostdetails.importcsv.replace': 'Replace',
  'ecs.addhostmodal.hostdetails.activationKey': 'Activation Key',
  'ecs.addhostmodal.hostdetails.activationKey.placeholder': 'Input Activation Key',
  'ecs.addhostmodal.hostdetails.name': 'Host Name',
  'ecs.addhostmodal.hostdetails.name.placeholder': 'Input Host Name',
  'ecs.addhostmodal.hostdetails.name.tooltip': 'The Host Name is a user-friendly identifier not to be confused with DNS hostname',
};
