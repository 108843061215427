import { NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router, NavigationStart } from '@angular/router';
import { singleSpaAngular, getSingleSpaExtraProviders } from 'single-spa-angular';
import { AppModule } from './app/app.module';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';
import { APP_ENVIRONMENT } from '@ecs/ecs-platform';

declare global {
  interface Window {
    loadScriptByUrl: (path: string) => void;
  }
}

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);

    return platformBrowserDynamic([
        ...getSingleSpaExtraProviders(),
        {
          provide: APP_ENVIRONMENT,
          useValue: {}
        }
      ]).bootstrapModule(AppModule);
  },
  template: '<ecs-root />',
  Router,
  NavigationStart,
  NgZone,
});

export const {
  bootstrap,
  mount,
  unmount
} = lifecycles;
