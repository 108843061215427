import { Injectable } from '@angular/core';
import { SiteResp } from '@ecs/ecs-api';
import { ClrAlertSeverity, ConfirmationService } from '@ecs/ecs-common';
import { AlertType, KeswickAlertService } from '@ecs/ecs-platform';
import { VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { Observable, catchError, forkJoin, map, of, switchMap, take } from 'rxjs';
import { ConfigureSitesService } from '../configure-sites.service';


@Injectable()
export class RemoveSiteModalService {

  constructor(
    private alertService: KeswickAlertService,
    private l10service: L10nService,
    private configureSiteService: ConfigureSitesService,
    private confirmationService: ConfirmationService,
  ) { }

  openRemoveSiteModal(sites: SiteResp[]): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const defaultSites = this.getDefaultSites(sites)
      const sitesWithHosts = this.getSitesWithHosts(sites)
      const isDeleteDisabled = defaultSites.length > 0 || sitesWithHosts.length > 0
      const modalMessage = isDeleteDisabled ?
        defaultSites.length > 0 ? this.l10service.getMessage('ecs.removesitemodal.error.defaultsite')
          : this.l10service.getMessage('ecs.removesitemodal.error.sitesattachedtohosts') : this.l10service.getMessage('ecs.removesitemodal.warningtext');

      this.confirmationService.confirm({
        title: this.l10service.getMessage('ecs.removesitemodal.title'),
        message: modalMessage,
        confirmButtonText: this.l10service.getMessage('ecs.delete'),
        cancelButtonText: this.l10service.getMessage('ecs.cancel'),
        size: VmwNgxModalSize.Medium,
        isWarning: !isDeleteDisabled,
        warningType: ClrAlertSeverity.DANGER,
        additionalMessage: this.l10service.getMessage('ecs.removesitemodal.additionalMessage'),
        bodyContent: this.l10service.getMessage('ecs.removesitemodal.bodycontent',[sites.length]),
        confirm: () => {
          this.removeSitesHandler(sites).pipe(take(1)).subscribe(() => {
            observer.next(true);
            observer.complete();
          });
        },
        cancel: () => {
          observer.next(false);
          observer.complete();
          return;
        },
        confirmButtonDisabled: isDeleteDisabled
      });

      return () => void 0;
    });
  }

  private removeSitesHandler(sites: SiteResp[]): Observable<void> {
    let requests: Observable<boolean>[] = []
    requests = sites.map((site) => this.removeSite(site.id))

    return forkJoin(requests).pipe(map((results) => {
      const totalSucceededRequests = results.filter((result) => result).length;
      if (totalSucceededRequests === requests.length) {
        this.alertService.showMessage({
          type: AlertType.success,
          message: this.l10service.getMessage("ecs.removesitemodal.success")
        });
      }
      else {
        this.alertService.showMessage({
          type: AlertType.failure,
          message: this.l10service.getMessage('ecs.removesitemodal.error', [
            totalSucceededRequests,
            requests.length - totalSucceededRequests
          ])
        });
      }
    }))
  }

  private removeSite(siteID: string): Observable<any> {
    return this.configureSiteService.deleteSite(siteID).pipe(
      map(() => { return true }),
      catchError(() => { return of(false) }))
  }

  private getDefaultSites(sites: SiteResp[]): SiteResp[] {
    return sites.filter((sites) => sites.is_default)
  }

  private getSitesWithHosts(sites: SiteResp[]): SiteResp[] {
    return sites.filter((sites) => sites.hosts_count > 0)
  }
}
