/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * All lumos tracking methods/configs etc goes here ....!
 */

declare global {
  interface Window {
    _paq: any;
    lumos: {
      _q: any[];
      _url: string;
      _clientId: string;
      _clientName: string;
      _paq?: any[];
      initialized: boolean;
      identify: (...args: any[]) => void;
      page: (...args: any[]) => void;
      track: (...args: any[]) => void;
      metadata: (...args: any[]) => void;
      locale: (...args: any[]) => void;
      theme: (...args: any[]) => void;
    };
  }
}

export const LUMOS_NAMESPACE = 'lumos';
export const DEFAULT_LUMOS_OBJECT = ({}) as Window['lumos'];

export class Lumos {
  public static initLumos = (env: string, lumosURL: string): void => {
    // const namespace = 'lumos';
    // Lumos provides two environments (staging and production), getting production flag from the configuration file.
    const lumosUrl = lumosURL;
    // The client ID obtained in the registration step.
    let clientId: string;

    switch (env) {
      case 'prd': {
        clientId = 'esp-prod-954-ftbwh';
        break;
      }
      case 'stg': {
        clientId = 'esp-stg-954-gmcsk';
        break;
      }
      default: {
        clientId = 'esp-stg-954-gmcsk';
        break;
      }
    }
    // any user-friendly name for your client
    const clientName = 'Keswick';

    window['_paq'] = window['_paq'] || [];
    window[LUMOS_NAMESPACE] = window[LUMOS_NAMESPACE] || {...DEFAULT_LUMOS_OBJECT};

    const lumos = window[LUMOS_NAMESPACE];
    lumos._q = lumos._q || [];
    ['identify', 'page', 'track', 'metadata', 'locale', 'theme'].forEach((event: string) => {
      (lumos as unknown as any)[event] = function () {
        // eslint-disable-next-line prefer-rest-params
        const e = Array.prototype.slice.call(arguments);
        e.unshift(event);
        lumos._q.push(e);
      };
    });

    lumos._url = lumosUrl;
    if (!lumos.initialized) {
      lumos._clientId = clientId;
      lumos._clientName = clientName;
      const script = document.createElement('script');
      script.async = true;
      script.src = `${lumosUrl}/assets/lumos.js?client_id=${clientId}&namespace=${LUMOS_NAMESPACE}`;
      document.getElementsByTagName('script')[0].appendChild(script);
      lumos.initialized = true;
      console.log('Lumos tracking initialized', lumosUrl);
    }
  };

  public static loginLumos = (userId: string, organization: string): void => {
    const lumos = (window['lumos'] = window['lumos'] || {...DEFAULT_LUMOS_OBJECT});
    const _paq = (lumos._paq = lumos._paq || []);

    // Get CSP User ID
    _paq.push(['setUserId', userId]);

    /*
            Set custom dimension of User's Organization. Custom dimensions are
            user-defined attributes or parameters that can be added to track and analyze
            specific data points within the Lumos analytics platform.
        */
    _paq.push(['setCustomDimension', 2, organization]);
  };

  public static trackImageDownload = (type: string): void => {
    const lumos = window[LUMOS_NAMESPACE] || {...DEFAULT_LUMOS_OBJECT};
    const _paq = (lumos._paq = lumos._paq || []);
    _paq.push(['trackEvent', 'Image Download', type]);
  };

  public static trackClickEvent = (category: string, action: string): void => {
    const lumos = window[LUMOS_NAMESPACE] || {...DEFAULT_LUMOS_OBJECT};
    const _paq = (lumos._paq = lumos._paq || []);
    _paq.push(['trackEvent', category, action]);
  };
}
