export interface IAlertDetail {
  id: string;
  message: string;
  severity: string;
  status: string;
  updated_at: Date;
}

export interface IAlertData {
  details: IAlertDetail;
  host_serial_number: string;
  host_uid: string;
  group_labels: string[];
}

export interface IAlertsResp {
  page: number;
  per_page: number;
  total: number;
  results: Array<IAlertData>;
}

export enum EAlertSeverity {
  CRITICAL = 'Critical',
  WARNING = 'Warning'
}
