<div class="modal-body edit-host-modal" id="editHostModal">
  <p class="modal-title">{{ 'ecs.edithostmodal.title' | vtranslate }}</p>
  <div *ngIf="loading$ | async; else editHostTemplate" class="edit-host-modal-spinner-wrapper">
    <clr-spinner></clr-spinner>
    <span class="edit-host-spinner-span"> {{ 'ecs.loading' | vtranslate }}... </span>
  </div>
  <ng-template #editHostTemplate>
    <form clrForm [formGroup]="editHostForm" clrLayout="horizontal" autocomplete="off" *ngIf="editHostForm">
      <clr-combobox-container class="edit-host-combobox-container">
        <label>{{ 'ecs.host.grouplabel' | vtranslate }}</label>
        <clr-combobox formControlName="groupLabels" name="multiSelect" clrMulti="true">
          <ng-container *clrOptionSelected="let selected">
            {{ selected | ecsLabel }}
          </ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let group of orgGroupsExceptApplied$ | async" [clrValue]="group">
              {{ group | ecsLabel }}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
      <ng-container formGroupName="gitRepoConfig" *ngIf="initialHostGitUrl !== ''">
        <div class="clr-row">
          <div class="clr-col-7">
            <clr-combobox-container class="git-repo-combobox" *ngIf="editGitRepoEnabled">
              <label class="edit-host-label">
                {{ 'ecs.host.giturl' | vtranslate }}
                <clr-tooltip>
                  <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                  <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                    <span>{{ 'ecs.gitlabel.tootlip' | vtranslate }}</span>
                  </clr-tooltip-content>
                </clr-tooltip>
              </label>
              <clr-combobox (ngModelChange)="editRepo($event)" formControlName="gitRepoUrl">
                <clr-options>
                  <clr-option *clrOptionItems="let repo of orgGitRepos$ | async; field: 'url'" [clrValue]="repo">{{
                    repo?.url
                  }}</clr-option>
                </clr-options>
              </clr-combobox>
              <clr-control-error class="edit-host-control-error">{{ 'ecs.requiredField' | vtranslate }}</clr-control-error>
            </clr-combobox-container>
            <clr-input-container *ngIf="!editGitRepoEnabled" class="edit-git-input-container">
              <label class="edit-host-label">
                {{ 'ecs.host.giturl' | vtranslate }}
                <clr-tooltip>
                  <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                  <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                    <span>{{ 'ecs.gitlabel.tootlip' | vtranslate }}</span>
                  </clr-tooltip-content>
                </clr-tooltip>
              </label>
              <input
                [formControl]="initialGitUrlFormControl"
                focusVisible
                clrInput
                class="clr-col-8 clr-col-sm-7 full-width-input no-border"
                readonly />
            </clr-input-container>
          </div>
          <div class="clr-col-5">
            <clr-control-container>
              <button class="btn btn-sm btn-link edit-button" (click)="toggleEditing($event)">
                {{ editGitRepoEnabled ? ('ecs.cancel' | vtranslate) : ('ecs.edit' | vtranslate) }}
              </button>
            </clr-control-container>
          </div>
        </div>
        <ng-container *ngIf="editGitRepoEnabled">
          <clr-input-container class="edit-host-input-container">
            <label>
              {{ 'ecs.addgit.branch' | vtranslate }}
              <clr-tooltip>
                <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                  <span>{{ 'ecs.addgit.branch.tooltip' | vtranslate }}</span>
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
            <input
              focusVisible
              clrInput
              class="clr-col-8 clr-col-sm-7 full-width-input"
              placeholder="{{ 'ecs.edithostmodal.branch.placeholdershort' | vtranslate }}"
              name="gitRepoBranch"
              formControlName="gitRepoBranch" />
            <clr-control-error class="edit-host-control-error">{{ 'ecs.requiredField' | vtranslate }}</clr-control-error>
          </clr-input-container>
          <!-- Git Repo Path -->
          <clr-input-container class="edit-host-input-container">
            <label>
              {{ 'ecs.edithostmodal.gitRepo.path' | vtranslate }}
              <clr-tooltip>
                <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                  <span>{{ 'ecs.edithostmodal.gitRepo.path.tooltip' | vtranslate }}</span>
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
            <input
              focusVisible
              clrInput
              class="clr-col-8 clr-col-sm-7 full-width-input"
              placeholder="{{ 'ecs.edithostmodal.gitRepo.path.placeholder' | vtranslate }}"
              name="gitRepoPath"
              formControlName="gitRepoPath" />
            <clr-control-helper class="edit-host-control-error">{{
              'ecs.edithostmodal.gitRepo.path.helper' | vtranslate
            }}</clr-control-helper>
            <clr-control-error class="edit-host-control-error">{{ 'ecs.edithostmodal.errors.invalidPath' | vtranslate }}</clr-control-error>
          </clr-input-container>

          <clr-input-container class="edit-host-input-container">
            <label>
              {{ 'ecs.addgit.username' | vtranslate }}
              <clr-tooltip>
                <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                  <span>{{ 'ecs.addgit.username.tooltip' | vtranslate }}</span>
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
            <input
              focusVisible
              clrInput
              class="clr-col-8 clr-col-sm-7 full-width-input"
              placeholder="{{ 'ecs.edithostmodal.username.placeholdershort' | vtranslate }}"
              name="gitRepoUsername"
              formControlName="gitRepoUsername" />
            <clr-control-error class="edit-host-control-error">{{ 'ecs.requiredField' | vtranslate }}</clr-control-error>
          </clr-input-container>
          <clr-password-container class="edit-host-password-container">
            <label>
              {{ 'ecs.addgit.accesstoken' | vtranslate }}
              <clr-tooltip>
                <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
                <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                  <span>{{ 'ecs.addgit.accesstoken.tooltip' | vtranslate }}}</span>
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
            <input
              clrPassword
              [placeholder]="'Add token details'"
              class="clr-col-8 clr-col-sm-7 full-width-input"
              placeholder="{{ 'ecs.addgit.accesstoken.placeholder' | vtranslate }}"
              name="gitRepoAccessToken"
              formControlName="gitRepoAccessToken"
              id="gitrepo-access-token"
              required />
            <clr-control-error class="edit-host-control-error">{{ 'ecs.requiredField' | vtranslate }}</clr-control-error>
          </clr-password-container>
        </ng-container>
      </ng-container>
    </form>
  </ng-template>
</div>
