import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GroupsResp, UpdateGroupData } from '../components/group/groups/groups.model';
import { ESortOrder, OrgService } from '@ecs/ecs-platform';

import { OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { catchError, Observable, ReplaySubject } from 'rxjs';
import { handleError } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {  
  groupsList = new ReplaySubject<GroupsResp>(1);
  modalRef: VmwNgxModalRef<OkCancel>;

  constructor(private http: HttpClient, private orgService: OrgService) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getGroups(page = 1, perPage = 10, sortColumn = 'label', sortOrder = ESortOrder.ASCENDING, filterValues = new Map<string, string>()) {
    const url = `${this.getUrl()}`;
    let params = new HttpParams().set('page', page).set('per_page', perPage).set('sort_column', sortColumn).set('sort_order', sortOrder);

    if (filterValues.size != 0) {
      filterValues.forEach((value, key) => {
        if (value != '') {
          params = params.set(key, value);
        }
      });
    }

    this.http.get<GroupsResp>(url, { params }).subscribe((groupsResp) => {
      this.groupsList.next({
        results: groupsResp.results || [],
        total: groupsResp.total,
        page: groupsResp.page,
        perPage: groupsResp.perPage,
        sortColumn: sortColumn
      });
    });
  }

  deleteGroup(groupId: number): Observable<any> {
    const url = `${this.getUrl()}/${groupId}`;

    return this.http.delete<string>(url);
  }

  updateGroup(updateGroupData: UpdateGroupData): Observable<any> {
    const url = `${this.getUrl()}/${updateGroupData.id}`;

    return this.http.put<UpdateGroupData>(url, JSON.stringify(updateGroupData), this.httpOptions).pipe(catchError(handleError));
  }

  private getUrl(): string {
    const prefixUrl = this.orgService.getVcoEnterpriseOrgBaseUrl();
    return `${prefixUrl}/groups`;
  }
}
