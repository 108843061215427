import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AddGitrepoModalService } from '../../services/add-gitrepo-modal.service';
import { LEARN_MORE_ABOUT_GIT_REPO, Lumos } from '@ecs/ecs-common';

@Component({
  selector: 'app-gitrepo',
  templateUrl: './gitrepo.component.html',
  styleUrls: ['./gitrepo.component.scss']
})
export class GitrepoComponent {
  public heroBanner = true;
  public readonly LEARN_MORE_ABOUT_GIT_REPO: string = LEARN_MORE_ABOUT_GIT_REPO;
  constructor(public router: Router, private addGitrepoService: AddGitrepoModalService) {}

  openAddGitRepoModal() {
    Lumos.trackClickEvent('GitRepo', 'Add GitRepo clicked');
    this.addGitrepoService.openAddGitrepoModal();
  }

  hideHeroBanner() {
    this.heroBanner = false;
  }

  openGitInstructions() {
    window.open(this.LEARN_MORE_ABOUT_GIT_REPO, '_blank');
  }
}
