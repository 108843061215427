import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MODAL_DATA, OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { HostsService } from '../../../services/hosts.service';
import { GetHostsResp, HostResp, Label } from '@ecs/ecs-api';

@Component({
  selector: 'app-groups-update',
  templateUrl: './groups-update.component.html',
  styleUrls: ['./groups-update.component.scss']
})
export class GroupsUpdateComponent {
  private groupID: number;
  public groupLabel: Label;
  public description: string;
  private groupMemberIDs: string[] = [];
  private groupMemberSerialNumbers: string[] = [];
  private groupMemberSerialNumbersInitial: string[] = [];
  public updateGroupForm: FormGroup;
  public memberSelection: string[];
  public hostsList: HostResp[] = [];
  private deletedHostIdList: string[] = [];
  private addedHostIdList: string[] = [];
  private hostIdSerialNumMap: Map<string, string> = new Map<string, string>();
  // Reverse map mapping serial numbers to ID
  private hostSerialNumIdMap: Map<string, string> = new Map<string, string>();
  private groupMembersIdSerialNumMap: Map<string, string> = new Map<string, string>();
  @Input() updateGroupModal = false;

  constructor(
    @Inject(MODAL_DATA) data: any,
    private fb: FormBuilder,
    private hostsService: HostsService,
    private modalRef: VmwNgxModalRef<OkCancel>
  ) {
    (this.groupID = data.groupID),
      (this.groupLabel = data.groupLabel),
      (this.groupMemberIDs = data.groupMembers),
      (this.description = data.groupDescription);
  }

  ngOnInit(): void {
    this.buildUpdateGroupForm();

    // Fetch list of "Active" or "GitOpsPending" state hosts in the organization
    // which can be added/removed from the group
    this.hostsService.getHosts();
    this.hostsService.activeHostList.subscribe((hosts: GetHostsResp) => {
      if (hosts) {
        this.hostIdSerialNumMap.clear();
        this.hostsList = hosts.results;

        // Create hostIdSerialNumMap containing mapping between all host IDs and serial numbers
        hosts?.results?.forEach((hostResult) => {
          this.hostIdSerialNumMap.set(hostResult.unique_identifier, hostResult.host_registration_details.host_serial_number);
          this.hostSerialNumIdMap.set(hostResult.host_registration_details.host_serial_number, hostResult.unique_identifier);
        });

        // Create groupMembersIdSerialNumMap containing mapping between group members host IDs
        // and serial numbers
        this.groupMemberSerialNumbers = [];
        this.groupMemberIDs?.forEach((member) => {
          if (this.hostIdSerialNumMap.has(member)) {
            this.groupMemberSerialNumbers.push(this.hostIdSerialNumMap.get(member));
            this.groupMemberSerialNumbersInitial.push(this.hostIdSerialNumMap.get(member));
            this.groupMembersIdSerialNumMap.set(member, this.hostIdSerialNumMap.get(member));
          }
        });
      }

      // Update groupMembers in memberSelection combobox
      this.updateGroupForm.get('memberSelection').setValue(this.groupMemberSerialNumbers);
    });

    this.modalRef.buttons.submitButton.onClick().subscribe(() => {
      this.modalRef.close({
        id: this.groupID,
        description: this.description,
        members: {
          add_host_id_list: this.addedHostIdList,
          remove_host_id_list: this.deletedHostIdList
        }
      });
    });
  }

  buildUpdateGroupForm(): void {
    this.updateGroupForm = this.fb.group({
      description: new FormControl(''),
      memberSelection: new FormControl(''),
      newHostsList: new FormControl(null)
    });
  }

  onGroupMembersChange(newGroupMembers: string[]) {
    this.deletedHostIdList = [];
    this.addedHostIdList = [];

    // Check for removed members
    if (this.groupMembersIdSerialNumMap) {
      for (const member of this.groupMembersIdSerialNumMap.values()) {
        if (newGroupMembers?.length == 0 || !newGroupMembers?.includes(member)) {
          this.deletedHostIdList?.push(this.hostSerialNumIdMap?.get(member));
        }
      }
    }

    // Check for added members
    if (newGroupMembers) {
      for (const member of newGroupMembers) {
        if (
          this.groupMemberSerialNumbersInitial?.length == 0 ||
          !Array.from(this.groupMemberSerialNumbersInitial?.values())?.includes(member)
        ) {
          this.addedHostIdList?.push(this.hostSerialNumIdMap?.get(member));
        }
      }
    }
  }
}
