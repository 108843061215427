import { InjectionToken } from '@angular/core';

export interface CceUiEnvironment {
  commit?: string;
  production: boolean;
  oauthClientId: string;
  cspEnvironment: string;
  maintenanceUrl?: string;
  cspServiceDefinitionId: string;
  cspSdkKey: string;
  lumosUrl: string;
}

export const APP_ENVIRONMENT = new InjectionToken<CceUiEnvironment>('CCE UI Application Environment');

export const mockEnvironment: CceUiEnvironment = {
  commit: 'nothing',
  production: false,
  oauthClientId: 'fake-oauth-client',
  cspEnvironment: 'fake-csp-environment',
  cspServiceDefinitionId: 'fake-csp-service-id',
  maintenanceUrl: undefined,
  cspSdkKey: 'fakeSdkKey',
  lumosUrl: 'https://lumos.esp-staging.vmware-aws.com'
};
