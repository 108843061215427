export const ENG_ECS_HOSTS_TRANSLATIONS = {
    'ecs.host.name': 'Name',
    'ecs.action.activatehost': 'Activate',
    'ecs.action.edithost': 'Save',
    'ecs.action.addhosttogroup': 'Add Host To Group',
    'ecs.host.serial.number': 'Host Serial Number',
    'ecs.host.startdate': 'Start Date',
    'ecs.host.enddate': 'End Date',
    'ecs.host.addrepo.success': 'The git repository has been added successfully to the organization!',
    'ecs.host.gitOpsPath': 'Git Repo Path',
    'ecs.hosts.noActiveHosts': 'Currently you do not have any activated hosts.',
    'ecs.host.username.placeholder': 'Filter User',
    //'ecs.dormanthosts.title': 'These hosts have been registered, but have not completed enrollment with Edge Compute Service.',
    'ecs.host.serialnumber': 'Serial Number',
    'ecs.host.timeadded': 'Time added',
    'ecs.host.nohostaddedtitle': 'Currently you have no hosts added.',
    'ecs.host.nohostaddedsubtitle': 'Learn more on how to add hosts and manage them in Edge Compute',
    'ecs.host.pagination': '{0} - {1} of {2} hosts',
    'ecs.host.hostDetail.associatedGetrepo': 'Associated Git Repository',
    'ecs.host.hostDetail.getconfigSource': 'Git Config Source',
    'ecs.host.hostDetail.path': 'Path',
    'ecs.host.hostDetail.vendor': 'Vendor',
    'ecs.host.hostDetail.model': 'Model',
    'ecs.host.hostDetail.folder': 'Folder',
    'ecs.host.siteDetails.associatedSites': 'Associated Site',
    'ecs.host.hostDetail': 'Details',
    'ecs.host.hostDetail.getrepo': 'Git Repository',
    'ecs.host.hostDetail.branch': 'Branch',    
    'ecs.host.banner.gettingstarted':'Getting Started with Hosts',
    'ecs.host.banner':'Hosts are the servers located at an edge location, a deployed host can be registered and added to a site and associated with a git repository where they can find their desired state configuration.',
    'ecs.host.banner.subdescription':'To use this feature, first deploy a host, register the host with the information provided in DCUI after it has been built or the details received from the hardware vendor. All Hosts and their information, including their activation status will be listed here.',
    'ecs.host.banner.addhost':'ADD A HOST',
    'ecs.host.banner.learnmore':'LEARN MORE ABOUT HOSTS',
    'ecs.host.banner.learnmore.aboutactivationstatus':'LEARN MORE ABOUT ACTIVATION STATUS',
    'ecs.host.hostDetail.activationkey': 'Activation Key',
    'ecs.host.hostDetail.registrationType': 'Registration Type',
    'ecs.host.health.unknown': 'Unknown',
    'ecs.host.health.warning': 'Warning',
    'ecs.host.health.ok': 'Ok',
  };
  