<h2>{{ 'ecs.gitrepositories' | vtranslate }}</h2>
<p *ngIf="!heroBanner" class="page-info">
  {{ 'ecs.gitrepo.banner' | vtranslate }}
</p>
<div class="hero-section" *ngIf="heroBanner">
  <div class="hero-content">
    <div>
      <div class="hero-top">
        <h2>{{ 'ecs.gitrepo.gettingstarted' | vtranslate }}</h2>
      </div>

      <div>
        {{ 'ecs.gitrepo.banner' | vtranslate }}
      </div>
      <p></p>
      <div>
        {{ 'ecs.gitrepo.description' | vtranslate }}
        <span
          appI18nLinkTarget="link"
          (i18nLinkTargetClicked)="openGitInstructions()"
          [innerHTML]="'ecs.gitrepo.subdescription' | vtranslate">
        </span>
      </div>
    </div>
    <img src="/apps/ecs/assets/git-repos-hero-image-light.svg" alt="git repos hero section"/>
  </div>
  <button class="btn btn-primary" (click)="openAddGitRepoModal()">{{ 'ecs.gitrepo.addgitrepository' | vtranslate }}</button>
  <a [href]="LEARN_MORE_ABOUT_GIT_REPO" target="_blank" rel="noopener noreferrer">
    <button class="btn btn-outline">{{ 'ecs.gitrepo.learnmore' | vtranslate }}</button>
  </a>

  <div class="close-banner" (click)="hideHeroBanner()">
    <cds-icon shape="window-close" size="md"></cds-icon>
  </div>
</div>

<app-gitrepos-list></app-gitrepos-list>
