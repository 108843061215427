import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ClarityModule, ClrStepperModule } from '@clr/angular';

//import { CspComponentsModule } from '@vmw/csp-ngx-components';
//import { CspHeaderModule } from '@vmw/csp-ngx-components/header';
//import { CspServiceSdkModule } from '@vmw/csp-ngx-service-sdk';
import { VmwGaugeModule } from '@vmw/ngx-charts/gauge';
import { VmwComponentsModule } from '@vmw/ngx-components';
import { VmwDateRangePickerModule as VmwDateRangePickerComponentModule } from '@vmw/ngx-components/date-range-picker';
import { VmwContextualHelpModule } from '@vmw/ngx-contextual-help';
//import { OAuth2Module, OAuth2ModuleConfiguration } from '@vmw/ngx-csp-oauth2';
import { VmwThemeToolsModule } from '@vmw/ngx-utils';
import { VmwTextClipperDirectiveModule } from '@vmw/ngx-utils/text-clipper';

import {
  APIHttpInterceptor,
  //APP_ENVIRONMENT,
  CceCrossOrgSelectorModule,
  EARLY_PRELOAD_PROVIDER,
  //EARLY_PRELOAD_PROVIDER,
  EcsPlatformModule
} from '@ecs/ecs-platform';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { oauth2ConfigFactory } from './oauth.config';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsModule } from '@cds/angular';

import { APP_BASE_HREF, DecimalPipe } from '@angular/common';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { RequestRoleErrorComponent } from './errors/request-role-error/request-role-error.component';

import { Configuration, EcsApiModule } from '@ecs/ecs-api';

import { HostFilterComponent } from './components/hosts/filters/filters.component';
import { HostsComponent } from './components/hosts/hosts.component';

import { VmwWorldMapModule } from '@vmw/ngx-world-map';
import { ActivateHostModalComponent } from './components/activate-host-modal/activate-host-modal.component';
import { AddHostModalContentComponent } from './components/add-host-modal-content/add-host-modal-content.component';
import { AddToGroupModalComponent } from './components/add-to-group-modal/add-to-group-modal.component';
import { InitialActionCardComponent } from './components/common-components/initial-action-card/initial-action-card.component';
import { KeswickAlertComponent } from './components/common-components/keswick-alert/keswick-alert.component';
import { GettingStartedComponent } from './components/getting-started/getting-started.component';
import { GroupsListComponent } from './components/group/groups-list/groups-list.component';
import { GroupsComponent } from './components/group/groups/groups.component';
import { CapacityAndUsageComponent } from './components/host-details/capacity-and-usage/capacity-and-usage.component';
import { HostDetailsComponent } from './components/host-details/host-details.component';
import { HostHardwareDetailComponent } from './components/host-details/host-hardware-detail/host-hardware-detail.component';
import { LaunchpadFooterComponent } from './components/launchpad-footer/launchpad-footer.component';
import { LaunchpadComponent } from './components/launchpad/launchpad.component';
import { RemoveHostModalContentComponent } from './components/remove-host-modal-content/remove-host-modal-content.component';
import { GitRepoModule } from './git-repo/git-repo.module';

import { EcsCommonModule } from '@ecs/ecs-common';
import { DynamicComponentService } from '@ecs/ecs-platform';
import { EditHostModalComponent } from './components/edit-host-modal/edit-host-modal.component';
import { GroupsRemoveComponent } from './components/group/groups-remove/groups-remove.component';
import { GroupsUpdateComponent } from './components/group/groups-update/groups-update.component';
import { VideoPosterComponent } from './components/video-poster/video-poster.component';
import { EcsI8nModule } from './ecs-i18n/ecs-i18n.module';
import { initClarityIconRegistry } from './utils/clarity-icons-registry/clarity-icon-registry';
import { RebootHostModalContentComponent } from './components/reboot-host-modal-content/reboot-host-modal-content.component';
import { AddHostComponent } from './components/add-host/add-host.component';
import { AddHostSiteDetailsComponent } from './components/add-host-site-details/add-host-site-details.component';
import { EcsMonitorModule } from '@ecs/ecs-monitor';
import { VcCommonModule } from '@velocloud/angular-vc-common';
import { HostDetailPaneComponent } from './components/host-detail-pane/host-detail-pane.component';
import { RestXsrfInterceptor } from './interceptors/rest-xsrf-interceptor';

export function initApiModuleFactory() {
  return new Configuration({
    basePath: '/v0'
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HostsComponent,
    RequestRoleErrorComponent,
    PageNotFoundComponent,
    InitialActionCardComponent,
    AddHostModalContentComponent,
    KeswickAlertComponent,
    ActivateHostModalComponent,
    LaunchpadComponent,
    GettingStartedComponent,
    LaunchpadFooterComponent,
    RemoveHostModalContentComponent,
    HostDetailsComponent,
    HostHardwareDetailComponent,
    AddToGroupModalComponent,
    GroupsComponent,
    GroupsListComponent,
    CapacityAndUsageComponent,
    GroupsRemoveComponent,
    GroupsUpdateComponent,
    EditHostModalComponent,
    VideoPosterComponent,
    HostFilterComponent,
    RebootHostModalContentComponent,
    AddHostComponent,
    AddHostSiteDetailsComponent,
    HostDetailPaneComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    CceCrossOrgSelectorModule,
    VmwComponentsModule.forRoot(),
    VmwThemeToolsModule.forRoot(),
    // CspHeaderModule,
    VmwContextualHelpModule.forRoot({
      contextualHelpUrl: '/apps/ecs/assets/docs/en.json',
      contextMetadataUrl: '/apps/ecs/assets/docs/en-contexts.json'
    }),
    VmwTextClipperDirectiveModule,
    //CspComponentsModule.forRoot(),
    // CspServiceSdkModule.forRoot(),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'velocloud.csrf',
      headerName: 'x-vco-csrf'
    }),
    // OAuth2Module,
    FormsModule,
    CdsModule,
    ReactiveFormsModule,
    VmwDateRangePickerComponentModule,
    VmwWorldMapModule,
    GitRepoModule,
    VmwGaugeModule,
    // ApiModule.forRoot(initApiModuleFactory),
    EcsApiModule,
    EcsI8nModule,
    EcsCommonModule,
    EcsPlatformModule,
    ClrStepperModule,
    EcsMonitorModule,
    VcCommonModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/ui/' },
    EARLY_PRELOAD_PROVIDER,
    // {
    //   provide: OAuth2ModuleConfiguration,
    //   useFactory: oauth2ConfigFactory,
    //   deps: [APP_ENVIRONMENT]
    // },
    DecimalPipe,
    DynamicComponentService,
    { provide: HTTP_INTERCEPTORS, useClass: APIHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestXsrfInterceptor,
      multi: true
    },  
    {
      provide: APP_INITIALIZER,
      useFactory: initClarityIconRegistry,
      deps: [],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
