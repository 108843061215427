export const ENG_ECS_APP_TRANSLATIONS = {
  
  'ecs.navbar.sites': 'sites',
  'ecs.sessionexpired.title': 'Session Expired',
  'ecs.sessionexpired.body': 'Your session is expired due to inactivity. You will have to refresh your browser to start a new session',
  'ecs.apptitle': 'VMware Edge Cloud Orchestrator - Edge Compute Stack',
  'ecs.productname': 'Edge Cloud Orchestrator',

  /**
   * Side Navs
   */
  'ecs.sidenav.section.monitor': 'Monitor',
  'ecs.sidenav.section.link.alerts': 'Alerts',
  'ecs.sidenav.section.link.events': 'Events',

  'ecs.sidenav.section.configure': 'Configure',
  'ecs.sidenav.section.link.hosts': 'Hosts',
  'ecs.sidenav.section.link.gitrepositories': 'Git Repositories',
  'ecs.sidenav.section.link.groups': 'Groups',
  'ecs.sidenav.section.link.sites': 'Sites',
  'ecs.sidenav.section.link.launchpad': 'Launchpad',

  /**
    * Header Horizontal Tabs
    */
  'ecs.header.horizontalTabs.ENTERPRISE_MONITOR': 'Monitor',
  'ecs.header.horizontalTabs.ENTERPRISE_CONFIGURE': 'Configure',
  'ecs.product.name': 'VMware Edge Compute Stack'
};
