<div class="modal-body">
  <ng-container *ngIf="hostsWithoutGitRepo.length===0; else hostswithoutgitrepo">
    <div class="alert alert-info" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
          </div>
          <span class="alert-text">{{hosts.length}}: {{'ecs.activateHostModal.badge.title'| vtranslate}}</span>
        </div>
      </div>
    </div>
    <p>{{'ecs.activateHostModal.body'| vtranslate}}</p>
    <ul class="host-list">
      <li *ngFor="let host of hosts">
        <strong>{{ host?.name }}</strong>
      </li>
      <br />
    </ul>
  </ng-container>
</div>

<ng-template #hostswithoutgitrepo>
  <div class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
        </div>
        <span class="alert-text">{{hosts.length}}: {{'ecs.activateHostModal.badge.title'| vtranslate}}</span>
      </div>
    </div>
  </div>
  <p>{{'ecs.activateHostModal.body.hostswithoutgitconfig'| vtranslate}}</p>
  <ul class="host-list">
    <li *ngFor="let host of hostsWithoutGitRepo">
      <strong>{{ host?.name }}</strong>
    </li>
    <br />
  </ul>
</ng-template>