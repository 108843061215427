import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { CreateSite, GitRepoResp, SiteResp, UpdateSite } from '@ecs/ecs-api';
import { ConfirmationService, EcsValidators, GlobalConst, IComponentCanDeactivate, PageHeaderBreadcrumb, RoutingHelperService, validateGitRepoPath } from '@ecs/ecs-common';
import { AppRoutes, EnterpriseRoutes, OrgService, KeswickAlertService, AlertType, CONFIGURE_SITES_ROUTE, getAdjustedRoute } from '@ecs/ecs-platform';
import { VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { BehaviorSubject, distinctUntilChanged, finalize, map, switchMap, take } from 'rxjs';
import { ConfigureSitesService } from '../../configure-sites.service';
import { ManageSiteForm, ManageSiteFormValues, ManageSiteRouteParams, DEFAULT_SITE_NAME } from '../../ecs-configure-sites.models';
import { findChangedValue } from '@ecs/ecs-common';

enum SourceMode {
  CREATE = "create",
  EDIT = "edit"
}
@Component({
  selector: 'ecs-manage-site',
  templateUrl: './manage-site.component.html',
  styleUrls: ['./manage-site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageSiteComponent extends Unsubscriber implements OnInit, IComponentCanDeactivate {
  private readonly siteIdSource = new BehaviorSubject<string>(null);
  private readonly loadingSource = new BehaviorSubject<boolean>(false);
  private readonly selectedGitRepo = new BehaviorSubject<GitRepoResp>(null);
  private readonly editSiteInfo = new BehaviorSubject<SiteResp>(null);
  private readonly dummyBranch: GitRepoResp = {
    id: -1,
    name: '',
    description: '',
    url: "",
    default_branch: "",
    created_at: null,
    updated_at: null,
    host_count: 0
  }
  public mode: SourceMode = SourceMode.CREATE
  public readonly MAX_STRING_LENGTH = 50;
  public readonly SITE_NAME_MAX_STRING_LENGTH = 63;

  public breadcrumbs: PageHeaderBreadcrumb[] = [];
  public siteId$ = this.siteIdSource.asObservable();
  public loading$ = this.loadingSource.asObservable();
  public editSiteInfo$ = this.editSiteInfo.asObservable();
  public showToken = false;
  public tokenUpdateSuccess = false;

  public pageLabel$ = this.siteId$.pipe(
    map((siteId) => (siteId ? 'ecs.sites.configure.editSite' : 'ecs.sites.configure.addNewSite')),
    map((key: string) => {
      return this.l10nService.getMessage(key);
    })
  );
  public selectedGitRepo$ = this.selectedGitRepo.asObservable();
  public form: FormGroup<ManageSiteForm>;
  public gitRepos: GitRepoResp[] = [];
  public currentStep = ''
  public addAnotherSite = false;

  get hasGitRepoAssociated() {
    if (this.form) {
      return this.form.get('gitDetails.git_repo_id').value !== null;
    }
    return false;
  }

  get siteName() {
    if (this.form) {
      return this.form.get('siteDetails.name').value;
    }
    return '';
  }

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private l10nService: L10nService, private cdr: ChangeDetectorRef,
    private orgService: OrgService, private configureSitesService: ConfigureSitesService, private confirmationService: ConfirmationService,
    private routingHelperService: RoutingHelperService, private alertService: KeswickAlertService) {
    super();

  }

  populateBreadCrumbs(siteId: string) {
    this.breadcrumbs = [
      {
        label: this.l10nService.getMessage("ecs.sites.configure.label"),
        url: `/${AppRoutes.configure}/${EnterpriseRoutes.sites}`
      },
      {
        label: siteId ? this.l10nService.getMessage("ecs.sites.editSite") : this.l10nService.getMessage("ecs.sites.newSite")
      }
    ];
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.form?.dirty;
  }



  ngOnInit(): void {
    this.subs = this.route.params.subscribe((params: ManageSiteRouteParams) => {
      this.siteIdSource.next(params.id ?? null);
      this.populateBreadCrumbs(params.id)
      this.mode = params.id ? SourceMode.EDIT : SourceMode.CREATE
      if (this.mode === SourceMode.EDIT) {
        this.configureSitesService.getSite(params.id).subscribe((siteInfo: SiteResp) => {
          this.editSiteInfo.next(siteInfo)
        })
      }

    });
    this.loadingSource.next(true);
    this.subs = this.orgService.orgId$.pipe(take(1), switchMap(orgId => this.configureSitesService.loadGitRepos(orgId))).subscribe(gitRepos => {
      this.gitRepos = (gitRepos?.length) ? [this.dummyBranch, ...gitRepos] : [this.dummyBranch];
      this.createForm();
      this.subscribeToFormChanges();
      this.loadingSource.next(false);
      this.cdr.detectChanges();
    });
  }

  updateToken(): void {
    this.showToken = true;
  }

  cancelUpdateToken(): void {
    this.form.get('gitDetails').get('git_repo_access_token').patchValue("");
    this.form.get('gitDetails').get('git_repo_access_token').markAsPristine();

    this.showToken = false;
  }

  updateTokenSuccess(): void {
    this.showToken = false;
    if (this.form.get('gitDetails').get('git_repo_access_token').value) {
      this.tokenUpdateSuccess = true;
    }

  }

  saveChanges(): void {
    if (this.form.invalid) {
      return;
    }

    this.loadingSource.next(true);
    this.subs = this.editSiteInfo$.pipe(switchMap(data => {
      if (data) {
        const updatedValuesInSite: UpdateSite = findChangedValue(data, this.formValuesToSite(this.form.getRawValue()))
        if (this.form.get('gitDetails').get('git_repo_access_token').pristine) {
          delete updatedValuesInSite.git_repo_access_token
        }
        return this.configureSitesService.updateSite(data.id, updatedValuesInSite)
      }
      else {
        return this.configureSitesService.createSite(this.formValuesToSite(this.form.getRawValue()))
      }
    }), finalize(() => {
      this.loadingSource.next(false);
    })
    ).subscribe(() => {
      this.form.markAsUntouched();
      this.form.markAsPristine();
      if (this.addAnotherSite) {
        this.loadingSource.next(false);
        this.cdr.detectChanges();
        this.routingHelperService.reloadCurrentRoute();
      } else {
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.loadingSource.next(false);
        this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_SITES_ROUTE));
      }
    })
  }


  cancel(): void {
    if (this.form.dirty) {
      this.confirmationService.confirm({
        title: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.title'),
        message: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.body'),
        confirmButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.accept'),
        cancelButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.reject'),
        size: VmwNgxModalSize.Medium,
        confirm: () => {
          return;
        },
        cancel: () => {
          this.form.reset();
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_SITES_ROUTE));
        }
      })
    } else {
      this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_SITES_ROUTE));
    }
  }

  private isGitDetailsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = control.value === true;
      return isValid ? null : { 'error': { value: control.value } };
    };
  }

  private createForm(): void {
    this.subs = this.editSiteInfo$.subscribe((siteInfo: SiteResp) => {
      this.form = this.fb.group<ManageSiteForm>({
        siteDetails: this.fb.group({
          name: this.fb.control(siteInfo ? siteInfo.name : '', [Validators.required, EcsValidators.sanitizeString, Validators.maxLength(63), Validators.pattern(GlobalConst.patterns.nameRegex)]),
          description: this.fb.control(siteInfo ? siteInfo.description : '', [EcsValidators.sanitizeString, Validators.maxLength(255)]),
          location: this.fb.control(siteInfo ? siteInfo.location : '', [EcsValidators.sanitizeString, Validators.maxLength(50)]),
          isSiteDetailsValid: [true, [this.isGitDetailsValidator()]]
        }),
        gitDetails: this.fb.group({
          git_repo_id: this.fb.control(siteInfo ? siteInfo.git_repo_id : null, []),
          git_repo_branch: this.fb.control(siteInfo ? siteInfo.git_repo_branch : 'main', [EcsValidators.sanitizeString, Validators.maxLength(50)]),
          git_repo_path: this.fb.control(siteInfo ? siteInfo.git_repo_path : '/', [validateGitRepoPath, EcsValidators.sanitizeString, Validators.maxLength(255)]),
          git_repo_username: this.fb.control(siteInfo ? siteInfo.git_repo_username : '', [Validators.maxLength(50)]),
          git_repo_access_token: this.fb.control('', [Validators.maxLength(255)]),
        }),
        tagsDetails: this.fb.group({
          tags: this.fb.control(siteInfo && siteInfo.labels?.length > 0 ? siteInfo.labels : [])
        })
      });
      if (siteInfo && siteInfo.is_default) {
        this.form.get("siteDetails").get("name").disable();
      }
      this.cdr.detectChanges();
    })
  }

  private subscribeToFormChanges(): void {
    //TODO - this does not get called in somecases when git repo is changed in edit site flow. so default branch is not changed
    this.subs = this.form.get('gitDetails').get('git_repo_id').valueChanges.subscribe((gitRepoId: number) => {
      const selectedGitRepo = this.gitRepos.find(repo => repo.id === Number(gitRepoId));
      if (selectedGitRepo) {
        this.form.get('gitDetails').get('git_repo_branch').patchValue(selectedGitRepo.default_branch);
      }
      this.form.get('gitDetails').get('git_repo_username').patchValue("");
      this.form.get('gitDetails').get('git_repo_path').patchValue("/");
      this.form.get('gitDetails').get('git_repo_access_token').patchValue("");
    });

    this.subs = this.form.get('siteDetails').get('name').valueChanges.subscribe((name: string) => {

      if (this.form.get('siteDetails').get('name').value?.toLowerCase() === DEFAULT_SITE_NAME) {
        this.form.get('siteDetails').get('name').setErrors({ invalidName: [this.form.get('siteDetails').get('name').value] })
      }
      if (this.form.get('siteDetails').get('name').errors?.['pattern']) {
        this.form.get('siteDetails').get('name').setErrors({ invalidPattern: true })
      }
    })

    this.subs = this.siteDetailsControl.valueChanges
      .pipe(distinctUntilChanged((prev, curr) => prev.name === curr.name))
      .subscribe(() => {
        this.siteDetailsControl.setValue({ ...this.siteDetailsControl.value, isSiteDetailsValid: true })
      });
  }

  private formValuesToSite({ siteDetails, gitDetails, tagsDetails }: ManageSiteFormValues): CreateSite {
    let gitRepoConfig = {};
    if (gitDetails.git_repo_id) {
      gitRepoConfig = {
        git_repo_id: Number(gitDetails.git_repo_id),
        git_repo_branch: gitDetails.git_repo_branch,
        git_repo_path: gitDetails.git_repo_path,
        git_repo_username: gitDetails.git_repo_username,
        git_repo_access_token: gitDetails.git_repo_access_token
      };
    }
    const labels = tagsDetails.tags.filter(tag => !!tag);

    return {
      name: siteDetails.name ?? '',
      description: siteDetails.description ?? '',
      location: siteDetails.location ?? '',
      labels,
      ...gitRepoConfig
    };
  }

  private moveToStep(step: string) {
    this.currentStep = '';
    setTimeout(() => {
      this.currentStep = step;
      this.cdr.detectChanges()
    }, 0);
  }

  get siteDetailsControl() {
    return this.form.get('siteDetails')
  }

  navigateToGitDetails() {
    const siteName = this.form?.get("siteDetails")?.get("name")?.value
    this.subs = this.editSiteInfo$.pipe(take(1)).subscribe((siteResp: SiteResp) => {
      if (siteName === siteResp?.name) {
        this.moveToStep("gitDetails");
        return;
      }
      else {
        this.subs = this.configureSitesService.checkDuplicateSite(siteName).subscribe((isDuplicate: boolean) => {
          if (!isDuplicate) {
            this.moveToStep("gitDetails");
            this.cdr.detectChanges()
          }
          else {
            this.siteDetailsControl.setValue({ ...this.siteDetailsControl.value, isSiteDetailsValid: false })
            this.alertService.showMessage({
              type: AlertType.failure,
              message: this.l10nService.getMessage('ecs.addhost.duplicatesitedetails')
            });
          }
        })
      }
    })

  }

}
