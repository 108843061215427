import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateGitAssociation, GetGitReposResp, GitConfigSource, GitRepoResp, Label } from '@ecs/ecs-api';
import { MODAL_DATA, OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { BehaviorSubject, combineLatest, map, startWith, take } from 'rxjs';
import { GitReposService } from '../../services/gitrepos.service';
import { GroupsService } from '../../services/groups.service';
import { validateGitRepoPath } from '../../utils/validators/git-repo-path.validator';
import { GroupRespData, GroupsResp, UpdateGroupInfo } from '@ecs/ecs-platform';
import { Unsubscriber } from '@velocloud/angular-vc-common';

export interface EditHostModalData {
  hostIdentifier: string;
  groupLabels: Label[];
  selectedHostGitUrl: string;
  selectedHostGitPath: string;
  selectedHostGitBranch: string;
}

export type GitRepoComboboxData = Pick<GitRepoResp, 'id' | 'url' | 'default_branch' | 'description'>;

@Component({
  selector: 'app-edit-host-modal',
  templateUrl: './edit-host-modal.component.html',
  styleUrls: ['./edit-host-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditHostModalComponent extends Unsubscriber {
  private loadingSource = new BehaviorSubject<boolean>(false);
  private orgGroupsExceptAppliedSource = new BehaviorSubject<GroupRespData[]>([]);
  private orgGitReposSource = new BehaviorSubject<GitRepoComboboxData[]>([]);
  private editGitRepoEnabledSource = new BehaviorSubject<boolean>(false);

  private hostIdentifier: string;
  private groupLabelsInitial: GroupRespData[] = [];

  public readonly loading$ = this.loadingSource.asObservable();
  public readonly orgGroupsExceptApplied$ = this.orgGroupsExceptAppliedSource.asObservable();
  public readonly orgGitRepos$ = this.orgGitReposSource.asObservable();
  public readonly editGitRepoEnabled$ = this.editGitRepoEnabledSource.asObservable();

  public editHostForm: FormGroup;
  public orgGroupsExceptApplied: GroupRespData[] = [];
  public initialHostGitUrl: string;
  public initialGitUrlFormControl = new FormControl('');

  @Input() editHostModal = false;

  constructor(
    @Inject(MODAL_DATA) private data: EditHostModalData,
    private fb: FormBuilder,
    private gitRepoService: GitReposService,
    private groupsService: GroupsService,
    private modalRef: VmwNgxModalRef<OkCancel>,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.hostIdentifier = this.data?.hostIdentifier;
    this.initialHostGitUrl = this.data?.selectedHostGitUrl;
  }

  get editGitRepoEnabled() {
    return this.editGitRepoEnabledSource.value;
  }

  ngOnInit(): void {
    this.loadingSource.next(true);
    this.groupsService.getGroups();

    this.subs = combineLatest([this.groupsService.groupsList, this.gitRepoService.fetchGitRepos()])
      .pipe(take(1))
      .subscribe(([allGroups, gitReposResp]: [GroupsResp, GetGitReposResp]) => {
        // Get Initially selected groups
        const groupLabels = this.data?.groupLabels ?? [];
        this.groupLabelsInitial = (allGroups?.results ?? [])?.
          filter((group) => groupLabels.some(
            (label) => label.label_key === group?.label_key && label.label_value === group?.label_value));

        // Set up intial Git Repo and all available Git Repositories
        const gitRepos = gitReposResp?.results ?? [];
        const gitRepoInitial = gitRepos.find((gitRepo) => gitRepo.url === this.initialHostGitUrl) ?? null;
        this.orgGitReposSource.next(gitRepos);

        this.buildEditHostForm([...this.groupLabelsInitial], gitRepoInitial);
        this.loadingSource.next(false);
        this.cdr.detectChanges();
      });

    this.handleModalButtonActions();
  }

  editRepo(newRepo: GitRepoComboboxData): void {
    const { selectedHostGitBranch, selectedHostGitPath, selectedHostGitUrl } = this.data;

    this.editHostForm.get('gitRepoConfig').patchValue({
      gitRepoBranch: newRepo.url === selectedHostGitUrl ? selectedHostGitBranch : newRepo.default_branch,
      gitRepoPath: newRepo.url === selectedHostGitUrl ? selectedHostGitPath : '/',
      gitRepoAccessToken: '',
      gitRepoUsername: ''
    });
  }

  toggleEditing(event: Event) {
    this.editGitRepoEnabledSource.next(!this.editGitRepoEnabled);
  }

  private buildEditHostForm(selectedGroups: GroupRespData[], selectedGitRepo: GitRepoResp): void {
    this.editHostForm = this.fb.group({
      gitRepoConfig: this.fb.group({
        gitRepoUrl: new FormControl(selectedGitRepo, [Validators.required]),
        gitRepoBranch: [this.data.selectedHostGitBranch, [Validators.required]],
        gitRepoPath: [this.data.selectedHostGitPath ?? '/', [Validators.required, validateGitRepoPath]],
        gitRepoUsername: new FormControl('', [Validators.required]),
        gitRepoAccessToken: new FormControl('', [Validators.required])
      }),
      groupLabels: new FormControl(selectedGroups)
    });

    this.initialGitUrlFormControl.setValue(selectedGitRepo?.url ?? '');

    this.subs = combineLatest([
      this.groupsService.groupsList,
      this.editHostForm.get('groupLabels').valueChanges.pipe(startWith(selectedGroups))
    ])
      .pipe(
        map(([groups, selectedGroups]: [GroupsResp, GroupRespData[]]) => {
          return groups.results.filter((item) => !(selectedGroups ?? []).
          some((group) => group.label_key === item.label_key && group.label_value === item.label_value));
        })
      )
      .subscribe((groups) => this.orgGroupsExceptAppliedSource.next(groups));

    this.subs = combineLatest([this.editGitRepoEnabled$, this.editHostForm.statusChanges]).subscribe(([editGitRepoEnabled]) => {
      const groupLabelsCtrl = this.editHostForm.get('groupLabels');
      const gitRepoConfigCtrl = this.editHostForm.get('gitRepoConfig');

      if (
        this.editHostForm.dirty &&
        groupLabelsCtrl.valid &&
        (!editGitRepoEnabled || (this.editGitRepoEnabled && gitRepoConfigCtrl.valid))
      ) {
        this.modalRef.buttons.submitButton.disabled = false;
      } else {
        this.modalRef.buttons.submitButton.disabled = true;
      }
    });

    this.editHostForm.markAsPristine();
  }

  private handleModalButtonActions(): void {
    this.modalRef.buttons.submitButton.onClick().subscribe(() => {
      const {
        groupLabels,
        gitRepoConfig: { gitRepoUrl, gitRepoBranch, gitRepoPath, gitRepoUsername, gitRepoAccessToken }
      } = this.editHostForm.getRawValue();

      // Update added group members in array of groups to be updated
      const addedGroupMembers = (groupLabels ?? [])
        .filter((selectedGroup: GroupRespData) => !this.groupLabelsInitial?.
          some((group) => group.label_key === selectedGroup.label_key && group.label_value === selectedGroup.label_value) )
        .map(
          ({ id }: { id: number }): UpdateGroupInfo => ({
            id: id,
            description: '',
            members: {
              add_host_id_list: [this.hostIdentifier],
              remove_host_id_list: []
            }
          })
        );

      // Update deleted group members in array of groups to be updated
      const deletedGroupMembers = this.groupLabelsInitial
        ?.filter((group) => !groupLabels?.
        some((updatedGroup: GroupRespData) => group.label_key === updatedGroup.label_key && group.label_value === updatedGroup.label_value))
        .map(
          ({ id }): UpdateGroupInfo => ({
            id: id,
            description: '',
            members: {
              add_host_id_list: [],
              remove_host_id_list: [this.hostIdentifier]
            }
          })
        );

      const gitRepoActivateBody: CreateGitAssociation =
        this.editGitRepoEnabled && this.editHostForm.get('gitRepoConfig').valid
          ? {
              id: gitRepoUrl.id,
              branch: gitRepoBranch,
              access_token: gitRepoAccessToken,
              username: gitRepoUsername,
              path: gitRepoPath,
              git_config_source: GitConfigSource.Host //TODO: Update this dialog to handle HOST vs SITE selection
            }
          : null;

      this.modalRef.close({
        groupData: [...addedGroupMembers, ...deletedGroupMembers],
        gitRepoData: gitRepoActivateBody,
        hostIdentifier: this.hostIdentifier
      });
    });
  }
}
