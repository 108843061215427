import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { VcoApiService } from '../services/vco-api-service';
import { Subscription } from 'rxjs';
import { SitesService } from '@ecs/ecs-api';

const ORG_OVERRIDE_LS_KEY = 'org-override';

@Injectable({
  providedIn: 'root'
})
export class OrgService {
  private _orgId = new BehaviorSubject<string>('');
  private _enableCrossOrg = new BehaviorSubject<boolean>(false);
  private _crossOrgAvailableForUser = new BehaviorSubject<boolean>(false);

  private _enterpriseId: number;
  private _subscriptionForEnterpriseLogicalId: Subscription;

  private _enterpriseLogicalIdSource = new BehaviorSubject<string>('');
  public enterpriseLogicalId$ = this._enterpriseLogicalIdSource.asObservable();

  // constructor(private oauth2Client: OAuth2Client) {
  constructor(private vcoApiService: VcoApiService, private sitesService: SitesService) {
    const orgId = localStorage.getItem(ORG_OVERRIDE_LS_KEY);
    if (orgId) {
      this.setCurrentOrgId(orgId);
    }
  }

  public setCurrentOrgId(id: string) {
    this._orgId.next(id);
  }

  public setEnterpriseId(value: number, fetchEnterpriseLogicalId: boolean = true) {
    this._enterpriseId = value;
    if (this._subscriptionForEnterpriseLogicalId) {
      this._subscriptionForEnterpriseLogicalId.unsubscribe();
      this._subscriptionForEnterpriseLogicalId = null;
    }
    if (fetchEnterpriseLogicalId) {
      this._subscriptionForEnterpriseLogicalId = this.vcoApiService.getEnterprise(this._enterpriseId).subscribe((details) => {
        this.enterpriseLogicalId = details.logicalId;
      });
    }
  }

  set enterpriseLogicalId(id: string) {
    if (this._enterpriseLogicalIdSource.value != id) {
      this.sitesService.configuration.basePath = `/api/ecs/enterprises/${id}/v0`;
      this._enterpriseLogicalIdSource.next(id);
      this.setCurrentOrgId(id);
    }
  }

  get enterpriseLogicalId(): string {
    return this._enterpriseLogicalIdSource.value;
  }

  public getVcoEnterpriseOrgBaseUrl(): string {
    return `/api/ecs/enterprises/${this.enterpriseLogicalId}/v0/orgs/${this.enterpriseLogicalId}`;
  }

  public setOverrideOrgId(id: string) {
    localStorage.setItem(ORG_OVERRIDE_LS_KEY, id);
    this._orgId.next(id);
  }

  public clearOverrideOrgId() {
    localStorage.removeItem(ORG_OVERRIDE_LS_KEY);
  }

  public overrideOrgIdChecker() {
    if (localStorage.getItem(ORG_OVERRIDE_LS_KEY)) {
      return true;
    }
    return false;
  }

  public disableCrossOrg() {
    this._enableCrossOrg.next(false);
  }

  public enableCrossOrg() {
    this._enableCrossOrg.next(true);
  }

  public setCrossOrgAvailableForUser(value: boolean) {
    this._crossOrgAvailableForUser.next(value);
  }

  public get orgId$() {
    return this._orgId.asObservable();
  }
  public get crossOrgEnabled$() {
    return this._enableCrossOrg.asObservable();
  }
  public get crossOrgAvailableForUser$() {
    return this._crossOrgAvailableForUser.asObservable();
  }
}
