import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { EcsCommonModule } from '@ecs/ecs-common';
import { EcsPlatformModule } from '@ecs/ecs-platform';
import { VIPModule } from '@vmw/ngx-vip';
import { ManageSiteComponent } from './components/manage-site/manage-site.component';
import { SitesListComponent } from './components/sites/sites-list/sites-list.component';
import { ConfigureSitesService } from './configure-sites.service';
import { EcsConfigureSitesRoutingModule } from './ecs-configure-sites-routing.module';
import { SiteDetailsComponent } from './components/sites/site-details/site-details.component';
import { AssociatedHostListComponent } from './components/sites/associated-host-list/associated-host-list.component';
import { SiteComponent } from './components/sites/sites.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EcsCommonModule,
    EcsPlatformModule,
    EcsConfigureSitesRoutingModule,
    VIPModule,
    ClarityModule
  ],
  declarations: [SitesListComponent, ManageSiteComponent, SiteDetailsComponent, AssociatedHostListComponent, SiteComponent],
  providers: [ConfigureSitesService]
})
export class EcsConfigureSitesModule {}
