export const EVENT_LIST_COLUMNS = [
    {
        name: 'description',
        label: 'ecs.operations.events.columns.event',
        field: 'description'
    },
    {
        name: 'event_source',
        label: 'ecs.operation.source',
        field: 'source'
    },
    {
        name: 'event_time',
        label: 'ecs.operation.time',
        field: 'created_at'
    }
];