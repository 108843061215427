import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, forkJoin, iif, map } from 'rxjs';
import { HostsService } from './hosts.service';
import { VmwNgxModalService } from '@vmw/ngx-modal-service';
import { AlertType, CONFIGURE_HOSTS_ROUTE, getAdjustedRoute, KeswickAlertService } from '@ecs/ecs-platform';
import { RemoveHostModalContentComponent } from './../components/remove-host-modal-content/remove-host-modal-content.component';
import { RemoveHostModalContentData } from '../components/remove-host-modal-content/remove-host-modal-content.model';
import { L10nService } from '@vmw/ngx-vip';
import { MODAL_ACCEPT } from '@ecs/ecs-common';
import { HostRegistrationStatus } from '@ecs/ecs-api';

@Injectable({
  providedIn: 'root'
})
export class RemoveHostModalService {
  private successfulHostDeletions = 0;

  private deletingSource = new BehaviorSubject<boolean>(false);

  readonly deleting$ = this.deletingSource.asObservable();

  constructor(
    private hostService: HostsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private router: Router,
    private l10service: L10nService
  ) {}

  openRemoveHostModal(hosts: RemoveHostModalContentData['hosts'], redirect = false): Observable<void> {
    const isSingleHostDeleted = hosts.length === 1;
    const modalRef = this.modalService.openOkCancel(RemoveHostModalContentComponent, {
      title: this.l10service.getMessage('ecs.action.removehost'),
      data: { hosts, isSingleHostDeleted },
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.yes'),
          handler: (ref) => {
            // the modal is closed with 'accept'
            ref.close('accept');
          }
        },
        cancelButton: {
          label: this.l10service.getMessage('ecs.no'),
          handler: (ref) => {
            // the modal is closed with 'deny'
            ref.close('deny');
          }
        }
      }
    });

    return modalRef.onClose().pipe(
      map((result) => {
        let requests: Observable<boolean>[] = [];
        if (result === MODAL_ACCEPT) {
          this.deletingSource.next(true);
          if (isSingleHostDeleted) {
            requests.push(this.removeHost(hosts[0]));
          } else {
            requests = hosts.map((host) => {
              return this.removeHost(host);
            });
          }
        }

        // Wait for all requests to complete
        forkJoin(requests).subscribe((results) => {
          const totalSucceededRequests = results.filter((result) => result).length;
          if (totalSucceededRequests === requests.length) {
            this.alertService.showMessage({
              type: AlertType.success,
              message:
                hosts.length > 1
                  ? this.l10service.getMessage('ecs.removehostmodal.allhostdeletion.success')
                  : this.l10service.getMessage('ecs.removehostmodal.singlehostdeletion.success', [hosts[0].hostName])
            });
          } else {
            this.alertService.showMessage({
              type: AlertType.failure,
              message: this.l10service.getMessage('ecs.removehostmodal.hostdeletion.failure', [
                totalSucceededRequests,
                requests.length - totalSucceededRequests
              ])
            });
          }

          this.deletingSource.next(false);

          this.hostService.getHosts();

          if (redirect) {
              this.router.navigate([getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)]);
          }
        });
      })
    );
  }

  private removeHost(
    { hostSerialNumber, hostIdentifier, hostRegistrationStatus,hostName }: RemoveHostModalContentData['hosts'][0]
  ): Observable<boolean> {
    return iif(
      () => hostRegistrationStatus == HostRegistrationStatus.DeletionPending || hostRegistrationStatus === HostRegistrationStatus.Registered,
      this.hostService.deleteHost(hostIdentifier).pipe(
        map(() => true),
        catchError((err) => {
          this.handleDeleteHostError(err, hostName);
          return [false];
        })
      ),
      this.hostService.deregisterHost(hostIdentifier).pipe(
        map(() => true),
        catchError((err) => {
          this.handleDeleteHostError(err, hostName);
          return [false];
        })
      )
    );
  }

  private handleDeleteHostError(error: any, hostName: string) {
    this.alertService.showMessage({
      type: AlertType.failure,
      message: this.l10service.getMessage('ecs.removehostmodal.deletion.failureerror', [hostName])
    });
  }
}
