export const ENG_ECS_REMOVE_HOST_MODAL_TRANSLATIONS = {
  'ecs.removehostmodal.title': 'You are about to delete hosts with the following Friendly Name',
  'ecs.removehostmodal.inactivehost': 'Inactive hosts will be force deleted from the system. This is irreversible operation.',
  'ecs.removehostmodal.selectedhost':
    'The selected Hosts will be deregistered first and then deleted. ' +
    'Hosts with a “Deletion Pending” or “Inactive” status will be force deleted from the system.',
  'ecs.removehostmodal.selectedhostsfordeletion': 'You have selected <b>{0}</b> hosts for removal.',
  'ecs.removehostmodal.reconfirm': 'Reconfirm by typing the number of Hosts selected to proceed',
  'ecs.removehostmodal.irrevesibleoperation': 'This is <label class="text-error">irreversible</label> operation.'
};
