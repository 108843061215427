import { RouterModule, Routes } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { EventsComponent } from './events/events.component';
import { NgModule } from '@angular/core';
import { EnterpriseRoutes, NavigationState } from '@ecs/ecs-platform';
import { MonitorHostsComponent } from './monitor-hosts/monitor-hosts.component';
import { HostDetailsComponent } from '../../../../apps/ecs-ui/src/app/components/host-details/host-details.component';

const routes: Routes = [
  { path: '', redirectTo: EnterpriseRoutes.inventory, pathMatch: 'full' },
  {
    path: EnterpriseRoutes.alerts,
    component: AlertsComponent,
    data: {
      navigationState: NavigationState.ENTERPRISE_MONITOR
    }
  },
  {
    path: EnterpriseRoutes.events,
    component: EventsComponent,
    data: {
      navigationState: NavigationState.ENTERPRISE_MONITOR
    }
  },
  {
    path: EnterpriseRoutes.inventory,
    children: [
      { path: '', redirectTo: EnterpriseRoutes.hosts, pathMatch: 'full' },
      {
        path: EnterpriseRoutes.hosts,
        component: MonitorHostsComponent,
        data: {
          navigationState: NavigationState.ENTERPRISE_MONITOR
        }
      },
      {
        path: `${EnterpriseRoutes.hostDetails}/:hostId`,
        component: HostDetailsComponent,
        data: {
          navigationState: NavigationState.ENTERPRISE_MONITOR
        }
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EcsMonitorRoutingModule {}
