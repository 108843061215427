<h2>{{ 'ecs.groups.title' | vtranslate }}</h2>
<clr-tabs>
  <clr-tab>
    <button type="button" clrTabLink>{{ 'ecs.groups.tab' | vtranslate }}</button>
    <clr-tab-content class="group-tab-content">
      <app-groups-list></app-groups-list>
    </clr-tab-content>
  </clr-tab>
  <!-- <clr-tab>
    <button clrTabLink>Recommended Groups</button>
  </clr-tab> -->
</clr-tabs>
