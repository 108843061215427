import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Label, SiteResp } from '@ecs/ecs-api';
import { AbstractPaginatableListComponent, Lumos } from '@ecs/ecs-common';
import { SITES_LIST_COLUMNS } from './sites-list.config';
import { RemoveSiteModalService } from '../../../services/remove-site-modal.service';
import { CONFIGURE_ADD_SITE_ROUTE, CONFIGURE_SITES_ROUTE, getAdjustedRoute } from '@ecs/ecs-platform';
import { ConfigureSitesService } from '../../../configure-sites.service';
import { FilterDateRange } from '../../../ecs-configure-sites.models';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'ecs-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RemoveSiteModalService]
})
export class SitesListComponent extends AbstractPaginatableListComponent<SiteResp> {
  readonly ADD_SITE_DOC_LINK = 'https://www.vmware.com';
  readonly columns = SITES_LIST_COLUMNS;
  readonly SortOrder = ClrDatagridSortOrder;

  public updatedAtRangeSrc = new BehaviorSubject<FilterDateRange>({ startDate: '', endDate: '' });
  public updatedAtRangeStart$ = this.updatedAtRangeSrc.pipe(map(range => range.startDate));
  public updatedAtRangeEnd$ = this.updatedAtRangeSrc.pipe(map(range => range.endDate));


  constructor(private configureSitesService: ConfigureSitesService, private removeSiteModalService: RemoveSiteModalService, private router: Router) {
    super(configureSitesService);
    this.subscribeToFilters();
  }

  subscribeToFilters(): void {

    this.subs = this.updatedAtRangeSrc.subscribe(range => {
        var filters: any = [];
        if (range.startDate) {
            filters = [...filters, { property: 'updated_at_range_start', value: range.startDate }];
        }
        if (range.endDate) {
            filters = [...filters, { property: 'updated_at_range_end', value: range.endDate }];
        }
        this.onDatagridRefresh({
            filters
        })
    });
}


  addSite(): void {
    Lumos.trackClickEvent('Site', 'Add Site clicked');
    this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_ADD_SITE_ROUTE));
  }

  editSite(site: SiteResp): void {
    Lumos.trackClickEvent('Site', 'Edit Site clicked');
    this.router.navigate([getAdjustedRoute(this.router, CONFIGURE_SITES_ROUTE),site.id]);
  }

  deleteSite(): void {
    Lumos.trackClickEvent('Site', 'Delete Site clicked');
    this.subs = this.removeSiteModalService.openRemoveSiteModal(this.selectedItems).subscribe(() => {
      this.onRefresh();
    });
  }

  getLabels(site: SiteResp): Label[] {
    return ((site?.labels || [] ).concat(site?.autoGeneratedLabels || []))
  }

   handleFilterDateRange(dateValue: Date, dateType: 'startDate' | 'endDate') {
    const date = new Date(dateValue).toISOString();
    this.updatedAtRangeSrc.next({ ...this.updatedAtRangeSrc.value, [dateType]: date });
}

}

