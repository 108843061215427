
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageSiteComponent } from './components/manage-site/manage-site.component';
import { UnsavedChangesGuard } from '@ecs/ecs-common';
import { SiteComponent } from './components/sites/sites.component';

const routes: Routes = [
    { path: '', component: SiteComponent },
    { path: 'new', component: ManageSiteComponent, canDeactivate: [UnsavedChangesGuard], runGuardsAndResolvers: 'always' },
    { path: ':id', component: ManageSiteComponent, canDeactivate: [UnsavedChangesGuard], runGuardsAndResolvers: 'always' },
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EcsConfigureSitesRoutingModule { }
