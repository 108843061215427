<section class="general-details">
    <table class="table table-noborder">
        <tbody>
            <tr>
                <td class="left field-label">{{ 'ecs.sites.siteDetails.labels.description' | vtranslate }}</td>
                <td class="left">{{ site.description || '-' }}</td>
            </tr>
            <tr>
                <td class="left field-label">{{ 'ecs.sites.siteDetails.labels.gitRepository' | vtranslate }}</td>
                <td class="left">
                    <ng-container *ngIf="site.git_repo_url; else notConfigured">{{ site.git_repo_url }}</ng-container>
                </td>
            </tr>
            <tr>
                <td class="left field-label">{{ 'ecs.sites.siteDetails.labels.branch' | vtranslate }} </td>
                <td class="left">{{ site.git_repo_branch || '-' }}</td>
            </tr>
            <tr>
                <td class="left field-label">{{ 'ecs.sites.siteDetails.labels.path' | vtranslate }}</td>
                <td class="left">{{ site.git_repo_path || '-' }}</td>
            </tr>
        </tbody>
    </table>
</section>

<section>
    <h4>{{ 'ecs.sites.siteDetails.labels.associatedHosts' | vtranslate }}</h4>
    <ecs-associated-host-list [siteId]="site.id"></ecs-associated-host-list>
    <!-- <clr-datagrid>
        <clr-dg-column [clrDgField]="'name'">{{ 'ecs.sites.siteDetails.labels.hostName' | vtranslate }}</clr-dg-column>
        <clr-dg-column [clrDgField]="'ip'">{{ 'ecs.sites.siteDetails.labels.ip' | vtranslate }}</clr-dg-column>
        <clr-dg-column [clrDgField]="'status'">{{ 'ecs.sites.siteDetails.labels.status' | vtranslate }}</clr-dg-column>
        <clr-dg-row *clrDgItems="let host of (associatedHosts$ | async)">
            <clr-dg-cell>{{ host.host_registration_details?.host_serial_number }}</clr-dg-cell>
            <clr-dg-cell>{{ 'IP Address' }}</clr-dg-cell>
            <clr-dg-cell>{{ host.host_health }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-placeholder>
            <div class="empty-placeholder">
                <p class="custom-para">{{ 'ecs.sites.siteDetails.noAssociatedHosts' | vtranslate }}</p>
            </div>
        </clr-dg-placeholder>

        <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
            <div class="footer-actions align-left">
                <a class="refresh-btn btn btn-link" (click)="onRefresh()">
                    <cds-icon shape="refresh" size="14"></cds-icon>
                    <span>{{'ecs.refresh' | vtranslate}}</span>
                </a>
            </div>
            <clr-dg-pagination
              #pagination
              [clrDgPageSize]="paginationInfo?.page?.size"
              [clrDgPage]="paginationInfo?.page?.current"
              [clrDgTotalItems]="totalItems$ | async">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"> {{'ecs.sites.datagrid.pagination.pageSize' | vtranslate}} </clr-dg-page-size>
              {{'ecs.sites.datagrid.pagination.countDetails' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1: (totalItems$ | async)}}
                </clr-dg-pagination>
                <div></div>
          </clr-dg-footer> 
    </clr-datagrid>-->
</section>

<div class="container">
    <section class="">
        <div class="clr-row">
            <div class="clr-col-sm-12 clr-col-md-12">
                <h4>{{ 'ecs.sites.siteDetails.labels.title' | vtranslate }}</h4>
                <div *ngIf="site?.labels?.length>0; else notConfigured" class="tags-container tags-enable-scroll">
                    <label *ngFor="let label of site?.labels" class="label label-light-blue">{{ label.label_key }}
                        <span class="badge">{{ label.label_value }}</span>
                    </label>
                </div>
            </div>
            <div class="clr-col-sm-12 clr-col-md-12 mrg-btm">

                <h4>{{ 'ecs.sites.siteDetails.labels.autogenerated.title' | vtranslate }}</h4>
                <div *ngIf="site?.autoGeneratedLabels?.length>0; else notConfigured"
                    class="tags-container tags-enable-scroll">
                    <label *ngFor="let label of site?.autoGeneratedLabels" class="label label-light-blue">{{
                        label.label_key }}
                        <span class="badge">{{ label.label_value }}</span>
                    </label>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #notConfigured>
    <em>{{ 'ecs.notConfigured' | vtranslate }}</em>
</ng-template>