import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { GitRepoResp } from '@ecs/ecs-api';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { EcsValidators, validateGitRepoPath } from '../../utils/validators';

enum SourceMode {
  CREATE = "create",
  EDIT = "edit"
}
export interface GitRepoConfigModel {
  git_repo_id: number;
  git_branch: string;
  git_path: string;
  git_username: string;
  git_access_token: string;
}

@Component({
  selector: 'ecs-git-repo-config-editor',
  templateUrl: './git-repo-config-editor.component.html',
  styleUrls: ['./git-repo-config-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GitRepoConfigEditorComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GitRepoConfigEditorComponent),
      multi: true
    }
  ]
})
export class GitRepoConfigEditorComponent extends Unsubscriber implements ControlValueAccessor, AfterViewInit {
  private gitReposSource = new BehaviorSubject<GitRepoResp[]>([]);
  private gitRepositories: GitRepoResp[] = []

  private onChange: (_: GitRepoConfigModel) => void;
  private onTouched: () => void;

  form: FormGroup; 
  public gitRepos$ = this.gitReposSource.asObservable();
  public showToken = false;
  public tokenUpdateSuccess = false;

  @Input() mode: SourceMode ;
  @Input()
  set gitRepos(values: GitRepoResp[]) {
    this.gitReposSource.next(values ?? []);
    this.gitRepositories = values
   
     
  }

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    super()
  }

  ngAfterViewInit(): void {
    this.subs = this.form.valueChanges.subscribe(() => {
      const { git_repo_id, ...value }: GitRepoConfigModel = this.form.getRawValue();
      if (git_repo_id === null || String(git_repo_id) === 'null') {
        this.onChange({ git_repo_id: null, git_branch: null, git_path: null, git_username: null, git_access_token: null });
      } else {
        this.onChange({
          ...value,
          git_repo_id: Number(git_repo_id),
        });
      }
      this.onTouched();
    });
    this.form.get('git_repo_id').valueChanges.pipe(distinctUntilChanged()).subscribe((id) => {
     
    })
  }

  writeValue(obj: GitRepoConfigModel): void {
    this.createUpdateForm(obj);
  }

  registerOnChange(fn: (_: GitRepoConfigModel) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }

  updateToken(): void {
    this.showToken = true;
  }
  
  cancelUpdateToken(): void { 
    this.form.get('git_access_token').patchValue(""); 
    this.form.get('git_access_token').markAsPristine();
   
    this.showToken = false;
  }
  
  updateTokenSuccess(): void {      
    if( this.form.get('git_access_token').value ){  
      this.tokenUpdateSuccess=true;
    } 
    this.showToken = false;
    
  }

  private createUpdateForm(value: GitRepoConfigModel): void {
    if (!this.form) {
      this.form = this.fb.group({
        git_repo_id: [value?.git_repo_id, []],
        git_branch: [value?.git_branch, [, EcsValidators.sanitizeString]],
        git_path: [value?.git_path, [validateGitRepoPath, EcsValidators.sanitizeString]],
        git_username: [value?.git_username, []],
        git_access_token: [value?.git_access_token, []]
      });

      this.subs = this.form.get('git_repo_id').valueChanges.subscribe((value) => {
        if (String(value) === 'null') {
          this.form.get('git_branch').clearValidators();
          this.form.get('git_path').clearValidators();
          this.form.get('git_username').clearValidators();
          this.form.get('git_access_token').clearValidators();
          this.form.get('git_branch').reset(null, { emitEvent: false, onlySelf: true });
          this.form.get('git_path').reset(null, { emitEvent: false, onlySelf: true });
          this.form.get('git_username').reset(null, { emitEvent: false, onlySelf: true });
          this.form.get('git_access_token').reset(null, { emitEvent: false, onlySelf: true });
        } else {
          this.form.get('git_branch').setValidators([Validators.required, EcsValidators.sanitizeString]);
          this.form.get('git_path').setValidators([Validators.required, validateGitRepoPath, EcsValidators.sanitizeString]);
          this.form.get('git_username').setValidators([Validators.required]);
          if(this.mode!==SourceMode.EDIT){
            this.form.get('git_access_token').setValidators([Validators.required]);
          }
        }
        this.form.patchValue({
          git_branch: this.gitRepositories.filter(gitRepo => gitRepo.id === Number(value))?.shift()?.default_branch,
          git_path: '/',
        });
        this.form.setErrors(null)
        this.form.get('git_branch').updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.form.get('git_path').updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.form.get('git_username').updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.form.get('git_access_token').updateValueAndValidity({ emitEvent: false, onlySelf: true });
      });
    } else {
      this.form.reset(value, { emitEvent: false, onlySelf: true });
    }
  }
}
