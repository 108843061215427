export const ENG_ECS_SERVICE_TRANSLATIONS = {
  'ecs.addgitrepomodal.updategitrepository': 'Update Git Repository',
  'ecs.addgitrepomodal.registergitrepository': 'Register a Git Repository',
  'ecs.addgitrepomodal.update.success': 'The gitrepo has been updated successfully!',
  'ecs.addgitrepomodal.add.success': 'The gitrepo has been added successfully!',
  'ecs.addgitrepomodal.actionlabel': 'Git repository List',
  'ecs.addhostmodal.dormanthostadded': 'Host has been added successfully!',
  'ecs.addhostmodal.dormanthostlist': 'Host List',
  'ecs.addhosttogroupmodal.Ok': 'Ok',
  'ecs.addhosttogroupmodal.success': 'The group has been created successfully!',
  'ecs.error.incorrectbatchregistrationdata': 'Incorrect batch registration data received',
  'ecs.error.generalerror': 'A client-side or network error occurred',
  'ecs.edithost': 'Edit Host | "{0}"',
  'ecs.edithost.groupupdationsuccess': 'Group update process has been triggered successfully',
  'ecs.edithost.reactivate': 'Host has been re-activated!',
  'ecs.failedtoloadevents': 'Failed to load events',
  'ecs.removegroup': 'Remove Group',
  'ecs.removegroup.success': 'All the selected groups have been removed successfully!',
  'ecs.removegroup.failure': 'Failed to remove some/all of the selected groups successfully',
  'ecs.removehostmodal.allhostdeletion.success': 'All the selected hosts have been deleted or marked for deletion!',
  'ecs.removehostmodal.singlehostdeletion.success': 'The host with friendly name "{0}" has deleted or marked for deletion!',
  'ecs.removehostmodal.hostdeletion.failure': '"{0}" hosts deleted. However, "{1}" hosts failed to delete.',
  'ecs.removehostmodal.deletion.failureerror': 'Failed to remove host with friendly name "{0}"',
  'ecs.reboothostmodal.failure': 'Failed to reboot host with friendly name "{0}"',
  'ecs.reboothostmodal.success': 'Reboot requested for host with friendly name "{0}"',
  'ecs.updatehosttogroupmodal.failed': 'Failed to updated group.',
};
