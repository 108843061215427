<div class="modal-body" id="addHostsToGroupModal">
  <p class="modal-title">
    {{'ecs.addtogroup.title' | vtranslate}}
     <a class="link" [href]="ADD_HOST_TO_GROUP_DOC_LINK" target="_blank" rel="noopener noreferrer">{{'ecs.learnmore' | vtranslate}}</a>
  </p>
  <form clrForm [formGroup]="addHostsToGroupForm" clrLayout="horizontal" autocomplete="off">
    <clr-input-container class="add-hosts-to-group-input-container">
      <label>{{'ecs.addtogroup.hostselected' | vtranslate}}</label>
      <input focusVisible clrInput class="clr-col-8 clr-col-sm-7 full-width-input no-border" [formControl]="hostCountControl" readonly />
    </clr-input-container>
    <clr-input-container class="add-hosts-to-group-input-container">
      <label>{{'ecs.addtogroup.groupdescription' | vtranslate}}</label>
      <input
        focusVisible
        clrInput
        class="clr-col-8 clr-col-sm-7 full-width-input"
        formControlName="description"
        placeholder="{{'ecs.addtogroup.groupdescription.placeholder' | vtranslate}}" />
    </clr-input-container>
    <div class="clr-row">
      <div class="clr-col-8">
        <clr-combobox-container class="key-value-combobox-container" *ngIf="!addEnabled">
          <label class="clr-control-label clr-col-12 clr-col-md-3">
            {{'ecs.addtogroup.grouplabel' | vtranslate}}
            <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add Group Modal - Group Label Info Circle Hover')">
              <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
              <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                <span>{{'ecs.addtogroup.grouplabel.tooltip' | vtranslate}}</span>
              </clr-tooltip-content>
            </clr-tooltip>
          </label>
          <clr-combobox
            class="clr-col-12 clr-col-md-9"
            formControlName="label"
            required
            placeholder="{{'ecs.keyvalue' | vtranslate}}"
            (clrInputChange)="onLabelInput($event)">
            <clr-options class="key-value-options">
              <clr-option *clrOptionItems="let group of groupsResp" [clrValue]="group">{{group |  ecsLabel}}</clr-option>
            </clr-options>
          </clr-combobox>
          <!-- Description -->
          <clr-control-helper class="group-label-control-helper">{{'ecs.addtogroup.grouplabel.helper' | vtranslate}}</clr-control-helper>
        </clr-combobox-container>
        <clr-input-container class="group-label-key-input-container" *ngIf="addEnabled">
          <label>
            {{'ecs.addtogroup.grouplabel.key' | vtranslate}}
            <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add Group Modal - Group Label Info Circle Hover')">
              <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
              <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                <span>{{'ecs.addtogroup.grouplabel.key.tooltip' | vtranslate}}</span>
              </clr-tooltip-content>
            </clr-tooltip>
          </label>
          <input
            focusVisible
            clrInput
            class="clr-col-12 clr-col-md-9"
            formControlName="labelKey"
            name="newGroupLabelKey"
            [required]="!addEnabled"
            placeholder="{{'ecs.addtogroup.grouplabel.key.placeholder' | vtranslate}}" />
        </clr-input-container>
      </div>
      <div class="clr-col-4">
        <clr-control-container>
          <button class="btn btn-sm btn-link add-group-button" (click)="toggleAddGroup($event)">
            {{ addEnabled ? ('ecs.cancel' | vtranslate) : ('ecs.add' | vtranslate) }}
          </button>
        </clr-control-container>
      </div>
    </div>
    <clr-input-container class="add-hosts-to-group-input-container" *ngIf="addEnabled">
      <label>
        {{'ecs.addtogroup.grouplabel.value' | vtranslate}}
        <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add Group Modal - Group Label Info Circle Hover')">
          <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
          <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>{{'ecs.addtogroup.grouplabel.value.tooltip' | vtranslate}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </label>
      <input
        focusVisible
        clrInput
        class="clr-col-8 clr-col-sm-7 full-width-input"
        formControlName="labelValue"
        name="newGroupLabelValue"
        [required]="!addEnabled"
        placeholder="{{'ecs.addtogroup.grouplabel.value.placeholder' | vtranslate}}" />
    </clr-input-container>
  </form>
</div>
