import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { EventsComponent } from './events/events.component';
import { VIPModule } from '@vmw/ngx-vip';
import { ClarityModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { EcsMonitorRoutingModule } from './ecs-monitor-routing.module';
import { MonitorHostsComponent } from './monitor-hosts/monitor-hosts.component';
import { MonitorHostFilterComponent } from './monitor-hosts/filters/monitor-filters.component';
import { VmwComponentsModule } from '@vmw/ngx-components';
import { EcsCommonModule } from '@ecs/ecs-common';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    VIPModule,
    EcsMonitorRoutingModule,
    EcsCommonModule,
    FormsModule,
    VmwComponentsModule,
    EcsCommonModule,
  ],
  declarations: [
    AlertsComponent,
    EventsComponent,
    MonitorHostsComponent,
    MonitorHostFilterComponent,
  ],
  exports: [
    AlertsComponent,
    EventsComponent,
    MonitorHostsComponent,
    MonitorHostFilterComponent,
  ]
})
export class EcsMonitorModule {}
