<div>
  <p>{{'ecs.groupremovemodal.subtitle' | vtranslate}}</p>
  <ul>
    <li *ngFor="let group of selectedGroups">
      <strong>{{ group | ecsLabel }}</strong>
    </li>
    <br />
  </ul>
</div>
<div>{{'ecs.groupremovemodal.body' | vtranslate}}</div>
<div>{{'ecs.groupremovemodal.confirm' | vtranslate}}</div>
