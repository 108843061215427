export const ENG_ECS_GITREPO_LIST_TRANSLATIONS = {
  'ecs.gitrepolist.column.repositoryid': 'Repository ID',
  'ecs.gitrepolist.column.repositoryname': 'Repository Name',
  'ecs.gitrepolist.column.hosts': 'Hosts',
  'ecs.gitrepolist.footer': 'Git Repositories per page',
  'ecs.gitrepolist.remove.confirmation': 'You are about to remove git repositories with the following URL',
  'ecs.gitrepolist.remove.alert':
    'Cannot delete selected git repositories because hosts are associated with one or more selected git repos!',
  'ecs.gitrepolist.pagination': '{0} - {1} of {2} Git Repos',
  'ecs.gitrepolist.modal.title': 'Remove Git Repository',
  'ecs.gitrepolist.modal.success': 'All the selected git repos have been removed successfully',
  'ecs.gitrepolist.modal.failure': 'Failed to remove some/all of the selected gitrepos successfully',
  'ecs.gitrepo.noRepoMessageTitle': 'Currently you have no git repositories configured.',
  'ecs.gitrepo.noRepoMessageSubtitle': 'Learn more on how to register Git repositories and manage them in Edge Compute'
};
