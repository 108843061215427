import { Component, Inject } from '@angular/core';
import { HostResp } from '@ecs/ecs-api';
import { MODAL_DATA } from '@vmw/ngx-modal-service';

@Component({
  selector: 'app-reboot-host-modal-content',
  templateUrl: './reboot-host-modal-content.component.html',
  styleUrls: ['./reboot-host-modal-content.component.scss']
})
export class RebootHostModalContentComponent {
  hostSerialNumber: string;

  constructor(@Inject(MODAL_DATA) data: HostResp) {
    this.hostSerialNumber = data.name;
  }
}
