import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take } from 'rxjs';
import { CreateGitRepoInfo, UpdateGitRepoInfo } from '../git-repo/gitrepos-list/gitrepos-list.model';
import { OrgService } from '@ecs/ecs-platform';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { GetGitReposResp } from '@ecs/ecs-api';
import { handleError } from '@ecs/ecs-common';

@Injectable({
  providedIn: 'root'
})
export class GitReposService {
  private readonly urlPrefix = '/api/ecs/enterprises';
  private readonly urlSuffix = 'v0/orgs/';
  orgID: string;
  gitRepoList = new BehaviorSubject<GetGitReposResp['results']>([]);

  constructor(private http: HttpClient, private orgService: OrgService) {
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  fetchGitRepos(): Observable<GetGitReposResp> {
    return this.http.get<GetGitReposResp>(`${this.getUrl()}`, {}).pipe(catchError(handleError));
  }

  getGitRepoList() {
    this.orgService.enterpriseLogicalId$.pipe(
      filter( (enterpriseLogicalId) => !!enterpriseLogicalId && enterpriseLogicalId.length > 0),
      take(1),
      switchMap(() => this.fetchGitRepos())
    ).subscribe((hostsResp) => {
      this.gitRepoList.next(hostsResp.results ?? []);
    });
  }

  createGitRepo(gitrepo: any): Observable<CreateGitRepoInfo> {
    return this.http
      .post<CreateGitRepoInfo>(`${this.getUrl()}`, JSON.stringify(gitrepo), this.httpOptions)
      .pipe(catchError(handleError));
  }

  gitUrlValidator(control: AbstractControl): ValidationErrors | null {
    const gitUrlRegex = /^(https?:\/\/)([\w-]+(?:\.[\w-]+)*)(\/[\w./?%&=-]+)+$/;
    if (control.value && !gitUrlRegex.test(control.value)) {
      return { isUrlInvalid: true };
    }

    return null;
  }

  updateGitRepo(gitRepoUpdateData: any, gitRepoID: any): any {
    return this.http
      .put<UpdateGitRepoInfo>(`${this.getUrl()}/${gitRepoID}`, JSON.stringify(gitRepoUpdateData), this.httpOptions)
      .pipe(catchError(handleError));
  }

  deleteGitRepo(gitRepoID: number): Observable<any> {
    return this.http.delete<any>(`${this.getUrl()}/${gitRepoID}`, {}).pipe(catchError(handleError));
  }

  private getUrl(): string {
    const prefixUrl = this.orgService.getVcoEnterpriseOrgBaseUrl();
    return `${prefixUrl}/gitrepos`;
  }
}
