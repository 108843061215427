import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { AssociatedHostListService } from './associated-host-list.service';
import { AbstractPaginatableListComponent } from '@ecs/ecs-common';
import { HostResp, HostHealthStatus } from '@ecs/ecs-api';

@Component({
  selector: 'ecs-associated-host-list',
  templateUrl: './associated-host-list.component.html',
  styleUrls: ['./associated-host-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AssociatedHostListService
  ]
})
export class AssociatedHostListComponent extends AbstractPaginatableListComponent<HostResp> implements OnInit {

  public readonly HostHealthStatus = HostHealthStatus;
  readonly SortOrder = ClrDatagridSortOrder;

  public statusList = [HostHealthStatus.Unknown, HostHealthStatus.Warning, HostHealthStatus.Ok];
  public checkboxStates: { [status: string]: boolean } = {};

  @Input() 
  set siteId(value: string) {
    this.associatedHostListService.siteId = value;
  }

  constructor(private associatedHostListService: AssociatedHostListService) {
    super(associatedHostListService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  handleStatusCheckboxChange(status: string, isChecked: boolean) {
    this.checkboxStates[status] = isChecked;
    const selectedStatusList = [...this.statusList].filter(status => this.checkboxStates[status]);
    const statusParam = selectedStatusList.length > 0 ? selectedStatusList.join(',') : selectedStatusList;
    this.onDatagridRefresh({filters:[{property:'host_health', value:statusParam}]});
  }

}
