import { FormControl, FormGroup } from "@angular/forms";
import { Label } from "@ecs/ecs-api";

export interface ManageSiteRouteParams {
    id?: string;
}

export interface ManageSiteFormValues {
    siteDetails: {
        name: string;
        description: string;
        location: string;
        isSiteDetailsValid: boolean;
    },
    gitDetails: {
        git_repo_id: number;
        git_repo_branch: string;
        git_repo_path: string;
        git_repo_username: string;
        git_repo_access_token: string;
    },
    tagsDetails: {
        tags: Label[];
    }
}

export type ManageSiteDetailsForm = {
    [K in keyof ManageSiteFormValues['siteDetails']]: FormControl<ManageSiteFormValues['siteDetails'][K]>
}

export type ManageSiteGitDetailsForm = {
    [K in keyof ManageSiteFormValues['gitDetails']]: FormControl<ManageSiteFormValues['gitDetails'][K]>
}

export type ManageSiteTagsForm = {
    [K in keyof ManageSiteFormValues['tagsDetails']]: FormControl<ManageSiteFormValues['tagsDetails'][K]>
}

// Define new type where key will be from ManageSiteFormValues and value will be FormControl<ManageSiteFormValues[key]>
export type ManageSiteForm = {
    siteDetails: FormGroup<ManageSiteDetailsForm>;
    gitDetails: FormGroup<ManageSiteGitDetailsForm>;
    tagsDetails: FormGroup<ManageSiteTagsForm>;
}

export const DEFAULT_SITE_NAME = 'default';

export interface FilterDateRange {
    startDate: string;
    endDate: string;
}
