import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import * as _ from 'lodash';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { EcsValidators } from '../../utils/validators';
import { TagForm, TagsForm } from './tags-editor.models';
import { Label } from '@ecs/ecs-api';


@Component({
  selector: 'ecs-tags-editor',
  templateUrl: './tags-editor.component.html',
  styleUrls: ['./tags-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsEditorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TagsEditorComponent),
      multi: true
    },
  ]
})
export class TagsEditorComponent extends Unsubscriber implements ControlValueAccessor, AfterViewInit {
  private onChange: (value: Label[]) => void;
  private onTouched: () => void;

  formArray: TagsForm;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.subs = this.formArray.valueChanges.subscribe((value) => {
      if (!this.onChange || !this.onTouched) {
        return;
      }

      this.onChange((value as unknown as Label[]).filter((label)=> label && label.label_key && label.label_value))

      this.onTouched();
    });

    this.subs = this.formArray.statusChanges.subscribe(() => {
      if (this.onTouched) {
        this.onTouched();
      }
    });
  }

  registerOnChange(fn: (value: Label[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(): { [key: string]: boolean } | null {
    return this.formArray.valid ? null : { invalid: true };
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formArray?.disable() : this.formArray?.enable();
  }

  writeValue(values: Label[]): void {
    const tagValues = (!values?.length) ? [{label_key: '', label_value: ''}]: values;
    //const formValues = this.fromValuesToFormValues(tagValues);
    this.createForm(tagValues);
  }

  removeTag(index: number): void {
    this.formArray.removeAt(index);
    if (this.formArray.controls.length === 0) {
      this.formArray.push(this.createTagForm({ label_key: '', label_value: '' }), { emitEvent: false });
      this.formArray.reset();
      this.formArray.controls.forEach((control) => {
        control.markAsPristine();
        control.markAsUntouched();
      });
    }
  }

  addTag(): void {
    this.formArray.insert(0, this.createTagForm({ label_key: '', label_value: '' }));
  }

  private createForm(values: Label[]): void {
    if (!this.formArray) {
      this.formArray = new FormArray(values.map((value) => this.createTagForm(value)));
    } else {
      this.formArray.clear();
      values.map((value) => this.createTagForm(value)).forEach((form) => this.formArray.push(form));
    }
  }

  private createTagForm({ label_key, label_value }: Label): TagForm {
    return new FormGroup(
      {
        label_key: new FormControl(label_key),
        label_value: new FormControl(label_value)
      },
      [this.validateTagForm()]
    );
  }

  private validateTagForm(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      const tagForm = control as TagForm;
      const formArray = tagForm.parent.controls as TagForm[];

      const { label_key, label_value } = tagForm.value;

      if (label_key && label_value) {
        this.validateDuplicateTags(tagForm, formArray);
      }

      if (formArray.length === 1 && !label_key && !label_value) {
        return null;
      }

      if (tagForm.pristine) {
        return null;
      }

      const keyErrors = EcsValidators.validateTagLabelValue(tagForm.controls.label_key, EcsValidators.Labels.key);
      const valueErrors = EcsValidators.validateTagLabelValue(tagForm.controls.label_value, EcsValidators.Labels.value);

      if (!keyErrors && !valueErrors) {
        return null;
      }

      Array<[FormControl<string>, ValidationErrors]>([tagForm.controls.label_key, keyErrors], [tagForm.controls.label_value, valueErrors]).forEach(
        ([formCtrl, errors]) => {
          formCtrl.setErrors(errors, { emitEvent: true });
          formCtrl.markAllAsTouched();
        }
      );

      return keyErrors || valueErrors;
    };
  }

  validateDuplicateTags(tagForm: TagForm, formArray: Array<TagForm>): void {
    const tagsList: Array<object> = [];
    formArray.map((item: TagForm) => {
      tagsList.push(item.value);
    })
    const uniqueTags = _.uniqWith(tagsList, _.isEqual);
    if (formArray.length !== uniqueTags.length) {
      tagForm.get('label_key').setErrors({duplicateTags: true}, {emitEvent: true});
      tagForm.get('label_value').setErrors({duplicateTags: true}, {emitEvent: true});
    } else {
      tagForm.get('label_key').setErrors(null);
      tagForm.get('label_value').setErrors(null);
    }
  }
}
