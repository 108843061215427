import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '@ecs/ecs-api';

@Pipe({
  name: 'ecsLabel'
})
export class LabelFormatterPipe implements PipeTransform {
  transform(label: Label): string {
    if (!label || !label.label_key || !label.label_value) {
      return ''; // or any default value you want to return for missing data
    }

    return `${label.label_key}:${label.label_value}`;
  }
}