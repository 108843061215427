<section *ngIf="(sections$ | async) as sections" class="section">
   <clr-vertical-nav [clrVerticalNavCollapsible]="true" (clrVerticalNavCollapsedChange)="onCollapsedChange($event)">
      <ng-container *ngIf="sections.length">
         <ecs-sidenav-section 
            *ngFor="let section of sections; trackBy:trackByName" 
            [section]="section"
            [collapsed]="collapsed"
            (linkSelected)="onLinkSelected($event)">
         </ecs-sidenav-section>
      </ng-container>
   </clr-vertical-nav>
</section>
