<div class="add-host-site-details">
    <p>
        {{'ecs.addhost.sitedetails.title' | vtranslate}}
    </p>
    <form class="site-details-form" clrForm [formGroup]="addHostSiteDetailsForm" clrLayout="horizontal"
        autocomplete="off">
        <clr-combobox-container>
            <label class="clr-control-label clr-col-12 clr-col-md-4">{{'ecs.addhost.sitedetails.selectsite'
                |vtranslate}}</label>
            <clr-combobox class="clr-col-12 clr-col-md-8" formControlName="site" required (clrSelectionChange)="onSelectionChanged($event)"
                placeholder="{{'ecs.addhost.sitedetails.selectsite'| vtranslate}}">
                <clr-options class="clr-options">
                    <clr-option *clrOptionItems="let site of sites" [clrValue]="site.name">{{ site.name }}</clr-option>
                </clr-options>
            </clr-combobox>
            <clr-control-error>{{'ecs.requiredField' | vtranslate}}!</clr-control-error>
        </clr-combobox-container>
    </form>
</div>