import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { GlobalConst } from '../../lib.consts';

export const MAX_PHONE_NUMBER_LENGTH = 15;
export const MAX_TAG_LABEL_VALUE_LENGTH = 50;

export type ValidatorFn = (control: AbstractControl) => ValidationErrors | null;

export enum ValidationError {
  required = 'required',
  invalid = 'invalid',
  unique = 'unique',
  sanitize = 'sanitize',
  invalidPhoneNumber = 'invalidPhoneNumber',
  invalidCharacters = 'invalidCharacters',
  invalidLabelValuePattern = 'invalidLabelValuePattern',
  invalidLabelKeyPattern = 'invalidLabelKeyPattern',
  maxLength = 'maxLength',
}

export enum Labels {
  key = 'key',
  value = 'value',
}

export function sanitizeString({ value }: AbstractControl) {
  const expr = new RegExp('[><]', 'm');

  return expr.test(value) ? { [ValidationError.sanitize]: value } : null;
}

export function phoneNumber(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  const expr = new RegExp('[^0-9+]', 'm');

  const error = expr.test(value);

  if (error) {
    return { [ValidationError.invalidPhoneNumber]: value };
  }

  return Validators.maxLength(MAX_PHONE_NUMBER_LENGTH)(control);
}

export function requiredWhen<T>(otherControlName: string, requiredOtherControlValue: T) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control.parent && control.parent.get(otherControlName))) return null;

    const otherControl = control.parent.get(otherControlName);
    if (otherControl && otherControl.value === requiredOtherControlValue) {
      return Validators.required(control);
    }
    return null;
  };
}

export function validateTagLabelValue(control: AbstractControl, labelType: string): ValidationErrors | null {
  const { value } = control;
  const expr = new RegExp(labelType === Labels.key ? GlobalConst.patterns.labelKey : GlobalConst.patterns.labelValue);

  if (!value) {
    return Validators.required(control);
  }

  if (!expr.test(value)) {
    return { [labelType === Labels.key ? ValidationError.invalidLabelKeyPattern : ValidationError.invalidLabelValuePattern]: value };
  }

  return Validators.maxLength(MAX_TAG_LABEL_VALUE_LENGTH)(value);
}