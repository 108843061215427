<div class="modal-body update-group-modal" id="updateGroupModal">
  <form clrForm [formGroup]="updateGroupForm" clrLayout="horizontal" autocomplete="off">
    <clr-input-container class="update-group-input-container">
      <label>{{'ecs.groupupdate.grouplabel' | vtranslate}}</label>
      <label>
        <span class="label groupLabel">{{ groupLabel | ecsLabel }}</span>
      </label>
    </clr-input-container>
    <clr-input-container class="update-group-input-container">
      <label>{{'ecs.groupupdate.groupdescription' | vtranslate}}</label>
      <input focusVisible clrInput formControlName="description" [(ngModel)]="description" [value]="description" />
    </clr-input-container>
    <clr-combobox-container class="update-group-combobox-container">
      <label>{{'ecs.groupupdate.groupmembers' | vtranslate}}</label>
      <clr-combobox
        (ngModelChange)="onGroupMembersChange($event)"
        class="test"
        formControlName="memberSelection"
        name="multiSelect"
        clrMulti="true">
        <ng-container *clrOptionSelected="let selected">
          {{ selected }}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let host of hostsList; field: 'host'"
            [clrValue]="host.host_registration_details?.host_serial_number">
            {{ host.host_registration_details?.host_serial_number }}
          </clr-option>
        </clr-options>
      </clr-combobox>
      <clr-control-helper class="update-group-control-error">{{'ecs.groupupdate.groupmembers.addremove' | vtranslate}}</clr-control-helper>
    </clr-combobox-container>
  </form>
</div>
