<div class="add-host-content">
  <p>
    {{'ecs.addhostmodal.title' | vtranslate}}
  </p>
  <!--Custom Vendor & Model notification-->
  <clr-alert *ngIf="vendorAdded && modelAdded" [clrAlertType]="'success'">{{'ecs.addhostmodal.success' |
    vtranslate}}!!</clr-alert>

  <div class="clr-row activationType">
    <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.activationType' |
      vtranslate}}</label>
    <clr-dropdown class="download-button clr-col-12 clr-col-md-8">
      <button class="btn btn-primary" clrDropdownTrigger>
        {{ selectedActivationType }}
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu class="dropdown-menu" clrPosition="bottom-left" *clrIfOpen>
        <div (click)="activationTypeChanged(hostRegistrationTypeEnum.HARDWARE_INFO)" clrDropdownItem
          [clrDisabled]="isModeEdit">
          {{'ecs.addhostmodal.hostdetails.withHardwareInfo' | vtranslate}}</div>
        <div (click)="activationTypeChanged(hostRegistrationTypeEnum.ACTIVATION_KEY)" clrDropdownItem
          [clrDisabled]="isModeEdit">
          {{'ecs.addhostmodal.hostdetails.withActivationKey' | vtranslate}}</div>
        <div (click)="activationTypeChanged(hostRegistrationTypeEnum.BULK_UPLOAD)" clrDropdownItem
          [clrDisabled]="isModeEdit">
          {{'ecs.addhostmodal.hostdetails.withBulkUpload' | vtranslate}}</div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <form clrForm [formGroup]="addHostForm" clrLayout="horizontal" autocomplete="off">
    <div *ngIf="hostDetailType.value === hostRegistrationTypeEnum.HARDWARE_INFO">
      <clr-combobox-container class="vendor-model-combobox-container" ecsFormControlContainer>
        <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.vendor' |
          vtranslate}}</label>
        <clr-combobox class="select-box clr-col-12 clr-col-md-8" formControlName="vendor" required
          placeholder="{{'ecs.addhostmodal.hostdetails.vendor.placeholder' | vtranslate}}"
          (clrInputChange)="onVendorInput($event)">
          <clr-options class="vendor-model-options">
            <clr-option *clrOptionItems="let vendor of supportedHostVendors" [clrValue]="vendor">{{ vendor
              }}</clr-option>
            <clr-option (click)="AddNewVendorModel('vendor')">{{'ecs.addhostmodal.hostdetails.vendor.addnew' |
              vtranslate}}</clr-option>
          </clr-options>
        </clr-combobox>
        <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
      </clr-combobox-container>
      <clr-combobox-container class="vendor-model-combobox-container" ecsFormControlContainer>
        <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.model' |
          vtranslate}}</label>
        <clr-combobox class="select-box clr-col-12 clr-col-md-8" formControlName="model" required
          placeholder="{{'ecs.addhostmodal.hostdetails.model.placeholder'| vtranslate}}"
          (clrInputChange)="onModelInput($event)">
          <clr-options class="vendor-model-options">
            <clr-option *clrOptionItems="let model of supportedHostModels" [clrValue]="model">{{ model }}</clr-option>
            <clr-option (click)="AddNewVendorModel('model')">{{'ecs.addhostmodal.hostdetails.model.addnew'|
              vtranslate}}</clr-option>
          </clr-options>
        </clr-combobox>
        <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
      </clr-combobox-container>
      <clr-input-container ecsFormControlContainer>
        <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.serialnumber'|
          vtranslate}}</label>
        <input clrInput class="serial-input clr-col-12 clr-col-md-8"
          placeholder="{{'ecs.addhostmodal.hostdetails.serialnumber.placeholder'| vtranslate}}" name="serialNumber"
          formControlName="serialNumber" required />
        <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
      </clr-input-container>
    </div>

    <div *ngIf="hostDetailType.value === hostRegistrationTypeEnum.BULK_UPLOAD" class="file-input-container">
      <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.importcsv'|
        vtranslate}}</label>
      <div class="clr-col-12 clr-col-md-8">
        <input type="file" class="file-input" accept=".csv" (change)="onFileSelected($event)" name="csvFile"
          #fileUpload />
        <div class="file-upload">
          <button class="btn btn-primary btn-sm " (click)="fileUpload.click()"
            *ngIf="!fileName">{{'ecs.addhostmodal.hostdetails.importcsv.import' | vtranslate}}</button>
          <a [href]="filePath" [download]="fileName" *ngIf="fileName">
            <cds-icon style="margin-right: 8px" shape="file"></cds-icon>{{ fileName }}
          </a>
          <button style="margin-left: 16px" class="btn btn-primary btn-sm" (click)="fileUpload.click()"
            *ngIf="fileName">{{'ecs.addhostmodal.hostdetails.importcsv.replace' | vtranslate}}</button>
        </div>
      </div>
    </div>

    <div *ngIf="hostDetailType.value === hostRegistrationTypeEnum.ACTIVATION_KEY">
      <clr-input-container ecsFormControlContainer>
        <label
          class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.activationKey'|
          vtranslate}}</label>
        <input clrInput class="activationKeyInput clr-col-12 clr-col-md-8"
          placeholder="{{'ecs.addhostmodal.hostdetails.activationKey.placeholder'| vtranslate}}" name="activationKey"
          formControlName="activationKey" required />
        <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
      </clr-input-container>
    </div>
  </form>

  <form clrForm [formGroup]="hostNameFormGroup" clrLayout="horizontal" autocomplete="off">
    <div
      *ngIf="hostDetailType.value === hostRegistrationTypeEnum.ACTIVATION_KEY || hostDetailType.value === hostRegistrationTypeEnum.HARDWARE_INFO">
      <clr-input-container ecsFormControlContainer>
        <label class="clr-control-label clr-col-12 clr-col-md-4 required">{{'ecs.addhostmodal.hostdetails.name'|
          vtranslate}}
          <clr-tooltip>
            <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
            <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
              <span>{{'ecs.addhostmodal.hostdetails.name.tooltip' | vtranslate}}</span>
            </clr-tooltip-content>
          </clr-tooltip>
        </label>
        <input clrInput class="activationKeyInput clr-col-12 clr-col-md-8"
          placeholder="{{'ecs.addhostmodal.hostdetails.name.placeholder'| vtranslate}}" name="activationKey"
          formControlName="name" required />
        <clr-control-error><ecs-form-control-errors></ecs-form-control-errors></clr-control-error>
      </clr-input-container>
    </div>
  </form>

</div>