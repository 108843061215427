import { ChangeDetectorRef, Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { OkCancel, VmwNgxModalRef } from '@vmw/ngx-modal-service';
import { HostsService } from '../../services/hosts.service';
import { ADD_HOST_TO_GROUP_DOC_LINK, Lumos } from '@ecs/ecs-common';
import { Label } from '@ecs/ecs-api';
import { GroupRespData } from '@ecs/ecs-platform';

@Component({
  selector: 'app-add-to-group-modal',
  templateUrl: './add-to-group-modal.component.html',
  styleUrls: ['./add-to-group-modal.component.scss']
})
export class AddToGroupModalComponent implements OnInit {
  static readonly valid = 'VALID';
  public addHostsToGroupForm: FormGroup;
  public hostCountControl: FormControl = new FormControl();
  public groupsResp: GroupRespData[] = [];
  public newGroupLabelKey: string;
  public newGroupLabelValue: string;
  public addEnabled = false;
  private groupLabels: Label[] = [];
  private groupLabelsResp: any;
  private isExistingGroupLabel = true;
  private selectedGroupId = 0;
  public readonly ADD_HOST_TO_GROUP_DOC_LINK = ADD_HOST_TO_GROUP_DOC_LINK;

  @Input() addHostsToGroupModal = false;

  constructor(
    private modalRef: VmwNgxModalRef<OkCancel>,
    private fb: FormBuilder,
    private hostsService: HostsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildAddHostToGroupForm();
    this.hostCountControl.setValue(this.hostsService.getSelectedHosts().length);

    // Enable Confirm button if the form is valid, disable otherwise
    this.addHostsToGroupForm.controls['labelKey'].valueChanges.subscribe((value) => {
      this.newGroupLabelKey = value;
      this.validateGroupLabel();
    });

    this.addHostsToGroupForm.controls['labelValue'].valueChanges.subscribe((value) => {
      this.newGroupLabelValue = value;
      this.validateGroupLabel();
    });

    this.addHostsToGroupForm.controls['label'].valueChanges.subscribe((value) => {
      this.newGroupLabelValue = this.newGroupLabelKey = '';
      this.isExistingGroupLabel = true;
      this.validateGroupLabel();
    });

    // Call GetGroup Labels API to fetch list of labels
    this.groupLabelsResp = this.hostsService.getGroupLabels().subscribe((res) => {
      this.groupsResp = res.results;
      if (this.groupsResp != null) {
        this.groupsResp.forEach((value, key) => {
          this.groupLabels.push({label_key: value.label_key, label_value: value.label_value});
        });
      }
    });

    this.modalRef.buttons.submitButton.onClick().subscribe(() => {
      const description = this.addHostsToGroupForm.get('description').value;
      this.modalRef.close({
        new_group_info: {
          label_key: this.newGroupLabelKey,
          label_value: this.newGroupLabelValue,
          members: this.hostsService.getSelectedHosts(),
          description: description
        },
        update_group_info: {
          id: this.selectedGroupId,
          label: this.addHostsToGroupForm.get('label').value,
          description: description,
          members: {
            add_host_id_list: this.hostsService.getSelectedHosts(),
            remove_host_id_list: []
          }
        }
      });
    });
  }

  buildAddHostToGroupForm(): void {
    this.addHostsToGroupForm = this.fb.group({
      label: new FormControl('', [Validators.required]),
      labelKey: new FormControl(''),
      labelValue: new FormControl(''),
      description: new FormControl('')
    });
  }

  onLabelInput(event: GroupRespData | string) {
    // This is a new group to be created
    if (typeof event == 'string') {
      if (String(event) != this.addHostsToGroupForm.get('label').value) {
        const label = this.newGroupLabelKey + ':' + this.newGroupLabelValue;

        // Set label if valid key and value have been set
        if (this.newGroupLabelKey && this.newGroupLabelValue && String(event) === label) {
          this.addHostsToGroupForm.get('label').setValue(String(label));
        }
      }
    } else {
      // If this is an existing group label, reset values for new group label key and value
      // so that UpdateGroup API is called instead of CreateGroup API
      this.isExistingGroupLabel = true;
      this.selectedGroupId = event.id;
      this.newGroupLabelKey = this.newGroupLabelValue = '';
      this.addHostsToGroupForm.get('label').setValue(String(event.label_key) + ':' + event.label_value);
    }
  }

  validateGroupLabel() {
    const label = this.addHostsToGroupForm.get('label').value;
    if ((this.isExistingGroupLabel && label) || (this.newGroupLabelKey && this.newGroupLabelValue)) {
      this.hostsService.enableAddToGroupConfirm();
    } else {
      this.hostsService.disableAddToGroupConfirm();
    }
  }

  hoverInfoCircleEvent(action: string) {
    Lumos.trackClickEvent('Info Circles', action);
  }

  toggleAddGroup(event: Event) {
    // Toggle addEnabled
    this.addEnabled = !this.addEnabled;

    // Reset labelKey, labelValue and label
    this.addHostsToGroupForm.get('label').reset();
    this.addHostsToGroupForm.get('labelKey').reset();
    this.addHostsToGroupForm.get('labelValue').reset();

    // If "Add" button is enabled, it indicates that we are adding
    // new group label.
    if (this.addEnabled) {
      this.isExistingGroupLabel = false;
    } else {
      this.isExistingGroupLabel = true;
    }

    // Prevent default form submission behaviour to ensure form validators
    // are not trigerred on button click
    event.preventDefault();
  }
}
