import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SiteResp } from '@ecs/ecs-api';
import { Observer } from 'rxjs';
import { SourceMode } from '../add-host/add-host.component';

@Component({
  selector: 'ecs-add-host-site-details',
  templateUrl: './add-host-site-details.component.html',
  styleUrls: ['./add-host-site-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddHostSiteDetailsComponent),
      multi: true
    }]
})
export class AddHostSiteDetailsComponent implements OnInit, ControlValueAccessor {
  @Input() sites: SiteResp[] = []
  @Input() mode: SourceMode = SourceMode.CREATE

  addHostSiteDetailsForm: FormGroup;

  ngOnInit(): void {
    this.buildForm()
  }

  writeValue(obj: any): void {
    obj && this.addHostSiteDetailsForm.setValue(obj, { emitEvent: false })
  }

  registerOnChange(fn: any): void {
    this.addHostSiteDetailsForm.valueChanges.subscribe(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {
  }


  buildForm() {
    this.addHostSiteDetailsForm = new FormGroup({
      siteId: new FormControl('', [Validators.required]),
      site: new FormControl('', [Validators.required]),
    });
    if (this.mode === SourceMode.EDIT)
      this.addHostSiteDetailsForm.disable()
  }

  onSelectionChanged(event: { model: string }) {
    const site = this.sites.filter((site: SiteResp) => site.name === event.model).shift()
    this.addHostSiteDetailsForm.get('siteId').setValue(site && site.id)
  }


}
