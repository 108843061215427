import { Component, Inject } from '@angular/core';
import { MODAL_DATA } from '@vmw/ngx-modal-service';
import { GroupRespData } from '@ecs/ecs-platform';

@Component({
  selector: 'app-groups-remove',
  templateUrl: './groups-remove.component.html',
  styleUrls: ['./groups-remove.component.scss']
})
export class GroupsRemoveComponent {
  public selectedGroups: Array<GroupRespData> = [];

  constructor(@Inject(MODAL_DATA) data: any) {
    this.selectedGroups = data?.selectedGroups;
  }
}
