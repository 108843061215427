import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LEARN_MORE_ABOUT_SITES, Lumos } from '@ecs/ecs-common';
import { CONFIGURE_ADD_SITE_ROUTE, getAdjustedRoute } from '@ecs/ecs-platform';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SiteComponent {
  public heroBanner = true;
  public readonly LEARN_MORE_ABOUT_SITE: string = LEARN_MORE_ABOUT_SITES;
  constructor(public router: Router) {}

  openAddSiteModal() {
    Lumos.trackClickEvent('Site', 'Add site clicked');
    this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_ADD_SITE_ROUTE));
  }

  hideHeroBanner() {
    this.heroBanner = false;
  }

  openSiteInstructions() {
    window.open(this.LEARN_MORE_ABOUT_SITE, '_blank');
  }
}
