export const SITES_LIST_COLUMNS = [
    {
        name: 'name',
        label: 'ecs.sites.datagrid.column.name',
        field: 'name'
    },
    {
        name: 'description',
        label: 'ecs.sites.datagrid.column.description',
        field: 'description'
    },
    {
        name: 'hostsCount',
        label: 'ecs.sites.datagrid.column.hostsCount',
    },
    {
        name: 'siteTags',
        label: 'ecs.sites.datagrid.column.labels',
    },
    {
        name: 'gitDetails',
        label: 'ecs.sites.datagrid.column.gitDetails',
        field: 'git_repo_url',
    },
    {
        name: 'updatedAt',
        label: 'ecs.sites.datagrid.column.updatedAt',
        field: 'updated_at'
    },
];