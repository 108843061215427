<div class="modal-body">
  <p>{{'ecs.addgitrepomodal.title' | vtranslate}}</p>

  <form clrForm [formGroup]="addGitRepoForm" clrLayout="horizontal" autocomplete="off">
    <clr-input-container class="custom-input-container">
      <label>
        {{ isGitRepoEditMode ? ('ecs.host.giturl' | vtranslate) : ('ecs.host.addgitlabel' | vtranslate) }}
        <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add GitRepo Modal - Add git URL Info Circle Hover')">
          <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
          <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>{{'ecs.addgitrepomodal.addgiturl.tooltip' | vtranslate}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </label>
      <input
        focusVisible
        clrInput
        class="clr-col-8 clr-col-sm-7 full-width-input"
        type="text"
        id="gitrepo-url"
        required
        placeholder="{{'ecs.addgitrepomodal.addgiturl.placeholder' | vtranslate}}"
        name="gitRepoURL"
        formControlName="gitRepoURL"
        data-input="gitrepo-url"
        (blur)="onUrlInputComplete()"
        [(ngModel)]="gitRepoURL"
        [readonly]="isGitRepoEditMode" />
      <clr-control-error class="add-gitrepo-control-error" *clrIfError="'isUrlInvalid'">{{'ecs.host.invalidgitlabel' | vtranslate}}</clr-control-error>
      <clr-control-error class="add-gitrepo-control-error" *clrIfError="'required'">{{'ecs.requiredField' | vtranslate}}</clr-control-error>
    </clr-input-container>
    <clr-input-container class="custom-input-container">
      <label>
        {{'ecs.addgit.branch' | vtranslate}}
        <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add GitRepo Modal - Branch Info Circle Hover')">
          <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
          <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>{{'ecs.addgitrepomodal.branch.tooltip' | vtranslate}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </label>
      <input
        focusVisible
        clrInput
        class="clr-col-8 clr-col-sm-7 full-width-input"
        type="text"
        id="gitrepo-branch"
        required
        placeholder="{{'ecs.addgitrepomodal.branch.placeholder' | vtranslate}}"
        name="gitRepoBranch"
        formControlName="gitRepoBranch"
        data-input="gitrepo-branch"
        [(ngModel)]="gitRepoBranch" />
      <clr-control-error class="add-gitrepo-control-error" *clrIfError="'required'">{{'ecs.requiredField' | vtranslate}}</clr-control-error>
    </clr-input-container>
    <clr-input-container class="custom-input-container">
      <label>
        {{'ecs.addgit.description' | vtranslate}}
        <clr-tooltip (mouseover)="hoverInfoCircleEvent('Add GitRepo Modal - Description Info Circle Hover')">
          <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="20"></cds-icon>
          <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
            <span>{{'ecs.addgit.description.tooltip' | vtranslate}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
      </label>
      <input
        focusVisible
        clrInput
        class="clr-col-8 clr-col-sm-7 full-width-input"
        type="text"
        id="gitrepo-description"
        placeholder="{{'ecs.addgit.description.placeholder' | vtranslate}}"
        name="gitrepoDescription"
        formControlName="gitRepoDescription"
        data-input="gitrepo-description"
        [(ngModel)]="gitRepoDescription" />
    </clr-input-container>
  </form>
</div>
