import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ellipsis-tooltip',
  templateUrl: './ellipsis-tooltip.component.html',
  styleUrls: ['./ellipsis-tooltip.component.scss']
})
export class EllipsisTooltipComponent implements OnInit {
  @Input() public text: string;
  @Input() public tooltipText: string;
  @Input() public tooltipPosition = 'top-right';
  @Input() public multiLine = false;

  ngOnInit(): void {
    this.tooltipText = this.tooltipText || this.text;
  }
}
